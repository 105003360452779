import type React from 'react'
import { Box, TabView } from 'components/App/Layout'
import { Avatar, AvatarFallback, AvatarImage, Button, Text } from 'components/ui'
import { ArrowLeft } from 'lucide-react'
import farmerIcon from 'assets/logos/farmer.png'
import { useNavigate } from 'react-router-dom'
import { Info } from '../components/Info'
import { useMerchantStore } from '../../../stores/useMerchantStore'

export const ServiceProviderProfile: React.FC = () => {
  const navigate = useNavigate()
  const { merchant } = useMerchantStore()

  const handleBack = (): void => {
    navigate(-1)
  }

  return (
    <Box>
      <div className={'flex items-center gap-2 mt-2 sm:mt-4'}>
        <Button
          onClick={handleBack}
          variant={'ghost'}
          className={
            'bg-transparent gap-2 py-1 text-[clamp(1.3rem,4vw,1.5rem)] leading-[70%] font-semibold capitalize'
          }
        >
          <ArrowLeft />
        </Button>
        <Text size={'large'}>My profile</Text>
      </div>

      <TabView>
        <div className={'flex gap-4 items-center'}>
          <Avatar className={'h-[clamp(3rem,4vw,4rem)] w-[clamp(3rem,4vw,4rem)]'}>
            <AvatarImage src={farmerIcon} />
            <AvatarFallback>
              <Text size={'medium'} className='fa-solid fa-user text-success'></Text>
            </AvatarFallback>
          </Avatar>
          <div className={'flex flex-col justify-between '}>
            <Text size={'small'} className={'capitalize'}>
              Merchant Id
            </Text>
            <Text size={'medium'} variant={'primary'}>
              {merchant?.merchant_id}
            </Text>
          </div>
        </div>

        <div className={'flex flex-col border-y border-input py-4'}>
          <Text size={'medium'} variant={'muted'}>
            Merchant Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'Name'} name={merchant?.merchant_name ?? ''} />
            <Info label={'License Number'} name={merchant?.license_no ?? ''} />
            <Info label={'Trade Name'} name={merchant?.trade_name ?? ''} />
            <Info label={'UIN'} name={merchant?.uin.toString() ?? ''} />
          </div>
        </div>

        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            Location Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'location'} name={merchant?.location.toLowerCase() ?? ''} />
            <Info
              label={'physical address'}
              name={merchant?.physical_address.toLowerCase() ?? 'N/A'}
            />
            <Info label={'postal address'} name={merchant?.postal_address.toLowerCase() ?? 'N/A'} />
          </div>
        </div>

        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            Contact Information
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info label={'email address'} name={merchant?.email.toLowerCase() ?? ''} />
            <Info label={'phone'} name={merchant?.contact.toLowerCase() ?? ''} />
          </div>
        </div>
      </TabView>
    </Box>
  )
}
