import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import type React from 'react'
import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  useStepper,
  useToast
} from '../../../../../../../components/ui'
import { type SelectedHerbicide, useWeedingStore } from '../../../../stores/useWeedingStore'
import { type Herbicides } from '../../../../../../../types/static'
import { AdjuvantSelection } from '../chemical/AdjuvantSelection'
import { useTranslation } from 'react-i18next'
import { AdjuvantsConfirmation } from '../chemical/AdjuvantsConfirmation'
import { type WeedControl, type WeedingChemicals } from '../../../../../../../types'
import collect from 'collect.js'
import { NoHerbicides } from '../NoHerbicides'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  onNext: () => void
  weeding: WeedControl | undefined
  onBack: () => void
}

export function HerbicidesTable<TData, TValue>({
  columns,
  data,
  onNext,
  onBack,
  weeding
}: DataTableProps<TData, TValue>): React.ReactNode {
  const collection = collect(weeding?.chemicals ?? [])
  const selectedHerbicidesApi = collection
    .mapWithKeys((item: WeedingChemicals) => [
      [`${item.chemical_price_detail_id}-${item.weed_id}`],
      true
    ])
    .all() as unknown as Record<string, boolean>

  const { nextStep } = useStepper()
  const { toast } = useToast()
  const [open, setOpen] = useState(false)
  const [showAdjuvants, setShowAdjuvants] = useState<boolean | null>(null)
  const { setSelectedHerbicides, selectedHerbicides } = useWeedingStore()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    weeding != null ? { ...selectedHerbicidesApi } : {}
  )
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: (row) => {
      const selectedKeys = Object.keys(rowSelection)
      const selectedHerbicideIds = selectedKeys.map((item) => item.split('-')[0])

      if (selectedKeys.find((item) => item === row.id) != null) {
        return true
      } else {
        return (
          selectedHerbicideIds.find(
            (item) => item === (row.original as Herbicides).herbicide_price_detail_id.toString()
          ) == null
        )
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) =>
      `${(row as Herbicides).herbicide_price_detail_id}-${(row as Herbicides).weed_id}`,
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const handleOpenDialog = (): void => {
    if (showAdjuvants != null) {
      setShowAdjuvants(null)
    }
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const handleNext = (): void => {
    setOpen(false)
    onNext()
  }

  const { t } = useTranslation('croppingPlan')

  const onSubmit = (): void => {
    if (table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected()) {
      const selectedHerbicidesFromTable = table
        .getSelectedRowModel()
        .rows.map((row) => row.original as Herbicides)
      const herbicidesSchedule = selectedHerbicidesFromTable[0].application_stage

      const selectedHerbicidesFromTableList: SelectedHerbicide[] = selectedHerbicidesFromTable.map(
        (item) => {
          return {
            herbicide: item,
            adjuvant: null,
            schedule: item.application_stage
          }
        }
      )

      if (herbicidesSchedule === 'Post Emergence') {
        setSelectedHerbicides({
          PE: selectedHerbicidesFromTableList,
          PrP: selectedHerbicides?.PrP,
          PrE: selectedHerbicides?.PrE
        })
        handleOpenDialog()
      } else if (herbicidesSchedule === 'Pre Planting') {
        setSelectedHerbicides({
          PE: selectedHerbicides?.PE,
          PrP: selectedHerbicidesFromTableList,
          PrE: selectedHerbicides?.PrE
        })
        handleOpenDialog()
      } else {
        setSelectedHerbicides({
          PE: selectedHerbicides?.PE,
          PrP: selectedHerbicides?.PrP,
          PrE: selectedHerbicidesFromTableList
        })
        handleOpenDialog()
      }
    } else if (table.getRowCount() === 0) {
      nextStep()
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please select a herbicide from the table'
      })
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      {table.getRowCount() > 0 ? (
        <div className='rounded-md border'>
          <Table className={'relative'}>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length !== 0 ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    className={'hover:cursor-pointer hover:bg-muted-hover'}
                    onClick={() => {
                      row.toggleSelected()
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className='h-24 text-center'>
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <NoHerbicides />
      )}

      {table.getRowCount() > 0 ? <DataTablePagination table={table} selectable /> : null}

      <div className='w-full flex justify-end gap-2'>
        <Button onClick={onBack} size={'sm'} variant={'secondary'}>
          {t('footer.prev')}
        </Button>
        <Button onClick={onSubmit} size={'sm'} variant={'primary'}>
          {t('footer.next')}
        </Button>
      </div>

      <Dialog
        key={'add-adjuvants'}
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        {showAdjuvants == null ? (
          <DialogContent
            key={'adjuvant-confirmation'}
            className={'w-[90vw] md:w-[50%] lg:w-[35%] max-w-[430px] max-h-[80vh] overflow-y-auto'}
          >
            <AdjuvantsConfirmation
              onClickNo={() => {
                setShowAdjuvants(false)
                handleCloseDialog()
                handleNext()
              }}
              onClickYes={() => {
                setShowAdjuvants(true)
              }}
            />
          </DialogContent>
        ) : showAdjuvants ? (
          <DialogContent
            key={'adjuvant-table'}
            className={
              'flex flex-col w-[90vw] md:w-[80%] lg:w-[65%] max-w-[430px] md:max-w-[90%] lg:max-w-[80%] max-h-[80vh] overflow-y-auto'
            }
          >
            <AdjuvantSelection
              herbicides={table.getSelectedRowModel().rows.map((row) => row.original as Herbicides)}
              weeding={weeding}
              onNext={handleNext}
              onCancel={handleCloseDialog}
            />
          </DialogContent>
        ) : null}
      </Dialog>
    </div>
  )
}
