import type React from 'react'
import { useEffect, useState } from 'react'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { Button, Loader, Text, useStepper } from '../../../../../../../components/ui'
import { PesticidesTable } from '../tables/PesticidesTable'
import { pesticidesColumn } from '../tables/columns'
import { fungicidesColumn } from '../tables/fungicides-columns'
import { useAgrochemicalData } from '../../../../../../../api/getStaticData'
import { useCroppingPlanPest } from '../../../../hooks/useCroppingPlanPest'
import { useGetPestControl } from '../../../../api/pest-control/getPestControl'
import { FungicidesTable } from '../tables/FungicidesTable'
import collect from 'collect.js'
import { CircleOff, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useFarmerStore } from '../../../../../../../stores/useFarmerStore'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'

export const PestChemicalSelection: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { farmer } = useFarmerStore()
  const { prevStep } = useStepper()
  const {
    setApplicationStages,
    setControlMethods,
    setAdjuvants,
    setPesticides,
    setHerbicides,
    setFungicides
  } = useStaticDataStore()
  const { data: staticData, isLoading: isLoadingStaticData, error } = useAgrochemicalData()
  const { header, setPest, isLoading } = useCroppingPlanPest(() => {})
  const isMicro = farmer?.class_id != null && farmer.class_id !== 'TAXPAYER'
  const {
    data: apiPest,
    isInitialLoading: apiIsLoading,
    error: pestError
  } = useGetPestControl(header?.farmer_id, header?.cropping_plan_id)
  const [isPesticides, setIsPesticides] = useState(
    isMicro && flags.enableMicroscaleFungicides === false
  )
  const { t } = useTranslation('croppingPlan')

  useEffect(() => {
    if (apiPest != null) {
      setPest(apiPest.data)
    }

    if (staticData != null) {
      setApplicationStages(staticData.data.application_stages)
      setControlMethods(staticData.data.control_methods)
      setAdjuvants(staticData.data.adjuvants)
      setPesticides(staticData.data.pesticides)
      setHerbicides(staticData.data.herbicides)
      setFungicides(staticData.data.fungicides)
    }

    void ldClient?.identify({ key: 'moa-frontend' })
  }, [staticData, apiPest, setPest])

  const handleNext = (): void => {
    setIsPesticides(true)
  }

  const handleBack = (): void => {
    setIsPesticides(false)
  }

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (isLoadingStaticData || apiIsLoading || isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error?.response?.status !== 404) {
      return <Error />
    }
  }

  if (pestError != null) {
    if (pestError?.response?.status !== 404) {
      return <Error />
    }
  }

  const fungicidesList = collect(
    staticData?.data?.fungicides?.filter((fungicide) => fungicide.crop_id === header?.crop_id) ?? []
  )
    .unique()
    .all()
  const pesticidesList = collect(
    staticData?.data?.pesticides?.filter((pesticide) => pesticide.crop_id === header?.crop_id) ?? []
  )
    .unique()
    .all()

  if (fungicidesList == null || pesticidesList == null) {
    return <Error />
  }

  if (isPesticides) {
    return (
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col gap-4 justify-start items-start'}>
          <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
            Pesticide Chemical Selection
          </Text>
          <div className='flex'>
            <Info size='18' color={'var(--primary)'} className='mr-2.5' />
            <Text variant='primary' size='small' className='font-bold'>
              Please select one or more pesticide from the table below.
            </Text>
          </div>
        </div>
        {pesticidesList.length === 0 ? (
          <div>
            <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
              <Text size={'xLarge'} className={'opacity-40'}>
                <CircleOff />
              </Text>
              <Text size={'medium'} variant={'bodyTextLight'}>
                No Pesticides Available for your Selected Crop
              </Text>
            </div>

            <div className='w-full flex justify-end gap-2'>
              <Button onClick={handleBack} size={'sm'} variant={'secondary'}>
                {t('footer.prev')}
              </Button>
              <Button onClick={handleNext} size={'sm'} variant={'primary'}>
                {t('footer.next')}
              </Button>
            </div>
          </div>
        ) : (
          <PesticidesTable
            pest={apiPest?.data}
            onNext={handleNext}
            onBack={handleBack}
            data={pesticidesList}
            columns={pesticidesColumn}
          />
        )}
      </div>
    )
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col gap-4 justify-start items-start'}>
        <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
          Fungicide Chemical Selection
        </Text>
        <div className='flex'>
          <Info size='18' color={'var(--primary)'} className='mr-2.5' />
          <Text variant='primary' size='small' className='font-bold'>
            Please select one or more fungicides from the table below.
          </Text>
        </div>
      </div>

      {fungicidesList.length === 0 ? (
        <div>
          <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
            <Text size={'xLarge'} className={'opacity-40'}>
              <CircleOff />
            </Text>
            <Text size={'medium'} variant={'bodyTextLight'}>
              No Fungicides Available for your Selected Crop
            </Text>
          </div>

          <div className='w-full flex justify-end gap-2'>
            <Button onClick={prevStep} size={'sm'} variant={'secondary'}>
              {t('footer.prev')}
            </Button>
            <Button onClick={handleNext} size={'sm'} variant={'primary'}>
              {t('footer.next')}
            </Button>
          </div>
        </div>
      ) : (
        <FungicidesTable
          pest={apiPest?.data}
          onNext={handleNext}
          onBack={handleBack}
          data={fungicidesList}
          columns={fungicidesColumn}
        />
      )}
    </div>
  )
}
