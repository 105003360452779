import {
  AttachmentRepo,
  ConfirmDetails
} from 'features/loan-management/components/stepper/individual'
import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMedia } from 'react-use'
import { Card, Step, Stepper } from '../../../../../components/ui'
import { Box } from '../../../../../components/App/Layout'

export const CompleteApplication: React.FC = () => {
  const steps = [{ label: 'Confirm Details' }, { label: 'Attachment Repo' }]
  const navigate = useNavigate()

  const handleCancelLAF = (): void => {
    navigate('/farmer/loans')
  }

  const isDesktop = useMedia('(min-width: 900px)')
  return (
    <Card
      className={
        'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
      }
    >
      <Box>
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={steps}
            onClickStep={(step, setStep) => {
              setStep(step)
            }}
            styles={{
              'step-label': 'text-xs'
            }}
            scrollTracking
          >
            {steps.map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <ConfirmDetails cancel={handleCancelLAF} />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <AttachmentRepo />
                </Step>
              )
            })}
          </Stepper>
        </div>
      </Box>
    </Card>
  )
}
