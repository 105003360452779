import type React from 'react'
import { useState } from 'react'
import { Card, Text } from 'components/ui'
import { SearchBar } from 'components/Form/SearchBar'
import { Loader } from 'components/ui/loader'
import { useCheckVoucherEligibility } from '../hooks/useCheckVoucherEligibility'
import { useAuthenticationStore } from '../../../stores/useAuthenticationStore'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMerchantStore } from '../../../stores/useMerchantStore'
import { useGetSPInventory } from '../service-provider/inventory/api/getSPInventory'

interface SearchEligibilityProps {
  route: string
}

export const SearchEligibility: React.FC<SearchEligibilityProps> = ({ route }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const flags = useFlags()
  const { checkVoucherEligibility, isEligible, error, isLoading } = useCheckVoucherEligibility(
    `${route}?omang=${searchQuery}`
  )
  const { merchant } = useMerchantStore()
  const { role } = useAuthenticationStore()

  const { data } = useGetSPInventory(merchant?.merchant_id)

  const hasInventory =
    data?.data.filter((item) => item.item_verification_status === 'VERIFIED').length !== 0 &&
    role === 2001

  const requiresInventory = flags.merchantInventoryTransaction === true ? hasInventory : true

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>Voucher Redemption</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {requiresInventory ? (
          <div className={'flex flex-col gap-2'}>
            <Text size={'medium'} variant={'bodyTextLight'}>
              Check voucher eligibility by entering omang number.
            </Text>
            <SearchBar
              className={'w-full max-w-[700px] flex gap-8'}
              searchQuery={searchQuery}
              disabled={searchQuery.length !== 9}
              onChange={handleChange}
              onSearch={() => {
                void checkVoucherEligibility(searchQuery)
              }}
              isSearching={isLoading}
            />
          </div>
        ) : (
          <>
            <Text size={'medium'} className={'text-center'}>
              You do not have verified inventory to transact
            </Text>
          </>
        )}

        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && isEligible != null && !isEligible ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            No Redeemable Voucher Found.
          </Text>
        ) : null}
      </Card>
    </div>
  )
}
