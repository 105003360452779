/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InputField, SelectField } from 'components/Form'
import { RowContainer } from 'features/loan-management/components/stepper'
import { number, object, string, type TypeOf } from 'zod'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { BasicButton } from 'components/Elements/Button'
import { useAtom } from 'jotai'
import { MenuItem } from '@mui/material'
import type React from 'react'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { Text } from 'components/Elements/Text/Text'
import { companyLoanStore } from '../stores'
import { type CompanyLoanStepperProps } from '../../../types'

const schema = object({
  repaymentPeriod: number({ invalid_type_error: 'This should be a number' })
    .nonnegative()
    .default(1)
    .refine((value) => value === 1, {
      message: 'Proposed Repayment Period should always be 1 year'
    }),
  loanPurpose: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  projectDirections: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  period_at_physical_addr: number({ invalid_type_error: 'This should be a number' }).nonnegative(),
  period_at_prev_physical_addr: number({
    invalid_type_error: 'This should be a number'
  }).nonnegative(),
  source_reference: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  ownership_Status: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  })
})

type CompanyInformationDetailsInput = TypeOf<typeof schema>

export const CompanyInformation: React.FC<CompanyLoanStepperProps> = ({
  className,
  onBack,
  onNext,
  activeStep,
  steps
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { farmer } = useFarmerStore()
  const onSubmit: SubmitHandler<CompanyInformationDetailsInput> = (
    data: CompanyInformationDetailsInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      company_loan: {
        farmer_id: Number(farmer?.farmer_id),
        company_reg_no: String(farmer?.farmer_id),
        loan_req2: data.repaymentPeriod,
        loan_req3: data.loanPurpose,
        loan_req4: data.projectDirections,
        period_at_physical_addr: data.period_at_physical_addr,
        period_at_prev_physical_addr: data.period_at_prev_physical_addr,
        ownership_at_physical_addr: data.ownership_Status,
        source_reference: data.source_reference,
        last_update_by: farmer?.user_id as number
      }
    }))
    onNext()
  }
  console.log(loanData)
  return (
    <FormWithStepper<CompanyInformationDetailsInput, typeof schema>
      className={className}
      isLoading={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit }) => (
        <div className={'w-full flex flex-col gap-8 p-2 px-4'}>
          <div className={'w-full flex flex-col gap-2'}>
            <Text size={'large'} variant={'bodyTextLight'} className={'ml-4 mb-4'}>
              Company Loan Details
            </Text>

            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                type={'number'}
                inputMode={'numeric'}
                defaultValue={loanData?.company_loan?.loan_req2}
                label={'Proposed Repayment Period (Years)'}
                error={!(errors.repaymentPeriod == null)}
                helperText={errors?.repaymentPeriod?.message ?? ''}
                register={register('repaymentPeriod', { valueAsNumber: true })}
              />
              <InputField
                className='flex-[1_0_230px]  capitalize'
                label={'Purpose of Loan'}
                defaultValue={loanData?.company_loan?.loan_req3}
                error={!(errors.loanPurpose == null)}
                helperText={errors?.loanPurpose?.message ?? ''}
                register={register('loanPurpose')}
              />
              <SelectField
                className='flex-[1_0_230px]  capitalize'
                label={'Ownership Status'}
                defaultValue={loanData?.company_loan?.ownership_at_physical_addr}
                error={!(errors.ownership_Status == null)}
                helperText={errors?.ownership_Status?.message ?? ''}
                register={register('ownership_Status')}
              >
                <MenuItem value={'0'}>
                  <Text>Owning</Text>
                </MenuItem>
                <MenuItem value={'1'}>
                  <Text>Rented</Text>
                </MenuItem>
              </SelectField>
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px]  capitalize'
                label={'Period at physical address (Years) '}
                defaultValue={loanData?.company_loan?.period_at_physical_addr}
                type={'number'}
                error={!(errors.period_at_physical_addr == null)}
                helperText={errors?.period_at_physical_addr?.message ?? ''}
                register={register('period_at_physical_addr', { valueAsNumber: true })}
              />

              <SelectField
                className='flex-[1_0_230px] capitalize'
                label={'How did you hear about NDB?'}
                defaultValue={loanData?.company_loan?.source_reference}
                error={!(errors.source_reference == null)}
                helperText={errors?.source_reference?.message ?? ''}
                register={register('source_reference')}
                fullWidth
              >
                <MenuItem value='facebook'>
                  <Text>Facebook</Text>
                </MenuItem>
                <MenuItem value='news'>
                  <Text>News</Text>
                </MenuItem>
                <MenuItem value='television'>
                  <Text>Television</Text>
                </MenuItem>
                <MenuItem value='print media'>
                  <Text>Print Media</Text>
                </MenuItem>
              </SelectField>

              <InputField
                className='flex-[1_0_230px]  capitalize'
                label={'Period at previous address (Years)'}
                type={'number'}
                defaultValue={loanData?.company_loan?.period_at_prev_physical_addr}
                error={!(errors.period_at_prev_physical_addr == null)}
                helperText={errors?.period_at_prev_physical_addr?.message ?? ''}
                register={register('period_at_prev_physical_addr', { valueAsNumber: true })}
              />
            </RowContainer>
            <RowContainer>
              <InputField
                className='flex-[1_0_230px] capitalize'
                defaultValue={loanData?.company_loan?.period_at_physical_addr}
                label={'Project Directions'}
                error={!(errors.projectDirections == null)}
                helperText={errors?.projectDirections?.message ?? ''}
                register={register('projectDirections')}
              />
            </RowContainer>
          </div>

          <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
            <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              onClick={handleSubmit(onSubmit)}
              size={'xs'}
            />
          </div>
        </div>
      )}
    </FormWithStepper>
  )
}
