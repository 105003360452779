import { ErrorPage } from 'components/Errors/ErrorPage'
import type React from 'react'
import { Card, Loader, Text } from 'components/ui'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { Box, TabView } from '../../../../components/App/Layout'
import { useOfficerStore } from '../../../../stores/useOfficerStore'
import { uniqBy } from 'lodash'
import { MOInventoriesTable } from '../../../mechanisationOfficer/inventory/components/tables/MOInventoriesTable'
import { viewInventoryColumns } from '../../../mechanisationOfficer/inventory/components/tables/viewInventoryColumn'
import { useGetEOInventories } from '../api/getEOInvetories'
import { useTranslation } from 'react-i18next'

export const EOInventories: React.FC = () => {
  const { officer } = useOfficerStore()
  const { t } = useTranslation('extensionOfficer')
  const { data: inventories, isLoading, error } = useGetEOInventories(officer?.ext_area_id)

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null && error.response?.status !== 404) {
    return <ErrorPage />
  }

  const farmerInventories = inventories?.data?.filter(
    (item) => item.farmer_id != null && item.item_verification_status !== 'PENDING_VERIFICATION'
  )

  const merchantInventories = inventories?.data?.filter(
    (item) => item.merchant_id != null && item.item_verification_status !== 'PENDING_VERIFICATION'
  )

  const farmerColumns = viewInventoryColumns(farmerInventories ?? [], t)
  const merchantColumns = viewInventoryColumns(merchantInventories ?? [], t)

  return (
    <Box>
      <TabView>
        <Tabs defaultValue='FI' className='flex flex-col gap-4 w-full'>
          <div
            className={'flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'}
          >
            <TabsList className={'grid w-full sm:w-2/3 md:w-1/2 lg:w-2/5 grid-cols-2'}>
              <TabsTrigger value='FI'>{t('inv.farmer')}</TabsTrigger>
              <TabsTrigger value='MI'>{t('inv.merchant')}</TabsTrigger>
            </TabsList>
          </div>

          <div>
            {/* Farmer Inventories Tab */}
            <TabsContent value='FI'>
              {farmerInventories?.length !== 0 ? (
                <Card
                  className={
                    'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                  }
                >
                  <MOInventoriesTable
                    columns={farmerColumns}
                    data={uniqBy(farmerInventories, 'farmer_id')}
                  />
                </Card>
              ) : (
                <div className='w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    {t('inv.nif')}
                  </Text>
                </div>
              )}
            </TabsContent>

            {/* Merchant Inventories Tab */}
            <TabsContent value='MI'>
              {merchantInventories?.length !== 0 ? (
                <Card
                  className={
                    'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                  }
                >
                  <MOInventoriesTable
                    columns={merchantColumns}
                    data={uniqBy(merchantInventories, 'merchant_id')}
                  />
                </Card>
              ) : (
                <div className='w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
                  <Text size={'medium'} variant={'bodyTextLight'}>
                    {t('inv.nif')}
                  </Text>
                </div>
              )}
            </TabsContent>
          </div>
        </Tabs>
      </TabView>
    </Box>
  )
}