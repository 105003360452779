import { ChangePasswordForm } from 'components/Change-Password/ChangePasswordForm'
import { Text } from 'components/ui'
import type React from 'react'
import { Box } from '../App/Layout'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { useNavigate } from 'react-router-dom'
import { ChangePasswordNotFound } from './ChangePasswordNotFound'

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate()

  return (
    <SessionAuth
      accessDeniedScreen={ChangePasswordNotFound}
      onSessionExpired={() => {
        navigate('/session')
      }}
      overrideGlobalClaimValidators={(globalValidators) => [
        ...globalValidators,
        UserRoleClaim.validators.includes('FIRST_LOGIN')
      ]}
    >
      <Box>
        <Text size={'large'}>Change Password</Text>
        <ChangePasswordForm />
      </Box>
    </SessionAuth>
  )
}
