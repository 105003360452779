import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useStepper,
  useToast
} from 'components/ui'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { StepperFooter } from '../Footer'
import type z from 'zod'
import { type seedingSchema } from './SeedSelection'
import { useCroppingPlanSeeding } from '../../../hooks/useCroppingPlanSeeding'
import type { Seeds } from '../../../types'
import { type Seeding } from '../../../../../../types'
import { getSeedId, getSeedIdMoreOptions } from '../../../utils/getSeeds'
import { type UseFormReturn } from 'react-hook-form'

const getDefaultSeedID = (seeding: Seeding, seed: Seeds[] | null): string => {
  const apiSelected = getSeedId(seeding?.seed_type, seeding?.variety)
  const selectionFromApi =
    apiSelected.length > 1
      ? getSeedIdMoreOptions(seeding?.seed_type, seeding?.variety, seeding?.sowing_rate, seed)
      : apiSelected

  return selectionFromApi[0]?.toString()
}

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  form: UseFormReturn<z.infer<typeof seedingSchema>>
  isNewSeeding: boolean
  seeding: Seeding | undefined
  seeds: Seeds[]
}

export function CroppingPlanSeedsTable<TData, TValue>({
  columns,
  data,
  form,
  isNewSeeding,
  seeding,
  seeds
}: DataTableProps<TData, TValue>): React.ReactNode {
  const { toast } = useToast()
  const { nextStep } = useStepper()
  const { updateSeedingPlan, createSeedingPlan, isLoading } = useCroppingPlanSeeding(nextStep)
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    seeding != null
      ? {
          [getDefaultSeedID(seeding, seeds)]: true
        }
      : {}
  )
  const table = useReactTable({
    data,
    columns,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => (row as Seeds).seed_id.toString(),
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const tableDataChanged = (): boolean => {
    if (seeding == null) {
      return false
    }

    const selectedSeed = table.getSelectedRowModel().rows.map((row) => row.original as Seeds)
    const selectionFromApi = getSeedIdMoreOptions(
      seeding?.seed_type,
      seeding?.variety,
      seeding?.sowing_rate,
      seeds
    )

    if (selectedSeed.length !== 1) {
      return false
    }

    return selectionFromApi[0] !== selectedSeed[0].seed_id
  }

  const onSubmit = (formData: z.infer<typeof seedingSchema>): void => {
    if (
      table.getRowCount() === 1
        ? table.getIsAllRowsSelected()
        : table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected()
    ) {
      const selectedSeed = table.getSelectedRowModel().rows.map((row) => row.original as Seeds)

      if (isNewSeeding) {
        createSeedingPlan(formData, selectedSeed[0].variety)
      } else if (
        tableDataChanged() ||
        seeding?.seed_type !== formData.seedType ||
        seeding?.yield !== formData.targetYield.toString()
      ) {
        updateSeedingPlan(formData, selectedSeed[0].variety)
      } else {
        nextStep()
      }
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please select a seed from the table'
      })
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className='rounded-md border'>
        <Table className={'relative'}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className={'hover:cursor-pointer hover:bg-muted-hover'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} selectable />
      <StepperFooter
        disabled={
          table.getRowCount() === 1
            ? !table.getIsAllRowsSelected()
            : !table.getIsSomeRowsSelected() && !table.getIsSomePageRowsSelected()
        }
        isLoading={isLoading}
        form={'seed-selection'}
        submit={form.handleSubmit(onSubmit)}
      />
    </div>
  )
}
