import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { app } from 'config/firebase'
import dayjs from 'dayjs'
import { checkUserExists } from 'features/authentication/api/checkUserExists'
import {
  createFarmer,
  type RegisterIndividualFarmerDTO
} from 'features/authentication/api/createFarmer'
import { type ValidateOmangDTO, verifyOmang } from 'features/authentication/api/verifyOmang'
import { type AddressInputValues } from 'features/authentication/components/individual/Addresses'
import { type PersonalDetailsInput } from 'features/authentication/components/individual/PersonalDetailsForm'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { type ApiResponse, type Farmer } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../components/ui'

interface UseIndividualRegistration {
  isLoading: boolean
  error: string | null
  verifyOmangDetails: (data: PersonalDetailsInput) => Promise<void>
  registerFarmer: (data: AddressInputValues) => void
}

export const useIndividualRegistration = (next: () => void): UseIndividualRegistration => {
  const { toast } = useToast()
  const functions = getFunctions(app, 'us-central1')
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { contactDetails, omangDetails, setOmangDetails, setContactDetails } =
    useIndividualRegistrationStore()

  const verifyOmangMutation = useMutation({
    mutationFn: async (data: ValidateOmangDTO) => {
      return verifyOmang(data)
    }
  })

  const createFarmerMutation = useMutation({
    mutationFn: async (data: RegisterIndividualFarmerDTO) => {
      return createFarmer(data)
    }
  })

  const checkUserOmangExists = async (omang: string): Promise<boolean | null> => {
    setIsLoading(true)
    setError(null)
    try {
      const checkUserResponse = await checkUserExists(omang)
      if (checkUserResponse.status === 200) {
        return true
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
        if (er.status === 400) {
          return false
        }
      }
    } finally {
      setIsLoading(false)
    }
    return null
  }

  const verifyOmangDetails = async (data: PersonalDetailsInput): Promise<void> => {
    const checkUserExistsResponse = await checkUserOmangExists(data.omang)

    if (checkUserExistsResponse === null) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Omang verification is currently unavailable. Try again later!'
      })
    } else if (checkUserExistsResponse) {
      toast({
        variant: 'destructive',
        description: 'User with Omang already exists!'
      })
    } else {
      verifyOmangMutation.mutate(
        {
          DOB: dayjs(data.dateOfBirth).format('YYYY-MM-DD'),
          EXPIRY_DATE: dayjs(data.expiryDate).format('YYYY-MM-DD'),
          FORENAMES: data.forenames.toUpperCase(),
          GENDER: data.gender,
          OMANG: data.omang,
          PLACE_OF_BIRTH: data.placeOfBirth.toUpperCase(),
          SURNAME: data.surname.toUpperCase()
        },
        {
          onSuccess: (result) => {
            if (result.status === 200) {
              setOmangDetails({
                omang: data.omang,
                dateOfBirth: data.dateOfBirth,
                expiryDate: data.expiryDate,
                forenames: data.forenames.toUpperCase(),
                gender: data.gender.toUpperCase(),
                placeOfBirth: data.placeOfBirth.toUpperCase(),
                surname: data.surname.toUpperCase()
              })
              next()
            } else if (result.status === 204) {
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(result.status.toString())
              })
            } else {
              const err = result.message
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(err)
              })
            }
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
              if (er.status === 500) {
                toast({
                  variant: 'destructive',
                  title: 'Uh oh! Something went wrong.',
                  description: 'Omang verification is currently unavailable. Please try again later'
                })
              } else {
                toast({
                  variant: 'destructive',
                  description: mapCmsErrorToMessage(er.data.status.toString())
                })
              }
            } else {
              const er = err as Error
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(er.message)
              })
            }
          }
        }
      )
    }
  }

  const registerFarmer = (data: AddressInputValues): void => {
    setContactDetails({
      ...contactDetails!,
      email: data.email ?? '',
      physicalAddress: data.physicalAddress,
      postalAddress: data.postalAddress
    })
    if (omangDetails !== null && contactDetails !== null) {
      createFarmerMutation.mutate(
        {
          CONTACT: contactDetails?.number,
          EMAIL: data.email ?? '',
          IND_DOB: dayjs(omangDetails?.dateOfBirth).format('YYYY-MM-DD'),
          IND_EXP_DATE: dayjs(omangDetails?.expiryDate).format('YYYY-MM-DD'),
          POSTAL_ADDRESS: data?.postalAddress ?? '',
          PHYSICAL_ADDRESS: data?.physicalAddress ?? '',
          IND_FIRST_NAME: omangDetails?.forenames,
          IND_SURNAME: omangDetails?.surname,
          IND_GENDER: omangDetails?.gender,
          FARMER_TYPE: 1,
          IND_POB: omangDetails?.placeOfBirth,
          ID: omangDetails?.omang,
          USER_ROLE: 1000,
          VILLAGE_ID: 1
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSuccess: async (result) => {
            setIsLoading(true)
            if (result.status === 201) {
              const userId = result.data?.USER_ID
              const role = httpsCallable(functions, 'addFarmerRole')
              await role({ userId }).then(() => {
                next()
              })
            } else {
              const err = result.message
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(err)
              })
            }
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const er = err?.response as AxiosResponse<ApiResponse<Farmer>>
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(er.data.message)
              })
            } else {
              const er = err as Error
              toast({
                variant: 'destructive',
                description: mapCmsErrorToMessage(er.message)
              })
            }
          }
        }
      )
    }
  }

  return {
    error,
    isLoading: isLoading || verifyOmangMutation.isLoading || createFarmerMutation.isLoading,
    verifyOmangDetails,
    registerFarmer
  }
}
