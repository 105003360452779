import type React from 'react'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { useFieldArray, useForm } from 'react-hook-form'
import z, { array, coerce, object, type TypeOf } from 'zod'
import dayjs from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Text,
  useStepper
} from '../../../../../components/ui'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { Box } from '../../../../../components/App/Layout'
import { CalendarIcon, Info } from 'lucide-react'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { cn } from '../../../../../lib/utils'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

const otherLoans = object({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  ref_no: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  date_aqcuired: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  int_rate: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  installment: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  amount_approved: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  outstanding_balance: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  arrears: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  loan_duration: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  })
})

export const schemaOL = object({
  loans_with_other_institution: array(otherLoans).nonempty('Field is required').optional(),
  loan_exists: z.enum(['yes', 'no'])
})

export type OtherLoansInput = TypeOf<typeof schemaOL>

export const OtherLoans: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const form = useForm<z.infer<typeof schemaOL>>({
    resolver: zodResolver(schemaOL),
    defaultValues: {
      loans_with_other_institution: loanData?.loans_with_other_institutions_ndb?.map((loan) => ({
        ref_no: loan.ref_no,
        loan_duration: loan.loan_duration,
        amount_approved: loan.amount_approved,
        arrears: loan.arrears,
        date_aqcuired: dayjs(loan.date_aqcuired).toDate() ?? undefined,
        installment: loan.installment,
        int_rate: loan.int_rate,
        outstanding_balance: loan.outstanding_balance
      })),
      loan_exists: loanData?.history_of_ndb_loans?.loan_exists as 'yes' | 'no' | undefined
    }
  })
  const { farmer } = useFarmerStore()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'loans_with_other_institution'
  })
  const addOtherLoans = (): void => {
    append({
      ref_no: 0,
      loan_duration: 0,
      amount_approved: 0,
      arrears: 0,
      date_aqcuired: new Date(),
      installment: 0,
      int_rate: 0,
      outstanding_balance: 0
    })
  }

  const onSubmit = (data: OtherLoansInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      loans_with_other_institutions_ndb:
        data?.loans_with_other_institution != null
          ? data.loans_with_other_institution.map((loan) => ({
              farmer_id: Number(farmer?.farmer_id),
              last_update_by: Number(farmer?.user_id),
              ...loan
            }))
          : [],
      history_of_ndb_loans: {
        loan_exists: data.loan_exists,
        last_update_by: Number(farmer?.user_id)
      }
    }))

    nextStep()

    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormField
              control={form.control}
              name='loan_exists'
              render={({ field: lowField }) => (
                <FormItem className='space-y-3'>
                  <FormLabel className={'font-redHat'}>
                    <div className='flex flex-col gap-3 '>
                      <Text size='medium' className=''>
                        {t('loan.ndb')}
                      </Text>
                      <div className='flex items-center mb-4'>
                        <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                        <Text variant='secondary' size='small' className=''>
                          {t('loan.have')}
                        </Text>
                      </div>
                    </div>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={lowField.onChange}
                      {...lowField}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('loan.yes')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('loan.no')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className={'w-full flex flex-col gap-8'}>
              <div className={'flex items-center justify-between'}>
                <div className='flex flex-col gap-3'>
                  <Text size='medium' className=''>
                    {t('loan.history')}
                  </Text>

                  <div className='flex items-center mb-4'>
                    <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                    <Text variant='secondary' size='small' className=''>
                      {t('loan.add')}
                    </Text>
                  </div>
                </div>

                <Button variant='secondary' size={'xs'} className='w-32' onClick={addOtherLoans}>
                  {t('loan.new')}
                </Button>
              </div>

              <FormField
                control={form.control}
                name={`loans_with_other_institution`}
                render={() => (
                  <FormItem className={'w-full mb-8'}>
                    {fields.map((field, index) => (
                      <>
                        <div className='flex items-center mb-8'>
                          <FormLabel className={'font-redHat'}>
                            <Text size='bodyBold' className=''>
                              {t('loan.loan')}
                              {`${index + 1}`}
                            </Text>
                          </FormLabel>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.ref_no`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.ref')}
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`${t('loan.ref')}`}
                                      type={'number'}
                                      inputMode={'numeric'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.date_aqcuired`}
                            render={({ field: lwoField }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel>{t('loan.start')}</FormLabel>
                                <Popover>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant={'outlined'}
                                        className={cn(
                                          'text-left font-normal',
                                          lwoField.value != null && 'text-muted-foreground'
                                        )}
                                      >
                                        {lwoField.value != null ? (
                                          format(lwoField.value, 'PPP')
                                        ) : (
                                          <span>{t('loan.start')}</span>
                                        )}
                                        <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                  <PopoverContent className='w-auto p-0' align='start'>
                                    <Calendar
                                      mode='single'
                                      captionLayout={'dropdown-buttons'}
                                      toYear={
                                        dayjs(new Date(Date.now())).toDate().getFullYear() + 1
                                      }
                                      fromYear={
                                        dayjs(new Date(Date.now())).toDate().getFullYear() - 120
                                      }
                                      selected={lwoField.value}
                                      onSelect={lwoField.onChange}
                                      disabled={(date) => new Date(Date.now()) <= date}
                                      initialFocus
                                    />
                                  </PopoverContent>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.loan_duration`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.duration')}
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`${t('loan.duration')}`}
                                      type={'number'}
                                      inputMode={'numeric'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.int_rate`}
                            render={({ field: lwoField }) => (
                              <div className='pt-2'>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.rate')}
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`${t('loan.rate')}`}
                                      type={'number'}
                                      inputMode={'numeric'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </div>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.installment`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.inst')}(BWP)
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                        <Text size='bodyBold' className=''>
                                          BWP
                                        </Text>
                                      </div>
                                      <Input
                                        id='currency'
                                        inputMode='decimal'
                                        type='number'
                                        min={0}
                                        step={0.01}
                                        placeholder='0.00'
                                        className='pl-14'
                                        {...lwoField}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.amount_approved`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.app')} (BWP)
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                        <Text size='bodyBold' className=''>
                                          BWP
                                        </Text>
                                      </div>
                                      <Input
                                        id='currency'
                                        inputMode='decimal'
                                        type='number'
                                        min={0}
                                        step={0.01}
                                        placeholder='0.00'
                                        className='pl-14'
                                        {...lwoField}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.outstanding_balance`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.bal')} (BWP)
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                        <Text size='bodyBold' className=''>
                                          BWP
                                        </Text>
                                      </div>
                                      <Input
                                        id='currency'
                                        inputMode='decimal'
                                        type='number'
                                        min={1}
                                        step={0.01}
                                        placeholder='0.00'
                                        className='pl-14'
                                        {...lwoField}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loans_with_other_institution.${index}.arrears`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      {t('loan.arr')} (BWP)
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                        <Text size='bodyBold' className=''>
                                          BWP
                                        </Text>
                                      </div>
                                      <Input
                                        id='currency'
                                        inputMode='decimal'
                                        type='number'
                                        min={0}
                                        step={0.01}
                                        placeholder='0.00'
                                        className='pl-14'
                                        {...lwoField}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />
                        </div>
                        <div className='w-full flex justify-end '>
                          <Button
                            color='error'
                            variant={'secondary'}
                            size={'xs'}
                            onClick={() => {
                              remove(index)
                            }}
                            disabled={index === 0}
                          >
                            {t('loan.remove')}
                          </Button>
                        </div>
                      </>
                    ))}
                  </FormItem>
                )}
              />
            </div>
          </div>

          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}