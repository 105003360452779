import { InputField } from 'components/Form'
import { MainContainer, RowContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { number, object, string, type TypeOf } from 'zod'
import { type SubmitHandler } from 'react-hook-form'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { BasicButton } from 'components/Elements/Button'
import { useAtom } from 'jotai'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { Text } from '../../../../../components/Elements/Text'
import { companyLoanStore } from '../stores'
import { type CompanyLoanStepperProps } from '../../../types'

const schema = object({
  bankId: number({ invalid_type_error: 'This should be a number' }),
  accountNumber: string().nonempty('Field is required').max(20),
  branchId: number({ invalid_type_error: 'This should be a number' })
})

type CompanyCommercialBankDetailsInput = TypeOf<typeof schema>

export const CompanyCommercialBank: React.FC<CompanyLoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { farmer } = useFarmerStore()

  // const selectedBankBranch = Banks.find(
  //   (item) =>
  //     item.bank_id === loanData?.commercial_bank_details?.bank_id &&
  //     item.bank_id === loanData?.commercial_bank_details?.bank_branch_id
  // )

  const onSubmit: SubmitHandler<CompanyCommercialBankDetailsInput> = (
    data: CompanyCommercialBankDetailsInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      commercial_bank_details: {
        bank_id: data.bankId,
        bank_branch_id: data.branchId,
        bank_account_number: Number(data.accountNumber),
        last_update_by: Number(farmer?.user_id)
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<CompanyCommercialBankDetailsInput, typeof schema>
      className={'flex flex-col flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ watch, register, formState: { errors }, handleSubmit }) => (
        <MainContainer>
          <div className={'w-full flex flex-col gap-8'}>
            <div className='w-full flex flex-col gap-2'>
              <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
                Commercial Bank
              </Text>
              {/* <RowContainer> */}
              {/* <div className='flex-[1_0_230px]  capitalize'> */}
              {/*  <SelectField */}
              {/*    label={'Bank Name'} */}
              {/*    error={!(errors.bankId == null)} */}
              {/*    helperText={errors?.bankId?.message ?? ''} */}
              {/*    fullWidth */}
              {/*    register={register('bankId', { valueAsNumber: true })} */}
              {/*    defaultValue={loanData?.commercial_bank_details?.bank_id} */}
              {/*  > */}
              {/*    {Banks.filter( */}
              {/*      (item, index, self) => */}
              {/*        index === self.findIndex((t) => t.bank_id === item.bank_id) */}
              {/*    ).map((item) => ( */}
              {/*      <MenuItem key={item.bank_id} value={item.bank_id}> */}
              {/*        <Text>{item.bank_name.toUpperCase()}</Text> */}
              {/*      </MenuItem> */}
              {/*    ))} */}
              {/*  </SelectField> */}
              {/* </div> */}
              {/* <div className='flex-[1_0_230px]  capitalize'> */}
              {/*  <SelectField */}
              {/*    label={'branch name'} */}
              {/*    defaultValue={loanData?.commercial_bank_details?.bank_branch_id} */}
              {/*    error={!(errors.branchId == null)} */}
              {/*    helperText={errors?.branchId?.message ?? ''} */}
              {/*    register={register('branchId', { valueAsNumber: true })} */}
              {/*    fullWidth */}
              {/*    disabled={ */}
              {/*      watch('bankId') === undefined && */}
              {/*      loanData?.commercial_bank_details?.bank_id === undefined */}
              {/*    } */}
              {/*  > */}
              {/*    {Banks.filter( */}
              {/*      (item) => */}
              {/*        item.bank_id === watch('bankId') || */}
              {/*        item.bank_id === loanData?.commercial_bank_details?.bank_id */}
              {/*    ).map((item) => ( */}
              {/*      <MenuItem key={item.bank_branch_id} value={item.bank_branch_id}> */}
              {/*        <Text>{item.bank_branch_name.toUpperCase()}</Text> */}
              {/*      </MenuItem> */}
              {/*    ))} */}
              {/*  </SelectField> */}
              {/* </div> */}
              {/* </RowContainer> */}
              <RowContainer>
                <InputField
                  className='flex-[1_0_230px] capitalize'
                  label={'account number'}
                  type='number'
                  defaultValue={loanData?.commercial_bank_details?.bank_account_number}
                  error={!(errors.accountNumber == null)}
                  helperText={errors?.accountNumber?.message ?? ''}
                  register={register('accountNumber')}
                />
              </RowContainer>
              <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
                <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
                <BasicButton
                  label={'Next'}
                  variant={'primary'}
                  onClick={handleSubmit(onSubmit)}
                  size={'xs'}
                />
              </div>
            </div>
          </div>
        </MainContainer>
      )}
    </FormWithStepper>
  )
}
