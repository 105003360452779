import { useEffect, useState } from 'react'
import { useGetCurrentPloughSeason } from '../api/getCurrentPloughSeason'
import { usePloughSeasonStore } from '../stores/usePloughSeasonStore'

interface UsePloughSeason {
  currentSeason: string
  isLoading: boolean
  isCurrentSeason: (season: string) => boolean
}

export const usePloughSeason = (): UsePloughSeason => {
  const { setPloughSeason, ploughSeason } = usePloughSeasonStore()
  const { data, isLoading } = useGetCurrentPloughSeason()
  const [currentSeason, setCurrentSeason] = useState<string>('')

  useEffect(() => {
    if (data != null) {
      setCurrentSeason(data?.data[0].plough_season)

      if (ploughSeason === '' || ploughSeason !== data?.data[0].plough_season) {
        setPloughSeason(data?.data[0].plough_season)
      }
    }
  }, [data])

  const isCurrentSeason = (season: string): boolean => ploughSeason === season

  return {
    currentSeason,
    isLoading,
    isCurrentSeason
  }
}
