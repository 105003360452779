import type React from 'react'
import { Separator, Text } from '../../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { type CroppingPlanDetails } from '../../../../../../types'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'

interface WeedingReviewProps {
  cp: CroppingPlanDetails
}

export const WeedingReview: React.FC<WeedingReviewProps> = ({ cp }) => {
  const { herbicides, adjuvants } = useStaticDataStore()
  const { t } = useTranslation('croppingPlan')

  return (
    <section className='flex flex-col gap-4'>
      <Text variant='primary' className={'font-semibold'}>
        {t('review.weed')}
      </Text>

      <div className='flex items-center gap-4 px-4'>
        <div className='w-1/2 flex flex-col'>
          <Text size='small' variant='secondary'>
            {t('review.weedMethod')}
          </Text>
          <Text className={'font-semibold'}>{cp.weed_control_type}</Text>
        </div>
        <div className='w-1/2 flex flex-col'>
          <Text size='small' variant='secondary'>
            {t('review.appMethod')}
          </Text>
          <Text className={'font-semibold'}>{cp.weed_control_application}</Text>
        </div>
      </div>

      <div className='flex items-center gap-4 px-4'>
        <div className='w-1/2 flex flex-col'>
          <Text size='small' variant='secondary'>
            Total Application Cost
          </Text>
          <Text className={'font-semibold'}>BWP {cp.weed_control_application_cost}</Text>
        </div>
        <div className='w-1/2 flex flex-col'>
          <Text size='small' variant='secondary'>
            Total Chemical Cost
          </Text>
          <Text className={'font-semibold'}>BWP {cp.weed_control_total_cost}</Text>
        </div>
      </div>

      <Text className={'px-4'} variant='primary'>
        Selected Chemicals
      </Text>
      <div className='flex flex-col gap-4 px-8'>
        {cp.weed_control?.map((item) => {
          return (
            <div className={'flex gap-4'}>
              <p>*</p>
              <Text className={'font-semibold'}>
                {item.chemical_schedule} |{' '}
                {herbicides?.find(
                  (herbicide) =>
                    herbicide?.herbicide_price_detail_id === item.chemical_price_detail_id &&
                    herbicide?.weed_id === item.weed_id
                )?.herbicide_name ?? null}{' '}
                |{' '}
                {herbicides?.find(
                  (herbicide) =>
                    herbicide?.herbicide_price_detail_id === item.chemical_price_detail_id &&
                    herbicide?.weed_id === item.weed_id
                )?.active_ingredient ?? null}
              </Text>
            </div>
          )
        })}

        {cp.pest_disease_control?.map((item) => {
          if (item.adjuvant_reg_no == null) {
            return null
          }
          return (
            <div className={'flex gap-4'}>
              <p>*</p>
              <Text className={'font-semibold'}>
                {adjuvants?.find(
                  (adjuvant) =>
                    adjuvant?.adjuvant_price_detail_id === item?.adjuvant_price_detail_id
                )?.adjuvant_name ?? null}{' '}
                | (Adjuvant)
              </Text>
            </div>
          )
        })}
      </div>
      <Separator />
    </section>
  )
}
