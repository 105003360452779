/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type z from 'zod'
import { coerce, object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { individualLoanItem } from '../stores/individualLoanItem'
import { useAtom } from 'jotai'
import type React from 'react'
import { useState } from 'react'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  useStepper
} from '../../../../../components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { Info } from 'lucide-react'

const schema = object({
  title_id: string().min(1, 'Field is required'),
  repaymentPeriod: coerce.number().min(1, 'Field is required').default(1),
  loanPurpose: string().min(1, 'Field is required'),
  projectDirections: string().min(1, 'Field is required'),
  period_at_physical_addr: coerce.number().min(1, 'Field is required'),
  period_at_prev_physical_addr: coerce.number().min(1, 'Field is required'),
  source_reference: string().min(1, 'Field is required'),
  ownership_Status: string().min(1, 'Field is required')
})

export const PersonalInformation: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [borderColor, setBorderColor] = useState('border-gray-500')
  const { t } = useTranslation('loanManagement')
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { farmer } = useFarmerStore()
  const { nextStep } = useStepper()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      title_id: loanData?.individual_loan?.title_id,
      loanPurpose: loanData?.individual_loan?.loan_req3,
      ownership_Status: loanData?.individual_loan?.ownership_at_physical_addr,
      period_at_physical_addr: loanData?.individual_loan?.period_at_physical_addr,
      period_at_prev_physical_addr: loanData?.individual_loan?.period_at_prev_physical_addr,
      projectDirections: loanData?.individual_loan?.loan_req4,
      source_reference: loanData?.individual_loan?.source_reference,
      repaymentPeriod: loanData?.individual_loan?.loan_req2
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    setLoanData((prev) => ({
      ...prev!,
      individual_loan: {
        farmer_id: farmer?.farmer_id as number,
        loan_req2: data.repaymentPeriod,
        loan_req3: data.loanPurpose,
        loan_req4: data.projectDirections,
        title_id: data.title_id,
        period_at_physical_addr: data.period_at_physical_addr,
        period_at_prev_physical_addr: data.period_at_prev_physical_addr,
        ownership_at_physical_addr: data.ownership_Status,
        source_reference: data.source_reference,
        last_update_by: farmer?.user_id as number
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <div className='flex flex-col gap-3'>
                <Text size='medium' className=''>
                  {t('pInfo.info')}
                </Text>
                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    Please provide your personal information, loan details, and project directions
                    to proceed with your application.
                  </Text>
                </div>
              </div>
            </FormLabel>

            <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 mb-3'>
              <FormField
                control={form.control}
                name='title_id'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.title')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      {...field}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('pInfo.title')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={'Mr'}>Mr</SelectItem>
                        <SelectItem value={'Mrs'}>Mrs</SelectItem>
                        <SelectItem value={'Dr'}>Dr</SelectItem>
                        <SelectItem value={'Ms'}>Ms</SelectItem>
                        <SelectItem value={'Prof'}>Prof</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormLabel>
              <Text size='medium' className=''>
                {t('pInfo.loan')}
              </Text>
            </FormLabel>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
              <FormField
                control={form.control}
                name={'repaymentPeriod'}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.pay')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={'Repayment Period'}
                        type={'number'}
                        inputMode={'numeric'}
                        {...field}
                        value={1}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={'loanPurpose'}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.purpose')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('pInfo.purpose')}`}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='ownership_Status'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.own')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      {...field}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('pInfo.own')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={'0'}>{t('pInfo.owning')}</SelectItem>
                        <SelectItem value={'1'}>{t('pInfo.rent')}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={'period_at_physical_addr'}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.physical')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('pInfo.physical')}`}
                        type={'number'}
                        inputMode={'numeric'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={'source_reference'}
                defaultValue={loanData?.individual_loan?.source_reference}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>{t('pInfo.how')}</FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      {...field}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Source Reference' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={'facebook'}>Facebook</SelectItem>
                        <SelectItem value={'news'}>{t('pInfo.news')}</SelectItem>
                        <SelectItem value={'television'}>{t('pInfo.tv')}</SelectItem>
                        <SelectItem value={'print media'}>{t('pInfo.print')}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={'period_at_prev_physical_addr'}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.previous')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('pInfo.previous')}`}
                        type={'number'}
                        inputMode={'numeric'}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className='grid grid-cols-1 w-full'>
              <FormField
                control={form.control}
                name={'projectDirections'}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className={'font-redHat'}>
                      <Text size='body' className=''>
                        {t('pInfo.project')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <textarea
                        className='bg-background-dark border border-gray-400 focus:border-none sm:bg-card p-2 rounded-md resize-y w-full placeholder-gray-500 placeholder-text-lg'
                        placeholder={t('pInfo.project')}
                        {...field}
                        rows={3}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
