import { useCroppingPlanSeeding } from '../../../hooks/useCroppingPlanSeeding'
import type React from 'react'
import { useEffect } from 'react'
import { useStepper } from 'components/ui/stepper'
import z, { coerce, object } from 'zod'
import { useGetCroppingPlanData } from '../../../../../../api/getStaticData'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useGetSeeding } from '../../../api/seeding/getSeeding'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CroppingPlanSeedsTable } from './CroppingPlanSeedsTable'
import { CroppingPlanSeedsColumns } from './columns'
import { useTranslation } from 'react-i18next'
import { NoSeeds } from './NoSeeds'
import { StepperFooter } from '../Footer'
import { Info } from 'lucide-react'
import collect from 'collect.js'

// Todo: Translate
export const seedingSchema = object({
  seedType: z.enum(['OPV', 'HYBRID'], {
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  targetYield: coerce
    .number({
      required_error: 'target yield is required',
      invalid_type_error: 'target yield should be a number'
    })
    .min(1, 'target yield should be greater than 1')
    .nonnegative('target yield cannot be smaller than 1')
})

export const SeedSelection: React.FC = () => {
  const { nextStep } = useStepper()
  const { seeds, setSeeds } = useStaticDataStore()
  const { t } = useTranslation('croppingPlan')

  const { header, setSeeding } = useCroppingPlanSeeding(nextStep)

  const {
    data: staticData,
    isInitialLoading: loadingStaticData,
    error: staticDataError
  } = useGetCroppingPlanData(seeds === null)

  const {
    data: apiSeeding,
    isInitialLoading: apiIsLoading,
    error: seedingError
  } = useGetSeeding(header?.farmer_id, header?.cropping_plan_id)

  const schema = object({
    seedType: z.enum(['OPV', 'HYBRID'], {
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    }),
    targetYield: coerce
      .number({
        required_error: 'target yield is required',
        invalid_type_error: 'target yield should be a number'
      })
      .min(1, 'target yield should be greater than 1')
      .nonnegative('target yield cannot be smaller than 1')
  })

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      seedType: apiSeeding != null ? apiSeeding?.data.seed_type : undefined,
      targetYield: apiSeeding != null ? parseInt(apiSeeding?.data?.yield) : undefined
    }
  })

  useEffect(() => {
    if (staticData != null) {
      const uniqueSeeds = collect(staticData.data.seeds)
        .filter((item) => item.isactive)
        .unique('seed_id')
        .all()
      setSeeds(uniqueSeeds)
    }

    if (apiSeeding != null) {
      setSeeding(apiSeeding.data)
      form.reset()
    }
  }, [staticData, apiIsLoading])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || loadingStaticData) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (seedingError != null) {
    if (seedingError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (staticDataError != null) {
    if (staticDataError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (staticData?.data?.seeds == null) {
    return <Error />
  }

  const cropSeeds =
    seeds?.filter((seed) => seed.crop_id === header?.crop_id) ??
    staticData.data.seeds.filter((seed) => seed.crop_id === header?.crop_id)

  const isNewSeeding = (): boolean => {
    return apiSeeding?.data == null
  }

  const filteredSeeds =
    form.watch('seedType') === 'HYBRID'
      ? collect(cropSeeds.filter((seed) => seed.seed_type === 'HYBRID'))
          .unique('seed_id')
          .all()
      : collect(cropSeeds.filter((seed) => seed.seed_type === 'OPV'))
          .unique('seed_id')
          .all()

  return (
    <Form {...form}>
      <form className={'flex flex-col p-4 gap-4 w-full'} id={'seed-selection'}>
        <div className='flex'>
          <Info size='18' color={'var(--primary)'} className='mr-2.5' />
          <Text variant='primary' size='small' className='font-bold'>
            Please select a seed for the crop{' '}
            <span className={'font-bold text-md'}>{header?.crop_name?.toUpperCase()}</span>
          </Text>
        </div>

        <FormField
          control={form.control}
          name='seedType'
          render={({ field }) => (
            <FormItem>
              <FormLabel className={'font-redHat'}>{t('seed.type')}</FormLabel>
              <Select
                onValueChange={(val) => {
                  field.onChange(val)
                }}
                defaultValue={apiSeeding?.data.seed_type}
              >
                <FormControl>
                  <SelectTrigger className='w-[200px]'>
                    <SelectValue placeholder={`${t('seed.type')}`} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value={'HYBRID'}>HYBRID</SelectItem>
                  <SelectItem value={'OPV'}>OPV</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={'targetYield'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'font-redHat'}>{t('seed.target')}</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  className={'w-[200px]'}
                  placeholder={`${t('seed.target')}`}
                  type={'number'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className='flex'>
          <Info size='18' color={'red'} className='mr-2.5' />
          <Text variant='error' size='small' className='font-bold'>
            Please ensure that your target yield is not greater than the selected seed yield.
          </Text>
        </div>

        {filteredSeeds == null || filteredSeeds.length === 0 ? (
          <div>
            <NoSeeds />
            <StepperFooter
              disabled={apiSeeding?.data == null}
              isLoading={false}
              form={'seed-selection'}
            />
          </div>
        ) : null}

        {form.watch('seedType') != null ? (
          <CroppingPlanSeedsTable
            isNewSeeding={isNewSeeding()}
            seeds={collect(staticData.data.seeds)
              .filter((item) => item.isactive)
              .unique('seed_id')
              .all()}
            seeding={apiSeeding?.data}
            columns={CroppingPlanSeedsColumns(t)}
            data={filteredSeeds}
            form={form}
          />
        ) : null}
      </form>
    </Form>
  )
}
