import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from '../Errors/ErrorPage'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { useAuthenticationStore } from '../../stores/useAuthenticationStore'
import Session, { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { useMerchantStore } from '../../stores/useMerchantStore'
import { useGetMerchant } from '../../features/authentication/api/getMerchant'
import { getRole } from 'utils/utilFunctions'
import { Unauthorized } from '../Errors/Unauthorized'
import { usePloughSeason } from '../../hooks/usePloughSeason'
import { Navigate, useNavigate } from 'react-router-dom'
import logo from '../../assets/logos/moa.svg'

const ServiceProviderDashboard = React.lazy(async () => import('../Dashboard/ServiceProvider'))

export const Services: React.FC = () => {
  const navigate = useNavigate()
  const sessionContext = Session.useSessionContext()
  const { setRole, isLogout } = useAuthenticationStore()
  const { setMerchant } = useMerchantStore()
  const claimValue = Session.useClaimValue(UserRoleClaim)
  const {
    data: merchantData,
    isInitialLoading: loadingMerchant,
    error: merchantError
  } = useGetMerchant(
    sessionContext.loading ? null : sessionContext.userId,
    claimValue.loading ? null : getRole(claimValue.value)
  )

  const { isLoading } = usePloughSeason()

  useEffect(() => {
    if (merchantData != null) {
      setRole(claimValue.loading ? null : getRole(claimValue.value))
      setMerchant(merchantData?.data[0])
    }
  }, [merchantData])

  if (sessionContext.loading || claimValue.loading || loadingMerchant || isLoading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <img
          role={'presentation'}
          className='h-[4rem] md:h-[7rem] xl:h-[7.5rem] sm:h-[clamp(3rem,10vw,4rem)] hover:transform hover:scale-[1.005] cursor-pointer hover:duration-500 animate-bounce transition-transform'
          src={logo}
          alt={logo}
        />
      </div>
    )
  }

  if (merchantError != null) {
    return <ErrorPage />
  }

  if (!sessionContext.doesSessionExist && (isLogout === false || isLogout === null)) {
    return <Navigate to='/session'></Navigate>
  }
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <SessionAuth
        accessDeniedScreen={Unauthorized}
        onSessionExpired={() => {
          navigate('/session')
        }}
        overrideGlobalClaimValidators={(globalValidators) => [
          ...globalValidators,
          UserRoleClaim.validators.includes('SERVICE_PROVIDER')
        ]}
      >
        <ServiceProviderDashboard />
      </SessionAuth>
    </ErrorBoundary>
  )
}
