import type React from 'react'
import { useState } from 'react'
import {
  AttachmentRepo,
  Attachments,
  BalanceSheets,
  CommercialBank,
  ConfirmDetails,
  FinancialParticulars,
  MaritalInformation,
  NextOfKin,
  OtherLoans,
  ProductionSchedule,
  Security,
  SummarizedBusinessInformation
} from 'features/loan-management/components/stepper/individual'
import { useNavigate } from 'react-router-dom'
import { ApplicantEmploymentInfo } from './ApplicantEmploymentInfo'
import { PersonalInformation } from './PersonalInformation'
import { Step, Stepper } from 'components/ui/stepper'
import { useMedia } from 'react-use'
import { useAtom } from 'jotai'
import { SelectPlans } from './SelectPlans'
import { farmerType } from '../stores'
import { toast } from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { SecurityOptional } from './SecurityOptional'

export const scaleType:
  | 'L1-Small Scale'
  | 'L2-Small Scale'
  | 'L1-Medium Scale'
  | 'L2-Medium Scale' = 'L1-Small Scale'

export const LoanApplicationForm: React.FC = () => {
  const [famerType] = useAtom(farmerType)
  const navigate = useNavigate()
  const isDesktop = useMedia('(min-width: 900px)')
  const { t } = useTranslation('loanManagement')

  const [completedSteps, setCompletedSteps] = useState<Record<number, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false
  })

  const steps = [
    { label: `${t('stepper.cp')}` },
    { label: `${t('stepper.att')}` },
    { label: `${t('stepper.pInfo')}` },
    { label: `${t('stepper.eInfo')}` },
    { label: `${t('stepper.kin')}` },
    { label: `${t('stepper.marital')}` },
    { label: `${t('stepper.banks')}` },
    { label: `${t('stepper.loans')}` },
    { label: `${t('stepper.part')}` },
    { label: `${t('stepper.sec')}` },
    { label: `${t('stepper.prod')}` },
    { label: `${t('stepper.bInfo')}` },
    { label: `${t('stepper.sheet')}` },
    { label: `${t('stepper.confirm')}` },
    { label: `${t('stepper.repo')}` }
  ]

  const handleCancel = (): void => {
    navigate('/farmer/loans')
  }

  const handleStepCompletion = (stepIndex: number, status: boolean): void => {
    setCompletedSteps((prev) => ({ ...prev, [stepIndex]: status }))
  }

  const handleStepClick = (index: number, setStep: (step: number) => void): void => {
    if (index > 0 && !completedSteps[0]) {
      toast({
        variant: 'destructive',
        title: 'Please complete the first step',
        description: 'You need to complete the first step before proceeding to the next step'
      })
      return
    }

    if (
      (index === 13 || index === 14) &&
      !(Object.values(completedSteps).slice(0, 12).every(Boolean) && completedSteps[12])
    ) {
      const firstIncompleteStep = Object.keys(completedSteps)
        .map(Number)
        .slice(0, 13)
        .find((stepIndex) => !completedSteps[stepIndex])
      toast({
        variant: 'destructive',
        title: `Please complete step(s): ${
          firstIncompleteStep != null ? firstIncompleteStep + 1 : ''
        }`,
        description: 'You need to complete all previous steps before proceeding to the next step'
      })
      return
    }

    if (completedSteps[12] && index < 13) {
      toast({
        variant: 'destructive',
        title: 'Cannot go back to previous steps',
        description: 'You cannot access previous steps once you reach step 14.'
      })
      return
    }

    setStep(index)
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Stepper
        variant={isDesktop ? 'circle-alt' : 'line'}
        size={'sm'}
        initialStep={0}
        mobileBreakpoint={'900px'}
        steps={steps}
        onClickStep={(step, setStep) => {
          handleStepClick(step, setStep)
        }}
        styles={{
          'step-label': 'text-xs'
        }}
        scrollTracking
      >
        {steps.map((stepProps, index) => {
          if (index === 0) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SelectPlans
                  cancel={handleCancel}
                  onComplete={(status: boolean) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 1) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Attachments
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 2) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <PersonalInformation
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 3) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ApplicantEmploymentInfo
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 4) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <NextOfKin
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 5) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <MaritalInformation
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 6) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CommercialBank
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 7) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <OtherLoans
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 8) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <FinancialParticulars
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 9) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                {famerType?.farmerType === scaleType ? (
                  <SecurityOptional
                    onComplete={(status) => {
                      handleStepCompletion(index, status)
                    }}
                  />
                ) : (
                  <Security
                    onComplete={(status) => {
                      handleStepCompletion(index, status)
                    }}
                  />
                )}
              </Step>
            )
          } else if (index === 10) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ProductionSchedule
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 11) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SummarizedBusinessInformation
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 12) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <BalanceSheets
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                  completedSteps={completedSteps}
                />
              </Step>
            )
          } else if (index === 13) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ConfirmDetails
                  cancel={handleCancel}
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          }
          return (
            <Step key={stepProps.label} {...stepProps}>
              <AttachmentRepo />
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
