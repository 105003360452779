import { type Implement, type PoweredImplement, type StaticInvData, type Tractor } from '../types'

interface InventoryData {
  inventory_type_id: number
  resource_item: number
}

export const findMatchingItem = (
  inventoryData: InventoryData | undefined,
  staticInvData: StaticInvData | undefined
): Tractor | PoweredImplement | Implement | undefined => {
  if (staticInvData == null) return undefined

  switch (inventoryData?.inventory_type_id) {
    case 1003:
      return staticInvData.tractors?.find((item) => item.model_id === inventoryData.resource_item)
    case 1002:
      return staticInvData.implements?.find((item) => item.model_id === inventoryData.resource_item)
    case 1001:
      return staticInvData.powered_implements?.find(
        (item) => item.model_id === inventoryData.resource_item
      )
    default:
      return undefined
  }
}
