import { Divider } from '@mui/material'
import { ErrorPage } from 'components/Errors/ErrorPage'
import {
  useGetCroppingPlan,
  useGetIndividualHeader
} from 'features/farmer/cropping-plan/api/header/getHeader'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import {
  getChemicalPestMethod,
  getChemicalWeedMethodValue,
  getHerbicide,
  getInsecticide,
  getManualPestStageValue,
  getManualWeedApplicationStageValue,
  getManualWeedMethodValue
} from 'features/farmer/cropping-plan/utils/functions'
import type React from 'react'
import { Button, Loader, toast, Text } from 'components/ui'
import { usePDF } from 'react-to-pdf'
import { FaDownload } from 'react-icons/fa6'

interface ReviewApprovedProps {
  onClose: () => void
}

export const ReviewApproved: React.FC<ReviewApprovedProps> = ({ onClose }) => {
  const { header } = useCroppingPlanStore()
  const { toPDF, targetRef } = usePDF({ filename: 'Cropping Plan.pdf' })
  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)
  const {
    data,
    isLoading,
    error: cpError
  } = useGetCroppingPlan(header?.cropping_plan_id, header?.farmer_id)
  const handleDownloadPdf = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      toPDF()
      resolve()
    })
    toast({
      variant: 'success',
      title: 'Printing',
      description: 'Save to your device'
    })
  }
  if (isLoading || apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null || cpError != null) {
    return <ErrorPage />
  }

  return (
    <section
      className={'bg-white w-full flex flex-col gap-6 sm:gap-8 py-8 px-6 sm:p-8 md:p-12 rounded-md'}
    >
      <div className={'w-full flex items-center justify-end'}>
        <Button color={'warning'} variant={'primary'} onClick={handleDownloadPdf}>
          <FaDownload />
        </Button>
      </div>
      <div ref={targetRef} className={'m-10'}>
        <div className={'flex gap-1 items-baseline'}>
          <Text
            size={'large'}
            variant={'bodyTextLight'}
            className={'group-hover:text-secondary capitalize'}
          >
            Cropping Plan Overview
          </Text>
        </div>
        <div className={'w-full flex flex-col gap-8'}>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Crop Name</Text>
              <Text size={'medium'}>{header?.crop_name}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text>Hectarage</Text>
              <Text size={'medium'}>{data?.data?.hectarage}</Text>
            </div>
          </div>

          <div className={'flex flex-col gap-2'}>
            <Divider />
            {/* Seeding */}
            <Text size={'large'} variant={'primary'}>
              Seeding
            </Text>
            <div className={'flex items-center'}>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Seed Variety</Text>
                <Text size={'medium'}>{data?.data?.seed_variety}</Text>
              </div>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Anticipated Returns</Text>
                <Text size={'medium'}>BWP {data?.data?.anticipated_returns}</Text>
              </div>
            </div>
            <div className={'flex items-center'}>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Total cost for Seeding</Text>
                <Text variant={'success'} size={'medium'}>
                  BWP {data?.data?.seed_cost}{' '}
                </Text>
              </div>
              <div className={'w-1/2 flex flex-col'}>
                <Text> </Text>
                <Text size={'medium'}> </Text>
              </div>
            </div>
          </div>

          {data?.data?.packaging_method === null ? null : (
            <div className={'flex flex-col gap-2'}>
              {/* Weeding */}
              <Divider />
              <Text size={'large'} variant={'primary'}>
                Weeding
              </Text>
              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Weeding Method</Text>
                  <Text size={'medium'}>{data?.data?.weed_control_type}</Text>
                </div>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Weeding Application Stage</Text>
                  <Text size={'medium'}>
                    {getManualWeedApplicationStageValue(
                      data?.data?.weed_control_chemical_schedule ??
                        data?.data?.weed_control_manual_schedule ??
                        undefined
                    )}
                  </Text>
                </div>
              </div>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Weeding Application Method</Text>
                  <Text size={'medium'}>
                    {data?.data?.weed_control_manual_method !== null
                      ? getManualWeedMethodValue(data?.data?.weed_control_manual_method)
                      : getChemicalWeedMethodValue(data?.data.weed_control_chemical_method)}
                  </Text>
                </div>

                {data?.data?.weed_control_chemicals != null &&
                data?.data?.weed_control_chemicals.length > 0 ? (
                  <div className={'w-1/2 flex flex-col'}>
                    <Text>Herbicide(s) Selected</Text>
                    {data?.data?.weed_control_chemicals?.map((item, index) => (
                      <span key={index}>
                        * <Text size={'medium'}>{getHerbicide(parseInt(item.id))[0].name}</Text>
                      </span>
                    ))}
                  </div>
                ) : null}
              </div>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Total cost for Weeding</Text>
                  <Text variant={'success'} size={'medium'}>
                    BWP{' '}
                    {data?.data?.weed_control_type === 'Chemical'
                      ? (data?.data?.weed_control_chemical_cost ?? 0) +
                        (data?.data?.weed_control_application_cost ?? 0)
                      : data?.data?.weed_control_application_cost}
                  </Text>
                </div>
              </div>
            </div>
          )}

          {data?.data?.packaging_method === null ? null : (
            <div className={'flex flex-col gap-2'}>
              {/* Pest Control */}
              <Divider />
              <Text size={'large'} variant={'primary'}>
                Pest Control
              </Text>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Pest Control Method</Text>
                  <Text size={'medium'}>{data?.data?.pest_control_type} </Text>
                </div>
                {data?.data?.pest_control_manual_schedule != null ? (
                  <div className={'w-1/2 flex flex-col'}>
                    <Text>Pest Control Schedule</Text>
                    <Text size={'medium'}>
                      {data?.data?.pest_control_chemical_schedule != null
                        ? data?.data?.pest_control_chemical_schedule
                        : getManualPestStageValue(data?.data?.pest_control_manual_schedule)}
                    </Text>
                  </div>
                ) : null}
              </div>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Pest Control Application Method</Text>
                  <Text size={'medium'}>
                    {data?.data?.pest_control_manual_method !== null
                      ? data?.data?.pest_control_manual_method
                      : data?.data?.pest_control_chemical_method != null
                      ? getChemicalPestMethod(data?.data.pest_control_chemical_method)
                      : null}
                  </Text>
                </div>

                {data?.data?.pest_control_chemicals != null &&
                data?.data?.pest_control_chemicals.length > 0 ? (
                  <div className={'w-1/2 flex flex-col'}>
                    <Text> Insecticide(s) Selected</Text>

                    {data?.data?.pest_control_chemicals?.map((item, index) => (
                      <span key={index}>
                        *<Text size={'medium'}> {getInsecticide(parseInt(item.id))[0].name}</Text>
                      </span>
                    ))}
                  </div>
                ) : null}
              </div>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Total cost for Pest Control</Text>
                  <Text variant={'success'} size={'medium'}>
                    BWP{' '}
                    {data?.data?.pest_control_type === 'Chemical'
                      ? (data?.data?.pest_control_application_cost ?? 0) +
                        (data?.data?.pest_control_chemical_cost ?? 0)
                      : data?.data?.pest_control_application_cost}
                  </Text>
                </div>
              </div>
            </div>
          )}

          <div className={'flex flex-col gap-2'}>
            {/* Land Preparation */}
            <Divider />
            <Text size={'large'} variant={'primary'}>
              Land Preparation
            </Text>
            <div className={'flex items-center'}>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Land Preparation Method</Text>
                <Text size={'medium'}>{data?.data?.tillage_method}</Text>
              </div>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Harrowing</Text>
                <Text size={'medium'}>{data?.data?.isHarrowing === true ? 'Yes' : 'No'}</Text>
              </div>
            </div>
            <div className={'flex items-center'}>
              <div className={'w-1/2 flex flex-col'}>
                <Text>Total cost for Land Preparation</Text>
                <Text variant={'success'} size={'medium'}>
                  BWP {data?.data?.land_preparation_cost}
                </Text>
              </div>
            </div>
          </div>

          {data?.data?.harvesting_method === null ? null : (
            <div className={'flex flex-col gap-2'}>
              {/* Harvesting */}
              <Divider />
              <Text size={'large'} variant={'primary'}>
                Harvesting
              </Text>
              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Harvesting Method</Text>
                  <Text size={'medium'}>{data?.data?.harvesting_method}</Text>
                </div>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Total cost for harvesting</Text>
                  <Text variant={'success'} size={'medium'}>
                    BWP {data?.data?.harvesting_cost}
                  </Text>
                </div>
              </div>
            </div>
          )}

          {data?.data?.shelling_threshing_method === null ? null : (
            <div className={'flex flex-col gap-2'}>
              {/* Threshing */}
              <Divider />
              <Text size={'large'} variant={'primary'}>
                Shelling & Threshing
              </Text>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Shelling & Threshing Method</Text>
                  <Text size={'medium'}>{data?.data?.shelling_threshing_method}</Text>
                </div>
                <div className={'w-1/2 flex flex-col'}>
                  <Text>Total cost for shelling & threshing</Text>
                  <Text variant={'success'} size={'medium'}>
                    {' '}
                    BWP {data?.data?.shelling_threshing_cost}
                  </Text>
                </div>
              </div>
            </div>
          )}
          {data?.data?.packaging_method === null ? null : (
            <div className={'flex flex-col gap-2'}>
              {/* Packaging */}
              <Divider />
              <Text size={'large'} variant={'primary'}>
                Packaging
              </Text>

              <div className={'flex items-center'}>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Packaging Method</Text>
                  <Text size={'medium'}>{data?.data?.packaging_method}</Text>
                </div>
                <div className={'w-1/2 flex flex-col'}>
                  <Text> Total cost for packaging</Text>
                  <Text variant={'success'} size={'medium'}>
                    BWP {data?.data?.packaging_cost}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className={'flex flex-col mt-6'}>
            {/* Transportation */}
            <Divider />
            <div className={'flex items-center'}>
              {data?.data?.transportation_cost === null ||
              data?.data?.transportation_cost === 0 ? null : (
                <div className={'w-1/2 flex flex-col'}>
                  <Text size={'large'} variant={'primary'}>
                    Transportation
                  </Text>

                  <Text variant={'success'} size={'medium'}>
                    BWP {data?.data?.transportation_cost}
                  </Text>
                </div>
              )}
              {/* Total Cost */}
              <div className={'w-1/2 flex flex-col'}>
                <Text size={'large'} variant={'primary'}>
                  Total Cost
                </Text>
                <Text variant={'success'} size={'medium'}>
                  BWP {apiHeader?.data[0]?.total_cost}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'w-full flex items-center justify-center'}>
        <Button variant={'primary'} className={'w-[8rem]'} onClick={onClose}>
          Close
        </Button>
      </div>
    </section>
  )
}
