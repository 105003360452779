import type React from 'react'
import { useState } from 'react'
import { type Farm } from '../../../../../types'
import { useGetFarmHistory } from '../../api/History/getFarmHistory'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { TabView } from '../../../../../components/App/Layout'
import {
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text
} from '../../../../../components/ui'
import { Error } from '../../../../../components/Errors/Error'
import { CircleOff, Info } from 'lucide-react'
import { FilterFarmHistoryMenu } from '../generic/filterFarmHistoryMenu'
import { CheckCircle, HighlightOff, HourglassEmpty } from '@mui/icons-material'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '../../../../../components/ui/tooltip'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface FarmHistoryProps {
  farm: Farm
}

/**
 * Formats a date string or Date object to 'MM/dd/yyyy' format.
 *
 * @param {string | Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
const formatDate = (date: string | Date): string => {
  return format(new Date(date), 'MM/dd/yyyy')
}

// Function to conditionally render farm status icon based on the status
const renderStatusIcon = (old_farm_status: string) => {
  switch (old_farm_status) {
    case 'verified':
      return <CheckCircle style={{ color: 'green' }} fontSize={'small'} />
    case 'rejected':
      return <HighlightOff style={{ color: 'red' }} fontSize={'small'} />
    case 'pending':
      return <HourglassEmpty style={{ color: 'orange' }} fontSize={'small'} />
    default:
      return null // Or any default icon for unknown status
  }
}

/**
 * Returns a dynamic message based on the filter type.
 *
 * @param {string} filter - The current filter type.
 * @returns {string} The dynamic message.
 */
const getNoHistoryMessage = (filter: string): string => {
  const { t } = useTranslation('landManagement')

  switch (filter) {
    case 'farm transfer':
      return `${t('history.noFarm')}`
    case 'change hactorage':
      return `${t('history.noHa')}`
    case 'subdivision':
      return `${t('history.noSub')}`
    default:
      return `${t('history.noHist')}`
  }
}

export const FarmHistory: React.FC<FarmHistoryProps> = ({ farm }) => {
  const { farmer } = useFarmerStore()
  const { data, isLoading, error } = useGetFarmHistory(farmer?.farmer_id, farm.farm_id)
  const [filter, setFilter] = useState<'farm transfer' | 'change hactorage' | 'subdivision'>(
    'farm transfer'
  )
  const { t } = useTranslation('landManagement')

  if (isLoading) {
    return (
      <TabView className={'grid place-items-center h-full flex-1'}>
        <Loader />
      </TabView>
    )
  }

  if (error != null && error.response?.status !== 404) {
    return (
      <TabView className={'grid place-items-center'}>
        <Error />
      </TabView>
    )
  }

  if (data?.data == null) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('history.farm')}</Text>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('history.show')}
          </Text>
        </div>
      </TabView>
    )
  }

  const filteredData = data.data.filter((res) => res.edit_farm_status === filter)

  return (
    <TabView>
      <FilterFarmHistoryMenu
        transfer={data.data.filter((res) => res.edit_farm_status === 'farm transfer').length}
        changeHactorage={
          data.data.filter((res) => res.edit_farm_status === 'change hactorage').length
        }
        subdivision={data.data.filter((res) => res.edit_farm_status === 'subdivision').length}
        filterBy={filter}
        setFilterBy={setFilter}
      />

      {filter === 'farm transfer' && (
        <div className='mt-6 w-full '>
          {filteredData.length === 0 ? (
            <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
              <Text size={'xLarge'} className={'opacity-40'}>
                <CircleOff />
              </Text>
              <Text size={'medium'} variant={'bodyTextLight'} className='capitalize'>
                {getNoHistoryMessage(filter)}
              </Text>
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[100px]'>`${t('history.date')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.origin')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.new')}`</TableHead>
                  <TableHead className='text-left'>`${t('history.status')}`</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredData.map((history, index) => (
                  <TooltipProvider key={index}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <TableRow className='cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700'>
                          <TableCell className=''>
                            {history.last_updated_date ? formatDate(history.last_updated_date) : ''}
                          </TableCell>
                          <TableCell className='text-center'>{history.og_name}</TableCell>
                          <TableCell className='text-center'>{history.dest_name}</TableCell>
                          <TableCell className='text-center flex space-x-2'>
                            <div>{renderStatusIcon(history.old_farm_status)} </div>
                            <div>{history.old_farm_status}</div>
                          </TableCell>
                        </TableRow>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className='flex justify-evenly flex-row'>
                          <Info className='mr-2.5 w-4 h-4 text-red-400' />{' '}
                          <Text size='small'>{t('inventory.click')}</Text>{' '}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      )}

      {filter === 'change hactorage' && (
        <div className='rounded-md border mt-6 w-full lg:w-4/6'>
          {filteredData.length === 0 ? (
            <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
              <Text size={'xLarge'} className={'opacity-40'}>
                <CircleOff />
              </Text>
              <Text size={'medium'} variant={'bodyTextLight'} className='capitalize'>
                {getNoHistoryMessage(filter)}
              </Text>
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[100px]'>`${t('history.date')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.oldHa')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.newHa')}`</TableHead>
                  <TableHead className='text-left'>`${t('history.status')}`</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredData.map((history, index) => (
                  <TooltipProvider key={index}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <TableRow className='cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700'>
                          <TableCell className=''>
                            {history.last_updated_date ? formatDate(history.last_updated_date) : ''}
                          </TableCell>
                          <TableCell className='text-center'>{history.old_hactarage}</TableCell>
                          <TableCell className='text-center'>{history.new_hactarage}</TableCell>
                          <TableCell className='text-right flex space-x-2'>
                            <div>{renderStatusIcon(history.old_farm_status)} </div>
                            <div>{history.old_farm_status}</div>
                          </TableCell>
                        </TableRow>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className='flex justify-evenly flex-row'>
                          <Info className='mr-2.5 w-4 h-4 text-red-400' />{' '}
                          <Text size='small'>{t('inventory.click')}</Text>{' '}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      )}

      {filter === 'subdivision' && (
        <div className='rounded-md border mt-6 w-full lg:w-4/6'>
          {filteredData.length === 0 ? (
            <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
              <Text size={'xLarge'} className={'opacity-40'}>
                <CircleOff />
              </Text>
              <Text size={'medium'} variant={'bodyTextLight'} className='capitalize'>
                {getNoHistoryMessage(filter)}
              </Text>
            </div>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[100px]'>`${t('history.date')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.oldHa')}`</TableHead>
                  <TableHead className='text-center'>`${t('history.newHa')}`</TableHead>
                  <TableHead className='text-left'>`${t('history.status')}`</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredData.map((history, index) => (
                  <TooltipProvider key={index}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <TableRow className='cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700'>
                          <TableCell className=''>
                            {history.last_updated_date ? formatDate(history.last_updated_date) : ''}
                          </TableCell>
                          <TableCell className='text-center'>{history.old_hactarage} </TableCell>
                          <TableCell className='text-center'>{history.new_hactarage}</TableCell>
                          <TableCell className='text-right flex space-x-2'>
                            <div>{renderStatusIcon(history.old_farm_status)} </div>
                            <div>{history.old_farm_status}</div>
                          </TableCell>
                        </TableRow>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className='flex justify-evenly flex-row'>
                          <Info className='mr-2.5 w-4 h-4 text-red-400' />{' '}
                          <Text size='small'>{t('inventory.click')}</Text>{' '}
                        </p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      )}
    </TabView>
  )
}