import type React from 'react'
import { useEffect } from 'react'
import { Card, Text } from 'components/ui'
import { servicesColumns } from '../components/columns'
import { useSearchParams } from 'react-router-dom'
import { Loader } from 'components/ui/loader'
import { NotEligible } from '../../components/NotEligible'
import { NotFound } from 'components/Errors/NotFound'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { useGetVoucherEligibility } from '../../api/getVoucherEligibility'
import { ServicesTable } from '../components/ServicesTable'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { useGetCroppingPlanData } from '../../../../api/getStaticData'

export const ServicesVoucherRedemption: React.FC = () => {
  const [searchParams] = useSearchParams()
  const query = searchParams.get('omang')
  const { data, isLoading, error } = useGetVoucherEligibility(query)
  const { services, setServices } = useStaticDataStore()
  const {
    data: staticData,
    isLoading: loadingStaticData,
    error: staticDataError
  } = useGetCroppingPlanData(services === null)

  useEffect(() => {
    if (staticData != null) {
      setServices(staticData.data.services)
    }
  }, [staticData])

  if (query == null) {
    return <NotFound />
  }

  if (isLoading || loadingStaticData) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null || staticDataError != null) {
    return <ErrorPage />
  }

  if (data?.data.exists === false) {
    return <NotEligible />
  }

  const landPreparation =
    services?.filter((service) => service.service_type === 'Land Preparation') ??
    staticData?.data?.services?.filter((service) => service.service_type === 'Land Preparation')

  return (
    <div
      className={
        'md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'
      }
    >
      <Text size={'large'}>Services Voucher Redemption</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          Below are a list of all the available services.
        </Text>
        <Text size={'body'} variant={'bodyTextLight'}>
          Please select all applicable services to be bought from the table below.
        </Text>
        <ServicesTable columns={servicesColumns} data={landPreparation} />
      </Card>
    </div>
  )
}
