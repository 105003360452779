import { useAtom } from 'jotai'
import type React from 'react'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import { individualLoanItem } from '../stores/individualLoanItem'
import dayjs from 'dayjs'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  useStepper
} from '../../../../../components/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { cn } from '../../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const schema = object({
  app_employer: string().min(1, 'Field is required'),
  employment_status: string().min(1, 'Field is required'),
  job_title: string().min(1, 'Field is required'),
  employment_start_date: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  monthly_income: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  other_income: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  no_of_permanent_employees: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  no_of_part_time_employees: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  previous_year_income: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  employee_other_income: string().min(1, 'Field is required')
})
export const ApplicantEmploymentInfo: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      app_employer: loanData?.employee_information?.applicant_employer,
      employment_status: loanData?.employee_information?.applicant_employment_status,
      job_title: loanData?.employee_information?.applicant_job_title,
      other_income: loanData?.employee_information?.farm_other_income,
      monthly_income: loanData?.employee_information?.applicant_monthly_income,
      no_of_permanent_employees: loanData?.employee_information?.permenant_employee_number,
      no_of_part_time_employees: loanData?.employee_information?.casual_employee_number,
      employee_other_income: loanData?.employee_information?.applicant_other_income_source,
      previous_year_income: loanData?.employee_information?.previous_year_turnover,
      employment_start_date:
        dayjs(loanData?.employee_information?.applicant_employment_start_date).toDate() ?? undefined
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    setLoanData((prevData) => ({
      ...prevData!,
      employee_information: {
        applicant_employer: data.app_employer,
        applicant_employment_status: data.employment_status,
        applicant_job_title: data.job_title,
        applicant_employment_start_date: data.employment_start_date,
        applicant_monthly_income: data.monthly_income,
        applicant_other_income_source: data.employee_other_income,
        permenant_employee_number: data.no_of_permanent_employees,
        casual_employee_number: data.no_of_part_time_employees,
        previous_year_turnover: data.previous_year_income,
        farm_other_income: data.other_income
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className='flex flex-col w-full' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <div className='flex flex-col gap-3'>
                <Text size='medium' className=''>
                  {t('eInfo.eInfo')}
                </Text>
                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    {t('eInfo.info')}
                  </Text>
                </div>
              </div>
            </FormLabel>

            <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 mb-3'>
              <FormField
                control={form.control}
                name='app_employer'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('eInfo.employer')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('eInfo.employerWork')}`}
                        type='text'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='employment_status'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('eInfo.eStatus')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      {...field}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('eInfo.type')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value='Contract'>{t('eInfo.contract')}</SelectItem>
                        <SelectItem value='Permanent'>{t('eInfo.perm')}</SelectItem>
                        <SelectItem value='Self Employed'>{t('eInfo.self')}</SelectItem>
                        <SelectItem value='Pensioner'>{t('eInfo.pension')}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='job_title'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('eInfo.job')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('eInfo.jobName')}`}
                        type='text'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className=' pt-0 md:pt-4'>
                <FormField
                  control={form.control}
                  name='employment_start_date'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('eInfo.start')}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outlined'}
                              className={cn(
                                'text-left font-normal',
                                field.value != null && 'text-muted-foreground'
                              )}
                            >
                              {field.value != null ? (
                                format(field.value, 'PPP')
                              ) : (
                                <span>{t('eInfo.start')}</span>
                              )}
                              <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-auto p-0' align='start'>
                          <Calendar
                            mode='single'
                            captionLayout={'dropdown-buttons'}
                            toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 1}
                            fromYear={dayjs(new Date(Date.now())).toDate().getFullYear() - 120}
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) => new Date(Date.now()) <= date}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className='pt-2'>
                <FormField
                  control={form.control}
                  name='monthly_income'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('eInfo.monthly')} (BWP)
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <div className='relative'>
                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                            <Text size='bodyBold' className=''>
                              BWP
                            </Text>
                          </div>
                          <Input
                            id='currency'
                            inputMode='decimal'
                            type='number'
                            min={0}
                            step={0.01}
                            placeholder='0.00'
                            className='pl-14'
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className='pt-2'>
                <FormField
                  control={form.control}
                  name='employee_other_income'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('eInfo.other')} (BWP)
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <div className='relative'>
                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                            <Text size='bodyBold' className=''>
                              BWP
                            </Text>
                          </div>
                          <Input
                            id='currency'
                            inputMode='decimal'
                            type='number'
                            min={0}
                            step={0.01}
                            placeholder='0.00'
                            className='pl-14'
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <FormLabel className={'font-redHat'}>
              <div className='flex flex-col gap-3'>
                <Text size='medium' className=''>
                  {t('eInfo.farmEmployee')}
                </Text>

                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    {t('eInfo.pEmp')}
                  </Text>
                </div>
              </div>
            </FormLabel>

            <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 mb-3'>
              <div className=' pt-0 md:pt-4'>
                <FormField
                  control={form.control}
                  name='no_of_permanent_employees'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('eInfo.noPerm')}
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <Input
                          classLabel={'bg-background-dark sm:bg-card'}
                          placeholder={`${t('eInfo.howPerm')}`}
                          type='number'
                          inputMode='numeric'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className=' pt-0 md:pt-4'>
                <FormField
                  control={form.control}
                  name='no_of_part_time_employees'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('eInfo.noCas')}
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <Input
                          classLabel={'bg-background-dark sm:bg-card'}
                          placeholder={`${t('eInfo.howCas')}`}
                          type='number'
                          inputMode='numeric'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className=' pt-0 md:pt-2'>
                <FormField
                  control={form.control}
                  name='previous_year_income'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('eInfo.prev')} (BWP)
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <div className='relative'>
                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                            <Text size='bodyBold' className=''>
                              BWP
                            </Text>
                          </div>
                          <Input
                            id='currency'
                            inputMode='decimal'
                            type='number'
                            min={0}
                            step={0.01}
                            placeholder='0.00'
                            className='pl-14'
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name='other_income'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('eInfo.otherIncome')} (BWP)
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <div className='relative'>
                        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                          <Text size='bodyBold' className=''>
                            BWP
                          </Text>
                        </div>
                        <Input
                          id='currency'
                          inputMode='decimal'
                          type='number'
                          min={0}
                          step={0.01}
                          placeholder='0.00'
                          className='pl-14'
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}