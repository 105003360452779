import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  type ChemicalsDTO,
  createWeeding,
  type CreateWeedingDTO
} from 'features/farmer/cropping-plan/api/weeding/createWeeding'
import {
  updateWeeding,
  type UpdateWeedingDTO
} from 'features/farmer/cropping-plan/api/weeding/updateWeeding'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type WeedControl } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import { type z } from 'zod'
import { type methodSchema } from '../components/steps/weeding/chemical/ApplicationMethod'
import { type AvailableSchedules, useWeedingStore } from '../stores/useWeedingStore'
import { getScheduleId } from '../utils/functions'
import collect from 'collect.js'

interface UseCroppingPlanWeeding {
  header: CroppingPlan | null
  weeding: WeedControl | null
  setWeeding: (weeding: WeedControl | null) => void
  isLoading: boolean
  createChemicalWeeding: (data: z.infer<typeof methodSchema>) => void
  updateChemicalWeeding: (data: z.infer<typeof methodSchema>) => void
}

const getChemicals = (
  schedule: string | null,
  selectedHerbicides: AvailableSchedules | null
): ChemicalsDTO[] => {
  if (schedule === 'Post Emergence') {
    const chemicals: ChemicalsDTO[] | undefined = selectedHerbicides?.PE?.map((item) => {
      return {
        chemical_id: undefined,
        weed_id: item.herbicide.weed_id,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    return chemicals != null ? chemicals : []
  } else if (schedule === 'Pre Planting') {
    const chemicals: ChemicalsDTO[] | undefined = selectedHerbicides?.PrP?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    return chemicals != null ? chemicals : []
  } else if (schedule === 'Pre Emergence') {
    const chemicals: ChemicalsDTO[] | undefined = selectedHerbicides?.PrE?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    return chemicals != null ? chemicals : []
  } else if (schedule === 'Pre Emergence & Post Emergence') {
    const chemicalsPE: ChemicalsDTO[] | undefined = selectedHerbicides?.PE?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicalsPrE: ChemicalsDTO[] | undefined = selectedHerbicides?.PrE?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicals = collect(chemicalsPrE ?? [])
      .merge(chemicalsPE ?? [])
      .all()

    return chemicals != null ? chemicals : []
  } else if (schedule === 'Pre Planting & Post Emergence') {
    const chemicalsPE: ChemicalsDTO[] | undefined = selectedHerbicides?.PE?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicalsPrP: ChemicalsDTO[] | undefined = selectedHerbicides?.PrP?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicals = collect(chemicalsPrP ?? [])
      .merge(chemicalsPE ?? [])
      .all()

    return chemicals != null ? chemicals : []
  } else if (schedule === 'Pre Planting & Pre Emergence') {
    const chemicalsPrE: ChemicalsDTO[] | undefined = selectedHerbicides?.PrE?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicalsPrP: ChemicalsDTO[] | undefined = selectedHerbicides?.PrP?.map((item) => {
      return {
        chemical_id: undefined,
        chemical_schedule: getScheduleId(item.schedule),
        chemical: item.herbicide.herbicide_price_detail_id,
        weed_id: item.herbicide.weed_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id
      }
    })

    const chemicals = collect(chemicalsPrE ?? [])
      .merge(chemicalsPrP ?? [])
      .all()

    return chemicals != null ? chemicals : []
  } else {
    return []
  }
}

export const useCroppingPlanWeeding = (next: () => void): UseCroppingPlanWeeding => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { selectedHerbicides, schedule } = useWeedingStore()
  const { header, weeding, setWeeding } = useCroppingPlanStore()

  const createWeedingMutation = useMutation({
    mutationFn: async (cpDto: CreateWeedingDTO) => {
      return createWeeding(cpDto)
    }
  })

  const updateWeedingMutation = useMutation({
    mutationFn: async (cpDto: UpdateWeedingDTO) => {
      return updateWeeding(cpDto)
    }
  })

  const createChemicalWeeding = (data: z.infer<typeof methodSchema>): void => {
    createWeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: 'Chemical',
        user_id: farmer?.user_id,
        chemical_method: data.methods,
        chemicals: getChemicals(schedule, selectedHerbicides)
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['weeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateChemicalWeeding = (data: z.infer<typeof methodSchema>): void => {
    updateWeedingMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: 'Chemical',
        cp_weed_control_id: weeding?.cp_weed_control_id,
        user_id: farmer?.user_id,
        chemical_method: data.methods,
        chemicals: getChemicals(schedule, selectedHerbicides)
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['weeding'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    header,
    weeding,
    setWeeding,
    isLoading: createWeedingMutation.isLoading || updateWeedingMutation.isLoading,
    createChemicalWeeding,
    updateChemicalWeeding
  }
}
