import bwFlag from 'assets/landing/logos/Flag-Botswana.svg'
import moalogo from 'assets/landing/logos/moalogo.svg'
import { useTheme } from 'next-themes'
import { LandingButton } from './LandingButton'
import { type FC, useState, useEffect } from 'react'
import { Button, Layout, Text } from 'components/ui'
import { Link, NavLink } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { motion, useScroll, useTransform } from 'framer-motion'
import { LanguageToggle } from './LanguageToggle'

interface NavLandingPageProps {
  goToDashboard: () => string
  isAuthenticated: boolean
}
export const NavLandingPage: FC<NavLandingPageProps> = ({ goToDashboard, isAuthenticated }) => {
  const [enabled, setEnabled] = useState(false)
  const { theme, setTheme } = useTheme()
  const { scrollY } = useScroll()

  const toggleTheme = (): void => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  const getThemeColor = (): string => {
    return theme === 'dark' ? 'rgb(67,69,70)' : 'rgb(255,255,255)'
  }

  const [background, setBackground] = useState(getThemeColor())

  useEffect(() => {
    const updateBackground = (): void => {
      const themeColor = getThemeColor()
      const scrolledBackground = scrollY.get() > 50 ? themeColor : 'rgba(255,255,255,0)'
      setBackground(scrolledBackground)
    }

    updateBackground()

    const unsubscribe = scrollY.onChange(updateBackground)
    return () => {
      unsubscribe()
    }
  }, [scrollY, theme])
  // framer motion

  const marginTop = useTransform(scrollY, [20, 0], [0, 30])
  const height = useTransform(scrollY, [0, 90], [110, 70])
  const borderColor = useTransform(
    scrollY,
    [0, 100],
    ['rgba(52,52,52,0)', 'rgba(157,157,157,0.73)']
  )

  const links = [
    { to: '/', title: 'Temo Letlotlo' },
    { to: '/user-manuals/', title: 'Crop Management system' },
    { to: '/contacts', title: 'Contacts' }
  ]

  return (
    <Layout variant={'body'}>
      {/* border-b border-border dark:shadow-[0px_5px_5px_0px_black] shadow-[0px_2px_5px_0px_grey] */}
      <div className='w-full bg-muted px-4 py-[4px] relative'>
        <Layout className={'flex gap-4 items-center'}>
          <img src={bwFlag} alt='bwFlag' className='h-[15px] sm:h-[20px]' />
          <Text size='small'>An official website of the Botswana government</Text>
        </Layout>
      </div>
      <motion.div
        id={'navigation-main'}
        style={{
          background,
          height,
          marginTop,
          borderBottom: '1px solid',
          borderColor
        }}
        className={'fixed w-full flex flex-col h-[70px] z-[9999] top-0 left-0 right-0 '}
        // mt-[15px] sm:mt-[20px]
      >
        <Layout className={'h-full flex items-center justify-between px-4'}>
          <div className='flex gap-4 items-center'>
            <Button
              variant={'ghost'}
              className='bg-transparent material-symbols-outlined md:hidden'
              onClick={() => {
                setEnabled(!enabled)
              }}
            >
              <Text size='large' className='material-symbols-outlined hover:text-primary'>
                menu
              </Text>
            </Button>
            <Link to={'/'}>
              <img className='w-[clamp(3rem,8vw,3.5rem)]' src={moalogo} alt={moalogo} />
            </Link>

            <Text size='small' className='font-semibold hidden sm:block'>
              Ministry Of Agriculture
            </Text>
          </div>
          <div className='hidden md:flex gap-6 justify-between'>
            {links.map((link, index) => (
              <NavLink
                to={link.to}
                className={({ isActive }) =>
                  isActive
                    ? 'font-medium text-primary-active capitalize'
                    : 'font-medium text-foreground hover:text-primary-hover capitalize'
                }
              >
                {link.title}
              </NavLink>
            ))}
          </div>
          <div className='flex gap-2'>
            <Button size={'icon'} variant={'ghost'} onClick={toggleTheme}>
              <span
                className={
                  theme === 'dark'
                    ? 'text-foreground fa-solid fa-moon'
                    : 'text-foreground fa-solid fa-sun'
                }
              ></span>
            </Button>

            <LanguageToggle />

            <Link to={goToDashboard()}>
              <Button size={'sm'} className='px-4 py-2'>
                {!isAuthenticated ? 'Log In' : 'Dashboard'}
              </Button>
            </Link>
            <div
              className={
                enabled
                  ? 'bg-card flex items-start p-8 flex-col absolute w-full h-screen z-[834789374857348] top-0 inset-x-0 transition-all duration-[0.3s] ease-[ease-in]'
                  : 'hidden h-0'
              }
            >
              <div className='w-full flex justify-end'>
                <Button
                  variant={'ghost'}
                  onClick={() => {
                    setEnabled(!enabled)
                  }}
                >
                  <Text
                    variant={'error'}
                    size='large'
                    className='material-symbols-outlined hover:text-destructive'
                  >
                    close
                  </Text>
                </Button>
              </div>

              <div className='w-full flex flex-col gap-2 items-center p-8 '>
                {links.map((link, index) => (
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      isActive
                        ? 'font-medium text-primary capitalize'
                        : 'font-medium text-foreground hover:text-primary-hover capitalize'
                    }
                  >
                    {link.title}
                  </NavLink>
                ))}
              </div>
              <div className='flex justify-center w-full gap-2'>
                <Link to={goToDashboard()} className='w-full max-w-[300px]'>
                  <LandingButton
                    // onClick={handleClickOpen}
                    variant='filled'
                    size='small'
                    className='w-full px-4 py-2'
                    title={!isAuthenticated ? 'Log In' : 'Dashboard'}
                  />
                </Link>
              </div>
            </div>
          </div>
        </Layout>
      </motion.div>
    </Layout>
  )
}
