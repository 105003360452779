import type React from 'react'
import { useEffect, useState } from 'react'
import { Button, Card, Text } from 'components/ui'
import { Caravan, LayoutGrid, TractorIcon } from 'lucide-react'
import { LabInventoryStatus } from './LabInventoryStatus'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  type GetInventoryRes,
  type Implement,
  type PoweredImplement,
  type Tractor
} from '../../../farmer/inventory/types'
import { useGetStaticInventory } from '../../../farmer/inventory/api/getInventoryData'
import { findMatchingItem } from '../../../farmer/inventory/util/findMatchingItem'
import { useAuthenticationStore } from '../../../../stores/useAuthenticationStore'

interface InventoryCardProps {
  inventoryData: GetInventoryRes
}

export const MOInventoryCard: React.FC<InventoryCardProps> = ({ inventoryData }) => {
  const [matchingItem, setMatchingItem] = useState<Tractor | PoweredImplement | Implement>()
  const { userType } = useParams<{ userType: string }>()
  const { t } = useTranslation('inventory')
  const { data: staticInvData } = useGetStaticInventory()
  const navigate = useNavigate()
  const [statusColor, setStatusColor] = useState<string>('')
  const { role } = useAuthenticationStore()

  useEffect(() => {
    if (inventoryData.item_verification_status === 'VERIFIED') {
      setStatusColor('bg-success')
    } else if (
      inventoryData.item_verification_status === 'TO BE REASSESSED' ||
      inventoryData.item_verification_status === 'PENDING_VERIFICATION'
    ) {
      setStatusColor('bg-warning')
    } else if (inventoryData.item_verification_status === 'REJECTED') {
      setStatusColor('bg-red-500')
    }
    setMatchingItem(findMatchingItem(inventoryData, staticInvData?.data))
  }, [staticInvData, inventoryData])

  const handleSubmit = (): void => {
    navigate(
      `${role === 1001 ? '/officer' : ''}/inventory/view-inventory/${userType ?? ''}/${
        inventoryData?.inventory_id ?? ''
      }`
    )
  }

  return (
    <Card className='p-4 grid gap-4'>
      <div className='rounded-md flex items-start gap-4 bg-background p-2'>
        <div className={`rounded-md p-2 sm:p-3 flex items-center justify-center ${statusColor}`}>
          {inventoryData.inventory_type_id === 1003 ? (
            <TractorIcon className='w-4 h-4 sm:w-6 sm:h-6 text-primary-foreground dark:text-foreground' />
          ) : inventoryData.inventory_type_id === 1002 ? (
            <LayoutGrid className='w-4 h-4 sm:w-6 sm:h-6 text-primary-foreground dark:text-foreground' />
          ) : (
            <Caravan className='w-4 h-4 sm:w-6 sm:h-6 text-primary-foreground dark:text-foreground' />
          )}
        </div>
        <div className='grid gap-1'>
          <div className='flex flex-col sm:flex-row items-baseline gap-2'>
            <Text size='medium'>{matchingItem?.make}</Text>
            <Text size='small' variant='muted'>
              {inventoryData.inventory_type_id === 1003
                ? `${t('inventory.tractor')}`
                : inventoryData.inventory_type_id === 1002
                ? `${t('inventory.nonPwrd')}`
                : `${t('inventory.pwrd')}`}
            </Text>
          </div>
          <div className='grid gap-1'>
            <div className='flex items-center gap-2'>
              <Text size='small' variant='muted'>
                {inventoryData.inventory_type_id !== 1003
                  ? `${t('inventory.serial')}`
                  : inventoryData.reg_number != null
                  ? `${t('inventory.reg')}`
                  : `${t('inventory.chas')}`}
                :
              </Text>
              <Text className={'font-semibold'}>
                {inventoryData.reg_number ?? inventoryData.chassis_number}
              </Text>
            </div>
            <div className='flex items-center gap-2'>
              <Text size='small' variant='muted'>
                {t('inventory.model')}
              </Text>
              <Text className={'font-semibold'}>{matchingItem?.model}</Text>
            </div>
          </div>
        </div>
      </div>

      <div className='flex items-center justify-between'>
        <LabInventoryStatus inventory={inventoryData} className='flex items-center gap-4' />
        <div className='flex gap-2'>
          <Button onClick={handleSubmit} size={'xs'}>
            {t('inventory.view')}
          </Button>
          {/* {inventoryData.item_verification_status === 'VERIFIED' && ( */}
          {/*  <Button onClick={openTransfer} disabled={isTransfer} variant={'warning'}> */}
          {/*    {t('inventory.transfer')} */}
          {/*  </Button> */}
          {/* )} */}
        </div>
      </div>
    </Card>
  )
}
