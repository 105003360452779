import type { ApiResponse, ApiResponseWithObject, CroppingPlan } from '../types'
import { axios } from '../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { type Agrochemicals, type CroppingPlanStaticData } from '../types/static'

export const getCroppingPlanData = async (): Promise<
  ApiResponseWithObject<CroppingPlanStaticData>
> => {
  return axios.get(`/staticdata/`)
}

export const getAgrochemicalData = async (): Promise<ApiResponseWithObject<Agrochemicals>> => {
  return axios.get(`/staticdata/agrochemicals`)
}

export const getInventoryData = async (): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/staticdata/inventory`)
}

export const useAgrochemicalData = (): UseQueryResult<
  ApiResponseWithObject<Agrochemicals>,
  AxiosError
> => {
  return useQuery({
    queryKey: ['agrochemical-static-data'],
    queryFn: async () => getAgrochemicalData()
  })
}

export const useGetCroppingPlanData = (
  enabled: boolean
): UseQueryResult<ApiResponseWithObject<CroppingPlanStaticData>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plan-static-data'],
    queryFn: async () => getCroppingPlanData(),
    enabled
  })
}

export const useGetInventoryData = (): UseQueryResult<ApiResponse<CroppingPlan>, AxiosError> => {
  return useQuery({
    queryKey: ['inventory-static-data'],
    queryFn: async () => getInventoryData()
  })
}
