import type React from 'react'
import { useEffect } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type Farm, type Lease } from 'types'
import { useGetHeader } from '../api/header/getHeader'
import { Button, Loader, Text } from 'components/ui'
import { CircleOff, Info } from 'lucide-react'
import { CroppingPlansTable } from './tables/CroppingPlansTable'
import { croppingPlansColumns } from './tables/cropping-plans-columns'
import { AxiosError } from 'axios'
import { Error } from 'components/Errors/Error'
import { useTranslation } from 'react-i18next'
import { useAgrochemicalData, useGetCroppingPlanData } from '../../../../api/getStaticData'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'

interface Base {
  openCroppingPlan: () => void
  openApprovedCroppingPlan: () => void
  newCroppingPlan: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlansProps = WithFarm | WithLease

export const CroppingPlans: React.FC<CroppingPlansProps> = ({
  farm,
  lease,
  openCroppingPlan,
  openApprovedCroppingPlan,
  newCroppingPlan
}) => {
  const { farmer } = useFarmerStore()
  const { isCurrentSeason, isLoading: ploughSeasonIsLoading } = usePloughSeason()
  const {
    seeds,
    setSeeds,
    setCrops,
    crops,
    fertilizers,
    setFertilizers,
    setChemicals,
    chemicals,
    setServices,
    setApplicationStages,
    setControlMethods,
    setAdjuvants,
    setPesticides,
    setHerbicides,
    setFungicides
  } = useStaticDataStore()
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetHeader(farmer?.farmer_id, farm?.farm_id !== undefined ? farm.farm_id : lease?.farm_id)
  const {
    data: staticData,
    isInitialLoading: loadingStaticData,
    error: staticDataError
  } = useGetCroppingPlanData(
    seeds === null || crops === null || fertilizers === null || chemicals === null
  )
  const {
    data: staticAgroData,
    isLoading: isLoadingStaticAgroData,
    error: errorAgro
  } = useAgrochemicalData()
  const { t } = useTranslation('landManagement')

  useEffect(() => {
    if (staticData != null) {
      setSeeds(staticData.data.seeds)
      setCrops(staticData.data.crops)
      setChemicals(staticData.data.chemicals)
      setFertilizers(staticData.data.npk_fertilizers)
      setServices(staticData.data.services)
    }

    if (staticAgroData != null) {
      setApplicationStages(staticAgroData.data.application_stages)
      setControlMethods(staticAgroData.data.control_methods)
      setAdjuvants(staticAgroData.data.adjuvants)
      setPesticides(staticAgroData.data.pesticides)
      setHerbicides(staticAgroData.data.herbicides)
      setFungicides(staticAgroData.data.fungicides)
    }
  }, [staticData, staticAgroData])

  if (isLoading || loadingStaticData || ploughSeasonIsLoading || isLoadingStaticAgroData) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error instanceof AxiosError) {
      if (error?.response?.status !== 404) {
        return <Error />
      }
    }
  }

  if (staticDataError != null) {
    if (staticDataError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (errorAgro != null) {
    if (staticDataError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (croppingPlans != null) {
    return (
      <>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('cp.cps')}</Text>
          <Button variant={'success'} size={'xs'} onClick={newCroppingPlan}>
            {t('cp.new')}
            <p className='fa-solid fa-right-left' />
          </Button>
        </div>

        <div className='flex'>
          <Info size='18' color='red' className='mr-2.5' />
          <Text variant='error' size='small' className=''>
            N.B. The total hectarage of your cropping plans per season cannot exceed your available
            land size.
          </Text>
        </div>
        <CroppingPlansTable
          data={croppingPlans.data}
          columns={croppingPlansColumns(t)}
          onApproved={openApprovedCroppingPlan}
          onDraft={openCroppingPlan}
          isCurrentSeason={isCurrentSeason}
        />
      </>
    )
  }

  // no cropping plans available
  return (
    <>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>{t('cp.cps')}</Text>
        <Button variant={'success'} size={'xs'} onClick={newCroppingPlan}>
          {t('cp.new')}
          <p className='fa-solid fa-right-left' />
        </Button>
      </div>
      <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
        <Text size={'xLarge'} className={'opacity-40'}>
          <CircleOff />
        </Text>
        <Text size={'medium'} variant={'bodyTextLight'}>
          No Cropping Plans Available
        </Text>
      </div>
    </>
  )
}
