import type React from 'react'
import { useEffect, useState } from 'react'
import { Box, TabView } from '../../../../../components/App/Layout'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from '../../../../../components/ui'
import { coerce, object, string, z } from 'zod'
import { useForm } from 'react-hook-form'
import { YearPicker } from '../../../../../components/Form/YearPicker'
import { useNavigate } from 'react-router-dom'
import { useGetStaticInventory } from '../../../../farmer/inventory/api/getInventoryData'
import { type GetInventoryRes, type Implement } from '../../../../farmer/inventory/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { uniqBy } from 'lodash'
import dayjs from 'dayjs'
import { useInventoryItem } from '../hooks/useInventoryItem'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { locations } from '../../../../farmer/land-management/utils'
import { type Village } from '../../../../../types'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'

export const NonPoweredImpSchema = object({
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  reg_number: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  model: z.coerce.number().min(1, 'Field is required'),
  make: z.coerce.number().min(1, 'Field is required'),
  class: string().optional().or(z.literal('')).default(''),
  type: string().optional().or(z.literal('')).default(''),
  subType: string().optional().or(z.literal('')).default(''),
  bottoms: string().optional().or(z.literal('')).default(''),
  workingWidth: string().optional().or(z.literal('')).default(''),
  draftPower: string().min(1, 'Field is required'),
  system: string().optional().or(z.literal('')).default(''),
  attachment: string().optional().or(z.literal('')).default(''),
  district: coerce
    .number({
      required_error: 'District is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'District is required'),
  subDistrict: coerce
    .number({
      required_error: 'Sub District is required',
      invalid_type_error: 'Sub District is required'
    })
    .min(1, 'Sub District is required'),
  extension: coerce
    .number({
      required_error: 'Extension is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'Extension is required'),
  village: coerce.number().min(1, 'Field is required')
})

export type InventoryInput = z.infer<typeof NonPoweredImpSchema>

export interface RegisterInventoryProps {
  inventoryData?: GetInventoryRes
  inventoryItem?: Implement
  isView?: boolean
  isMerchant?: boolean
}

export const RegisterNonPoweredImplements: React.FC<RegisterInventoryProps> = ({
  inventoryData,
  inventoryItem,
  isView,
  isMerchant
}) => {
  const { role } = useAuthenticationStore()
  const [selectedMake, setSelectedMake] = useState<string | number>(
    inventoryItem?.make_id.toString() ?? ''
  )
  const [selectedModel, setSelectedModel] = useState<string | number>(
    inventoryItem?.model_id.toString() ?? ''
  )
  const [district, setDistrict] = useState<number | string>(
    inventoryData?.district_id?.toString() ?? ''
  )
  const [subDistrict, setSubDistrict] = useState<number | string>()
  const [extensionArea, setExtensionArea] = useState<number | string>()
  const [village, setVillage] = useState<number | string>(
    inventoryData?.village_id?.toString() ?? ''
  )
  const [selectedVillage, setSelectedVillage] = useState<Village | undefined>()
  const { merchant } = useMerchantStore()
  const { data: staticInvData, isLoading: isLoadingStatic } = useGetStaticInventory()
  const { createItem, updateItem, isLoading } = useInventoryItem()
  const [draftPower, setDraftPower] = useState<string>(inventoryItem?.draft_power ?? '')
  const [selectedItem, setSelectedItem] = useState<Implement | undefined>()

  const [isFormChanged, setIsFormChanged] = useState(false)
  const form = useForm<InventoryInput>({
    resolver: zodResolver(NonPoweredImpSchema),
    defaultValues: {
      model: Number(selectedModel) ?? Number(inventoryItem?.model_id) ?? '',
      make: Number(selectedMake) ?? Number(inventoryItem?.make_id) ?? '',
      class: inventoryItem?.implement_class ?? '',
      workingWidth: inventoryItem?.working_width ?? '',
      subType: inventoryItem?.implement_sub_type ?? '',
      type: inventoryItem?.implement_type ?? '',
      reg_number: inventoryData?.chassis_number?.toString(),
      draftPower: draftPower ?? inventoryItem?.draft_power ?? '',
      system: inventoryItem?.implement_system ?? '',
      attachment: inventoryItem?.attachment ?? '',
      bottoms:
        inventoryItem?.implement_class === 'SEEDER'
          ? inventoryItem?.no_of_rows?.toString()
          : inventoryItem?.no_of_bottoms?.toString(),
      village: Number(village)
    }
  })
  const findSelectedItem = (): Implement | undefined => {
    return staticInvData?.data?.implements.find(
      (item) =>
        item.make_id.toString() === selectedMake &&
        item.model_id.toString() === selectedModel &&
        item.draft_power === draftPower
    )
  }
  const findVillage = (): Village | undefined => {
    const vil = locations.find((item) => item.village_id.toString() === village?.toString())

    if (vil != null) {
      setDistrict(vil.district_id.toString())
      setSubDistrict(vil.sub_district_id.toString())
      setExtensionArea(vil.extension_area_id.toString())
      setVillage(vil.village_id.toString())
      return vil
    }
    return vil
  }

  useEffect(() => {
    if (!isFormChanged) {
      setSelectedMake(inventoryItem?.make_id.toString() ?? '')
      setSelectedModel(inventoryItem?.model_id.toString() ?? '')
      setVillage(inventoryData?.village_id?.toString() ?? '')
    }

    const villageResult = findVillage()
    if (villageResult != null) {
      setSelectedVillage(villageResult)
    }

    setSelectedItem(findSelectedItem())

    form.setValue('make', Number(selectedMake))
    form.setValue('model', Number(selectedModel))
    form.setValue('district', Number(selectedVillage?.district_id))
    form.setValue('subDistrict', Number(selectedVillage?.sub_district_id))
    form.setValue('extension', Number(selectedVillage?.extension_area_id))
    form.setValue('village', Number(selectedVillage?.village_id))

    const subscription = form.watch(() => {
      setIsFormChanged(true)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [inventoryItem, inventoryData, selectedMake, selectedModel, village, isFormChanged, form])

  const navigate = useNavigate()

  const draftPowers = uniqBy(staticInvData?.data.implements, (item) => {
    return item.draft_power ?? ''
  }).sort((a, b) => (a?.draft_power ?? '').localeCompare(b?.draft_power ?? ''))

  const makes = uniqBy(staticInvData?.data.implements, (item) => {
    return item.make_id
  })
    .filter((make) => make.draft_power === draftPower)
    .sort((a, b) => a.make.localeCompare(b.make))

  const models = uniqBy(staticInvData?.data?.implements, (item) => {
    return item.model_id
  })
    .filter((sub) => sub.make_id.toString() === selectedMake?.toString())
    .sort((a, b) => a.model.localeCompare(b.model))

  const districts = uniqBy(locations, (item) => item.district_id)
    .slice()
    .sort((a, b) => a.district_name.localeCompare(b.district_name))
  const subDistricts = uniqBy(
    locations.filter((sub) => sub.district_id.toString() === district?.toString()),
    (item) => item.sub_district_id
  )
    .slice()
    .sort((a, b) => a.sub_district_name.localeCompare(b.sub_district_name))
  const extensions = uniqBy(
    locations.filter((ext) => ext.district_id.toString() === district?.toString()),
    (item) => item.extension_area_id
  )
    .slice()
    .sort((a, b) => a.extension_area_name.localeCompare(b.extension_area_name))
  const villages = uniqBy(
    locations.filter((vil) => vil.extension_area_id.toString() === extensionArea?.toString()),
    (item) => item.village_id
  )
    .slice()
    .sort((a, b) => a.village_name.localeCompare(b.village_name))

  const onSubmit = (data: InventoryInput): void => {
    const payload = {
      merchant_id: merchant?.merchant_id,
      yom: dayjs(String(data.yom)).format('YYYY'),
      chassis_number: data.reg_number,
      resource_item: Number(selectedItem?.model_id),
      inventory_item_verification_status_id: 'PENDING_VERIFICATION',
      last_updated_by: Number(merchant?.user_id),
      inventory_type_id: 1002,
      village_id: Number(selectedVillage?.village_id)
    }

    if (inventoryData != null) {
      updateItem({ ...payload, inventory_id: inventoryData.inventory_id })
    } else {
      createItem(payload)
    }
  }

  return (
    <Box>
      <TabView className={'max-w-[900px]'}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col gap-8'}>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name={`reg_number`}
                defaultValue={inventoryData?.reg_number}
                disabled={isView === true || isMerchant}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        label={'Serial Number'}
                        placeholder={'Serial Number'}
                        className={'mt-0'}
                        classLabel={'bg-card'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={'w-full mt-2 h-full'}>
                <YearPicker
                  error={!(form.formState.errors.yom == null)}
                  helperText={form.formState.errors?.yom?.message ?? ''}
                  label={'Manufacture Year'}
                  className={'w-full'}
                  name={'yom'}
                  control={form.control}
                  defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
                  disabled={isView === true || isMerchant}
                />
              </div>
              <FormField
                control={form.control}
                name='draftPower'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setDraftPower(val)
                        setSelectedMake('')
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={draftPower?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Draft Power' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {draftPowers?.map((disc) => {
                          if (disc?.draft_power == null || disc?.draft_power === '') {
                            return null
                          }

                          return (
                            <SelectItem key={disc.make_id} value={disc?.draft_power ?? ''}>
                              {disc.draft_power}
                            </SelectItem>
                          )
                        })}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      disabled={draftPower === '' || isView === true || isMerchant}
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={selectedMake?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Make' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes?.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      disabled={selectedMake === '' || isView === true || isMerchant}
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      value={selectedModel?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`class`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        {...field}
                        value={
                          selectedItem?.implement_class != null
                            ? selectedItem?.implement_class?.toString()
                            : 'N/A'
                        }
                        type={'text'}
                        label={'Implement Class'}
                        placeholder={'Implement Class'}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`type`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        {...field}
                        type={'text'}
                        label={'Implement Type'}
                        placeholder={'Implement Type'}
                        value={
                          selectedItem?.implement_type != null
                            ? selectedItem?.implement_type.toString()
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`subType`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        label={'Implement Sub Type'}
                        placeholder={'Implement Sub Type'}
                        value={
                          selectedItem?.implement_sub_type != null
                            ? selectedItem?.implement_sub_type.toString()
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`bottoms`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        placeholder={
                          draftPower === ''
                            ? 'Number of Rows/Bottoms '
                            : inventoryItem?.implement_class === 'SEEDER'
                            ? 'Number of Rows'
                            : 'Number of Bottoms'
                        }
                        label={
                          draftPower === ''
                            ? 'Number of Rows/Bottoms '
                            : inventoryItem?.implement_class === 'SEEDER'
                            ? 'Number of Rows'
                            : 'Number of Bottoms'
                        }
                        value={
                          selectedItem != null && selectedItem?.implement_class === 'SEEDER'
                            ? selectedItem?.no_of_rows?.toString()
                            : selectedItem?.no_of_bottoms?.toString() ?? 'N/A'
                        }
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`workingWidth`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.working_width != null
                            ? selectedItem?.working_width?.toString()
                            : 'N/A'
                        }
                        classLabel={'bg-card'}
                        label={'Working Width'}
                        placeholder={'Working Width'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name={`system`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.implement_system != null
                            ? selectedItem?.implement_system?.toString()
                            : 'N/A'
                        }
                        label={'Implement System'}
                        placeholder={'Implement System'}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name={`attachment`}
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type={'text'}
                        {...field}
                        value={
                          selectedItem?.attachment != null
                            ? selectedItem?.attachment?.toString()
                            : 'N/A'
                        }
                        label={'Implement Attachment'}
                        placeholder={'Implement Attachment'}
                        classLabel={'bg-card'}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Text className={'text-center'}>
              Select the location your inventory is primarily stored
            </Text>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name='district'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setDistrict(val)
                        setSubDistrict('')
                        setExtensionArea('')
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={selectedVillage?.district_id?.toString()}
                      disabled={isView === true || isMerchant === true}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`District`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {districts.map((disc) => (
                          <SelectItem key={disc.district_id} value={disc.district_id.toString()}>
                            {disc.district_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='subDistrict'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSubDistrict(val)
                        setExtensionArea('')
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={selectedVillage?.sub_district_id?.toString()}
                      disabled={isView === true || isMerchant === true || district === ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`Sub-District`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {subDistricts.map((sub) => (
                          <SelectItem
                            key={sub.sub_district_id}
                            value={sub.sub_district_id.toString()}
                          >
                            {sub.sub_district_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='extension'
                disabled={subDistrict === ''}
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setExtensionArea(val)
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={selectedVillage?.extension_area_id?.toString()}
                      disabled={isView === true || isMerchant === true || subDistrict === ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`Extension Area`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {extensions.map((ext) => (
                          <SelectItem
                            key={ext.extension_area_id}
                            value={ext.extension_area_id.toString()}
                          >
                            {ext.extension_area_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='village'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setVillage(val)
                        field.onChange(val)
                      }}
                      value={selectedVillage?.village_id?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='village' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {villages.map((vil) => (
                          <SelectItem key={vil.village_id} value={vil.village_id.toString()}>
                            {vil.village_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className='flex w-full justify-between space-x-4'>
              <Button
                className='w-full sm:w-[18rem]'
                variant='secondary'
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancel
              </Button>
              {isView === true &&
                isMerchant === false &&
                inventoryData?.item_verification_status !== 'PENDING_VERIFICATION' && (
                  <Button
                    className='w-full sm:w-[18rem]'
                    onClick={() => {
                      navigate(
                        `${
                          role === 1001 ? '/officer' : '/service-provider'
                        }/inventory/assessment/${Number(inventoryData?.inventory_id)}`
                      )
                    }}
                  >
                    View Assessment
                  </Button>
                )}
              {isView === false && isMerchant === false && (
                <Button
                  className='w-full sm:w-[18rem]'
                  type='submit'
                  disabled={isLoading || (inventoryData != null && !isFormChanged)}
                  loading={isLoading}
                >
                  {inventoryData != null ? 'Update' : 'Submit'}
                </Button>
              )}
              {isMerchant === true && (
                <Button
                  className='w-full sm:w-[18rem]'
                  onClick={() => {
                    navigate(`/inventory/assessment/${Number(inventoryData?.inventory_id)}`)
                  }}
                >
                  {inventoryData?.item_verification_status === 'PENDING_VERIFICATION'
                    ? 'New Assessment'
                    : 'View Assessment'}
                </Button>
              )}
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
