import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoanElligibility } from './LoanElligibility'
import { useGetFarms } from '../../../../farmer/land-management/api/getFarms'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { Button, Loader, Text } from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../../hooks/usePloughSeason'

interface ModalStepperProps {
  onClose: () => void
}

export const ModalStepper: React.FC<ModalStepperProps> = ({ onClose }) => {
  const { farmer } = useFarmerStore()
  const [activeStep, setActiveStep] = useState<number>(1)
  const [isEligible, setIsEligible] = useState<boolean>(true)
  const [notEligibleReasons, setNotEligibleReasons] = useState<string[]>()
  const navigate = useNavigate()
  const { t } = useTranslation('loanManagement')
  const { data: farms, isLoading: farmsLoading } = useGetFarms(farmer?.farmer_id)
  const { data: croppingPlans, isLoading: croppingPlansLoading } = useGetCroppingPlans(
    farmer?.farmer_id as number
  )

  const { isCurrentSeason, isLoading: seasonLoading } = usePloughSeason()

  // Consider both types of data to be loading
  const isLoading = farmsLoading || croppingPlansLoading || seasonLoading
  const hasCroppingPlanForCurrentSeason = croppingPlans?.data.find((cp) =>
    isCurrentSeason(cp.plough_season)
  )
  useEffect(() => {
    if (!farmsLoading && !croppingPlansLoading) {
      // Initialize eligibility status
      let eligibility = true
      const reasons: string[] = []

      // Check for farms existence and verification
      if (farms == null || farms.data.length === 0) {
        eligibility = false
        reasons.push('You do not have any farms.')
      } else if (farms.data.length === 1 && farms.data[0].status !== 'verified') {
        eligibility = false
        reasons.push('Your farm is not verified.')
      }

      // Check for cropping plans existence and verification
      if (croppingPlans == null || croppingPlans.data.length === 0) {
        eligibility = false
        reasons.push('You do not have any cropping plans.')
      } else if (
        croppingPlans.data.length === 1 &&
        croppingPlans.data[0].is_verified !== 'Approved'
      ) {
        eligibility = false
        reasons.push('Your cropping plan is not approved.')
      } else if (hasCroppingPlanForCurrentSeason == null) {
        eligibility = false
        reasons.push('You do not have any valid cropping plans for this ploughing season.')
      }

      setIsEligible(eligibility)
      setNotEligibleReasons(reasons)
    }
  }, [farms, croppingPlans, farmsLoading, croppingPlansLoading])

  const handleNext = (): void => {
    setActiveStep((prevStep) => prevStep + 1)
  }

  const handlePrev = (): void => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  const onSubmit = (): void => {
    navigate('/farmer/loans/newloan')
  }

  return (
    <div className='w-full flex flex-col items-center p-6'>
      {isLoading ? (
        <div className='flex justify-center items-center h-full'>
          <Loader /> {/* Adjust as necessary for your Spinner component */}
        </div>
      ) : (
        <div className='w-full flex flex-col h-full'>
          <LoanElligibility isEligible={isEligible} notEligibleReasons={notEligibleReasons} />
          <div className='w-full flex justify-between mt-auto'>
            <Button variant={'secondary'} size={'xs'} onClick={onClose} className='w-32'>
              {t('home.cancel')}
            </Button>
            <Button
              variant={isEligible ? 'success' : 'destructive'}
              size='xs'
              onClick={onSubmit}
              disabled={!isEligible}
              className='w-32'
            >
              <Text variant='colorText'>{t('home.cont')}</Text>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
