import type React from 'react'
import { useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button, Text } from '../../../../components/ui'
import { useTranslation } from 'react-i18next'

interface SignaturePadProps {
  setSignature: (signature: string) => void
}

const SigningPad: React.FC<SignaturePadProps> = ({ setSignature }) => {
  const sigPadRef = useRef<SignatureCanvas>(null)

  const clear = (): void => {
    sigPadRef.current?.clear()
  }

  const save = (): void => {
    if (sigPadRef.current != null) {
      setSignature(sigPadRef.current.toDataURL())
    }
  }

  const { t } = useTranslation('genericTranslation')

  return (
    <div className='flex flex-col align-center w-full gap-4'>
      <Text variant={'colorText'} size={'body'}>
        {t('signaturePad.prompt')}
      </Text>
      <div className='flex flex-row justify-center w-full h-full border-1 border-black rounded-md'>
        <SignatureCanvas
          penColor='black'
          canvasProps={{
            height: 150,
            className: 'sigCanvas border-2 border-gray-300 rounded-md w-[75%] border border-dashed'
          }}
          ref={sigPadRef}
        />
      </div>
      {/* Adjust this div to match the canvas width */}
      <div className='flex flex-row justify-between pb-4 w-full'>
        <Button variant={'destructiveOutlined'} size={'xs'} onClick={clear}>
          {t('signaturePad.clear')}
        </Button>
        <Button variant={'success'} size={'xs'} onClick={save}>
          {t('signaturePad.set')}
        </Button>
      </div>
    </div>
  )
}

export default SigningPad
