/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'

interface TextProp {
  className?: string
  variant?:
    | 'primaryLight'
    | 'error'
    | 'primary'
    | 'primaryDark'
    | 'secondary'
    | 'bodyText'
    | 'bodyTextLight'
    | 'secondaryLight'
    | 'warning'
    | 'success'
    | 'colorText'
  children?: React.ReactNode
  size?: 'large' | 'small' | 'body' | 'bodyBold' | 'medium' | 'xLarge'
  onClick?: () => void
}

export const Text: React.FC<TextProp> = ({
  children,
  onClick,
  size = 'body',
  className,
  variant = 'bodyText'
}) => {
  const classMappings = {
    small: 'text-[0.75rem] font-redHat font-normal ',
    body: 'text-[0.8rem] sm:text-[0.875rem] font-redHat font-medium ',
    bodyBold: 'text-[0.8rem] sm:text-[0.875rem] font-redHat font-bold ',
    medium: 'text-[1rem] sm:text-[1.1rem] font-redHat font-bold',
    large: 'text-[1.15rem] md:text-[1.25rem] lg:text-[1.6rem] font-redHat font-bold capitalize ',
    xLarge: 'text-[1.5rem] md:text-[1.6rem] lg:text-[1.7rem] font-redHat font-bold capitalize'
  }
  const colorClass = {
    bodyText: 'text-[#393939]',
    bodyTextLight: 'text-[#6D7278]',
    primaryLight: 'text-[color:var(--color-primary-light)]',
    primary: 'text-[color:var(--color-primary)]',
    primaryDark: 'text-[color:var(--color-primary-dark)]',
    secondary: '',
    secondaryLight: 'text-[#afafb0]',
    error: 'text-[var(--color-error)]',
    success: 'text-[var(--color-success)]',
    warning: 'text-[var(--color-warning)]',
    colorText: 'text-[var(--color-text)]',
    '': ''
  }
  // Create an array of class names based on the selected variant and size
  const classNames = [classMappings[size], colorClass[variant], className ?? ''].join(' ')

  return (
    <span className={classNames} onClick={onClick}>
      {children}
    </span>
  )
}
