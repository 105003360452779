import { type ApiResponse } from 'types'
import { useMutation } from '@tanstack/react-query'
import { changePassword, type ChangePasswordDTO } from '../api/changePassword'
import { useOfficerStore } from '../stores/useOfficerStore'
import { useMerchantStore } from '../stores/useMerchantStore'
import { mapCmsErrorToMessage } from '../utils/apiErrors'
import { AxiosError, type AxiosResponse } from 'axios'
import { useToast } from '../components/ui'
import { useSuperTokens } from '../features/authentication/hooks/useSuperTokens'

interface UseChangePassword {
  updatePassword: (oldPassword: string, newPassword: string) => void
  isLoading: boolean
}

export const useChangePassword = (): UseChangePassword => {
  const { officer } = useOfficerStore()
  const { merchant } = useMerchantStore()
  const { logOut } = useSuperTokens()
  const { toast } = useToast()

  const changePasswordMutation = useMutation({
    mutationFn: async (dto: ChangePasswordDTO) => {
      return changePassword(dto)
    }
  })

  const updatePassword = (oldPassword: string, newPassword: string): void => {
    changePasswordMutation.mutate(
      {
        newPassword,
        oldPassword,
        userId: officer?.user_id ?? merchant?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void logOut()
            toast({
              variant: 'success',
              title: 'Your Password has been successfully updated.'
            })
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    updatePassword,
    isLoading: changePasswordMutation.isLoading
  }
}
