import moment from 'moment'
import type React from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { Box, TabView } from 'components/App/Layout'
import { AvatarFallback, Avatar, Text, AvatarImage, Button } from 'components/ui'
import { ArrowLeft } from 'lucide-react'
import farmerIcon from 'assets/logos/farmer.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface InfoProps {
  label: string
  name: string
}
const Info: React.FC<InfoProps> = ({ label, name }) => {
  return (
    <div className={'flex flex-col'}>
      <Text size={'small'} className={'ml-1 capitalize'} variant={'primary'}>
        {label}
      </Text>
      <Text size={'medium'} className={label !== 'email address' ? 'capitalize' : ''}>
        {name}
      </Text>
    </div>
  )
}
export const Profile: React.FC = () => {
  const navigate = useNavigate()
  const { farmer } = useFarmerStore()
  const { t } = useTranslation('authenticationTranslation')

  const handleBack = (): void => {
    navigate(-1)
  }
  return (
    <Box>
      <div className={'flex items-center gap-2 mt-2 sm:mt-4'}>
        <Button
          onClick={handleBack}
          variant={'ghost'}
          className={
            'bg-transparent gap-2 py-1 text-[clamp(1.3rem,4vw,1.5rem)] leading-[70%] font-semibold capitalize'
          }
        >
          <ArrowLeft />
        </Button>
        <Text size={'large'}>{t('personalDetails.profile')}</Text>
      </div>

      <TabView>
        <div className={'flex gap-4 items-center'}>
          <Avatar className={'h-[clamp(3rem,4vw,4rem)] w-[clamp(3rem,4vw,4rem)]'}>
            <AvatarImage src={farmerIcon} />
            <AvatarFallback>
              <Text size={'medium'} className='fa-solid fa-user text-success'></Text>
            </AvatarFallback>
          </Avatar>
          <div className={'flex flex-col'}>
            <Text size={'small'} className={'capitalize'}>
              {t('personalDetails.farmerId')}
            </Text>
            <Text size={'medium'} variant={'primary'}>
              {farmer?.farmer_id}
            </Text>
          </div>
        </div>

        {/* Personal Information */}
        {farmer?.farmer_type === 1 ? (
          <div className={'flex flex-col border-y border-input py-4'}>
            <Text size={'medium'} variant={'muted'}>
              {t('personalDetails.info')}
            </Text>
            <div
              className={
                'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
              }
            >
              <Info
                label={`${t('personalDetails.surname')}`}
                name={farmer?.surname?.toUpperCase() ?? ''}
              />
              <Info
                label={`${t('personalDetails.forename')}`}
                name={farmer?.firstname?.toUpperCase() ?? ''}
              />
              <Info
                label={`${t('personalDetails.dob')}`}
                name={moment(farmer?.individual_dob).format('DD-MM-YYYY')}
              />
              <Info label={`${t('personalDetails.pob')}`} name={farmer?.individual_pob ?? ''} />
              <Info label={'omang/ID number'} name={farmer?.omang ?? ''} />
              <Info
                label={`${t('personalDetails.expiry')}`}
                name={moment(farmer?.individual_expiry_date).format('DD-MM-YYYY')}
              />
              <Info
                label={`${t('personalDetails.gender')}`}
                name={
                  farmer?.individual_gender === 'M'
                    ? t('personalDetails.male')
                    : farmer?.individual_gender === 'F'
                    ? t('personalDetails.female')
                    : ''
                }
              />
            </div>
          </div>
        ) : null}

        {/* Company Information */}
        {farmer?.farmer_type === 2 ? (
          <div className={'flex flex-col border-y border-input py-4'}>
            <Text size={'medium'} variant={'muted'}>
              Company Information
            </Text>
            <div
              className={
                'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
              }
            >
              <Info label={`Company Name`} name={farmer?.company_name?.toUpperCase() ?? ''} />
              <Info label={`UIN`} name={farmer?.company_reg_no ?? ''} />
              <Info
                label={`Company Registration Date`}
                name={moment(farmer?.company_reg_date).format('DD-MM-YYYY')}
              />
            </div>
          </div>
        ) : null}

        <div className={'flex flex-col'}>
          <Text size={'medium'} variant={'muted'}>
            {t('personalDetails.contact')}
          </Text>
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-4 list-none py-4'
            }
          >
            <Info
              label={`${t('personalDetails.email')}`}
              name={farmer?.email !== '' ? farmer?.email?.toUpperCase() ?? '' : 'N/A'}
            />
            <Info label={`${t('personalDetails.number')}`} name={farmer?.contact ?? ''} />
            <Info
              label={`${t('personalDetails.physical')}`}
              name={farmer?.physical_addr?.toUpperCase() ?? 'N/A'}
            />
            <Info
              label={`${t('personalDetails.postal')}`}
              name={farmer?.postal_addr?.toUpperCase() ?? 'N/A'}
            />
          </div>
        </div>
      </TabView>
    </Box>
  )
}
