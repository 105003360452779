import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import type React from 'react'
import { useState } from 'react'
import { Button, Text } from '../../../../../../../components/ui'
import { type Adjuvants, type Pesticides } from '../../../../../../../types/static'
import { useTranslation } from 'react-i18next'
import type { PestControl, WeedingChemicals } from '../../../../../../../types'
import collect from 'collect.js'
import {
  type SelectedPesticides,
  usePestAndDiseaseStore
} from '../../../../stores/usePestAndDiseaseStore'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  pesticide: Pesticides
  isLoading: boolean
  pest: PestControl | null | undefined
  next: () => void
  back: () => void
  submit: ((selected: SelectedPesticides[] | undefined) => void) | undefined
}

export function AdjuvantsPesticidesTable<TData, TValue>({
  columns,
  data,
  pesticide,
  next,
  back,
  pest,
  isLoading,
  submit
}: DataTableProps<TData, TValue>): React.ReactNode {
  const collection = collect(
    pest?.chemicals?.filter(
      (item) =>
        item.chemical_price_detail_id === pesticide.pesticides_price_detail_id &&
        item.pest_id === pesticide.pest_id &&
        item.adjuvant == null
    ) ?? []
  )
  const selectedAdjuvantApi = collection.isEmpty()
    ? {}
    : (collection
        .mapWithKeys((item: WeedingChemicals) => [
          [item?.adjuvant_price_detail_id?.toString()],
          true
        ])
        .all() as unknown as Record<string, boolean>)

  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const { selectedPesticides, setSelectedPesticides } = usePestAndDiseaseStore()
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    pest != null ? { ...selectedAdjuvantApi } : {}
  )
  const table = useReactTable({
    data,
    columns,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => (row as Adjuvants).adjuvant_price_detail_id.toString(),
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const { t } = useTranslation('croppingPlan')

  const onSubmit = (): void => {
    if (
      table.getRowCount() === 1
        ? table.getIsAllRowsSelected()
        : table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected()
    ) {
      const selectedAdjuvantFromTable = table
        .getSelectedRowModel()
        .rows.map((row) => row.original as Adjuvants)

      const updatedPesticidesList = selectedPesticides?.map((item) => {
        if (item.chemical.registration_no === pesticide.registration_no) {
          return {
            ...item,
            adjuvant: selectedAdjuvantFromTable[0]
          }
        }
        return item
      })

      setSelectedPesticides(updatedPesticidesList ?? [])

      if (submit != null) {
        submit(updatedPesticidesList)
      } else {
        next()
      }
    } else {
      next()
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col sm:flex-row gap-4 justify-start sm:items-center'}>
        <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
          Adjuvant Selection for {pesticide?.pesticide_name} / {pesticide?.pest_name}
        </Text>
      </div>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    row.toggleSelected()
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} selectable />
      <div className='w-full flex justify-end gap-2'>
        <Button disabled={isLoading} onClick={back} size={'sm'} variant={'secondary'}>
          {t('footer.prev')}
        </Button>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={onSubmit}
          size={'sm'}
          variant={'primary'}
        >
          {t('footer.next')}
        </Button>
      </div>
    </div>
  )
}
