import type React from 'react'
import { useFilePicker } from 'use-file-picker'
import { InputField } from 'components/Form'
import { MainContainer, RowContainer } from 'features/loan-management/components/stepper'
import { AttachFile } from '@mui/icons-material'
import z, { number, object, type TypeOf } from 'zod'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { type SubmitHandler } from 'react-hook-form'
import { BasicButton } from 'components/Elements/Button'
import { useAtom } from 'jotai'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { CustomRadio, type RadioOptions } from '../../../../../components/Form/Radio'
import { Text } from '../../../../../components/Elements/Text'
import { companyLoanStore } from '../stores'
import { type CompanyLoanStepperProps } from '../../../types'

const yesOrNo: Array<RadioOptions<string>> = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
]

const schema = object({
  fp_checklist1: z.enum(['yes', 'no']),
  fp_checklist2: z.enum(['yes', 'no']),
  fp_checklist3: z.enum(['yes', 'no']),
  fp_checklist4: number({ invalid_type_error: 'This should be a number' }).default(0),
  fp_checklist5: z.enum(['yes', 'no'])
})

type FinancialParticularsCompanyDetailsInput = TypeOf<typeof schema>
export const FinancialParticularsCompany: React.FC<CompanyLoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(companyLoanStore)

  const onSubmit: SubmitHandler<FinancialParticularsCompanyDetailsInput> = (
    data: FinancialParticularsCompanyDetailsInput
  ): void => {
    setLoanData((prev) => {
      return {
        ...prev!,
        financial_particulars: {
          fp_checklist1: data.fp_checklist1,
          fp_checklist2: data.fp_checklist2,
          fp_checklist3: data.fp_checklist3,
          fp_checklist4: data.fp_checklist3 === 'no' ? 0 : Number(data.fp_checklist4),
          fp_checklist5: data.fp_checklist5,
          fp_checklist6: 'no',
          last_update_by: farmer?.user_id as number
        }
      }
    })
    onNext()
  }

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'pdf/*',
    multiple: true,
    limitFilesConfig: { max: 2 },
    // minFileSize: 1,
    maxFileSize: 50 // in megabytes
  })

  return (
    <FormWithStepper<FinancialParticularsCompanyDetailsInput, typeof schema>
      className={'flex flex-colflex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit, control, watch }) => (
        <MainContainer>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Financial Particulars
          </Text>
          <div className={'w-full flex flex-col p-2 px-4'}>
            <RowContainer>
              <CustomRadio
                label='Is there a criminal record on you?'
                name={'fp_checklist1'}
                control={control}
                options={yesOrNo}
                defaultValue={loanData?.financial_particulars?.fp_checklist1 as unknown as string}
              />
            </RowContainer>

            <RowContainer>
              <CustomRadio
                label='Is there a pending criminal case on you? '
                name={'fp_checklist2'}
                control={control}
                options={yesOrNo}
                defaultValue={loanData?.financial_particulars?.fp_checklist2 as unknown as string}
              />
            </RowContainer>

            <RowContainer>
              <CustomRadio
                label='Have you been Blacklisted? '
                name={'fp_checklist3'}
                control={control}
                options={yesOrNo}
                defaultValue={loanData?.financial_particulars?.fp_checklist3 as unknown as string}
              />
              {(watch('fp_checklist3') === 'yes' ||
                loanData?.financial_particulars?.fp_checklist3 === 'yes') && (
                <InputField
                  className='flex-[1_0_30px] capitalize'
                  label={'Amount(BWP)'}
                  type='number'
                  error={!(errors.fp_checklist4 == null)}
                  helperText={errors?.fp_checklist4?.message ?? ''}
                  register={register('fp_checklist4', { valueAsNumber: true })}
                  defaultValue={loanData?.financial_particulars?.fp_checklist4}
                />
              )}
            </RowContainer>
            <RowContainer>
              <CustomRadio
                label='Have you signed a Guarantee or Surety for any entity?'
                name={'fp_checklist5'}
                control={control}
                options={yesOrNo}
                defaultValue={loanData?.financial_particulars?.fp_checklist5 as unknown as string}
              />
              {watch('fp_checklist5') === 'yes' && (
                <p
                  className='flex-[1_0_30px] text-[var(--color-primary)] flex gap-1 items-center capitalize cursor-pointer'
                  onClick={() => {
                    openFileSelector()
                  }}
                >
                  <AttachFile /> attachment
                </p>
              )}
            </RowContainer>
            <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
              <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
              <BasicButton
                label={'Next'}
                variant={'primary'}
                onClick={handleSubmit(onSubmit)}
                size={'xs'}
              />
            </div>
          </div>
        </MainContainer>
      )}
    </FormWithStepper>
  )
}
