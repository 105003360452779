import { Checkbox, FormControlLabel } from '@mui/material'
import { BasicButton } from 'components/Elements/Button'
import { InputField } from 'components/Form'
import { Text } from 'components/Elements/Text/Text'
import { useAtom } from 'jotai'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler } from 'react-hook-form'
import { number, object, type TypeOf } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { type CroppingPlan } from '../../../../../types'
import { useGetFarms } from '../../../../farmer/land-management/api/getFarms'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { type CroppingPlanId } from '../../../types/individual'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { classifyHectare } from '../../../utils/farmerClassification'
import { companyLoanStore, farmerType } from '../stores'
import { type CompanyLoanStepperProps } from '../../../types'

export const SelectCompanyPlans: React.FC<CompanyLoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const [, setEnableQuery] = useState(false)
  const { data: farms, isLoading } = useGetFarms(farmer?.farmer_id)
  const { data: croppingPlans } = useGetCroppingPlans(farmer?.farmer_id as number)
  const [selectedFarmIds, setSelectedFarmIds] = useState<number[]>([])
  const [selectedCroppingPlans, setSelectedCroppingPlans] = useState<CroppingPlanId[]>([])
  const [calculatedTotalAmount, setCalculatedTotalAmount] = useState<number | null>(null)
  const [totalHectrage, setTotalHectrage] = useState<number | null>(null)
  const [famerType, setFarmerType] = useAtom(farmerType)
  const calculateTotalSize = (): void => {
    if (selectedCroppingPlans.length === 0) {
      setTotalHectrage(null)
    } else {
      const totalSizeAmount = selectedCroppingPlans.reduce((total, croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        if (selectedCroppingPlan != null) {
          total += selectedCroppingPlan.hectarage
        }
        return total
      }, 0)
      setTotalHectrage(totalSizeAmount)

      setFarmerType((prev) => ({
        ...prev,
        farmerType: classifyHectare(Number(totalHectrage))
      }))
    }
  }

  const calculateTotalAmount = (): void => {
    if (selectedCroppingPlans.length === 0) {
      setCalculatedTotalAmount(null)
    } else {
      const totalAmount = selectedCroppingPlans.reduce((total, croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        if (selectedCroppingPlan != null) {
          total += parseFloat(selectedCroppingPlan.total_cost.replace(/,/g, ''))
        }
        return total
      }, 0)
      setCalculatedTotalAmount(totalAmount)
    }
  }

  const schema = object({
    loanAmount: number({
      invalid_type_error: 'This should be a number'
    }).refine(
      (value) => {
        return calculatedTotalAmount !== null && value <= calculatedTotalAmount
      },
      {
        message: `Loan amount must be less than or equal to the  ${
          calculatedTotalAmount !== null ? `P` + calculatedTotalAmount.toString() : 'given amount'
        }`
      }
    )
  })
  type LoanAmountDetailsInput = TypeOf<typeof schema>

  useEffect(() => {
    if (farmer?.farmer_id !== undefined && farms === undefined) {
      setEnableQuery(true)
    }
    calculateTotalAmount()
    calculateTotalSize()
  }, [farms, farmer, selectedCroppingPlans])

  const handleCroppingPlanCheckboxClick = (croppingPlanId: number): void => {
    setSelectedCroppingPlans((prevSelectedCroppingPlans) => {
      const idToToggle = croppingPlanId as unknown as CroppingPlanId
      if (prevSelectedCroppingPlans.includes(idToToggle)) {
        return prevSelectedCroppingPlans.filter((id) => id !== idToToggle)
      } else {
        return [...prevSelectedCroppingPlans, idToToggle]
      }
    })
  }

  const mapCroppingPlansToObjects = (): CroppingPlanId[] => {
    return selectedCroppingPlans
      .map((croppingPlanId) => {
        const selectedCroppingPlan = croppingPlans?.data.find(
          (plan: { cropping_plan_id: number }) =>
            plan.cropping_plan_id === (croppingPlanId as unknown as number)
        )
        return selectedCroppingPlan != null
          ? {
              cropping_plan_id: croppingPlanId
            }
          : null
      })
      .filter(Boolean) as unknown as CroppingPlanId[]
  }

  const areCroppingPlansSelected = (): boolean => {
    return selectedCroppingPlans.length > 0
  }
  const hasApprovedCroppingPlan = (farmId: number): boolean => {
    return (
      croppingPlans?.data?.some(
        (plan: CroppingPlan) => plan.is_verified === 'Approved' && plan.farm_id === farmId
      ) ?? false
    )
  }

  const onSubmit: SubmitHandler<LoanAmountDetailsInput> = (data: LoanAmountDetailsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      cropping_plan: mapCroppingPlansToObjects() as unknown as CroppingPlanId[],
      total_amount: {
        loan_req1: data?.loanAmount
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<LoanAmountDetailsInput, typeof schema>
      className={'flex flex-col flex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit }) => (
        <div className='w-full flex flex-col gap-2 relative'>
          {!isLoading && farms?.data != null && farms.data.length > 0 && (
            <Text size={'medium'} variant={'bodyTextLight'} className={'ml-8'}>
              Select verified Cropping Plans in the Farms you are applying a loan for:
            </Text>
          )}
          <div className='w-full flex flex-col'>
            {farms?.data
              .filter((item) => item.status === 'verified' && hasApprovedCroppingPlan(item.farm_id))
              .map((item) => {
                const isSelected = selectedFarmIds.includes(item.farm_id)
                return (
                  <div
                    className={'bg-[#F3F6FD] w-full rounded-md flex flex-col my-2 '}
                    key={item.farm_id}
                  >
                    <FormControlLabel
                      sx={{
                        position: 'relative',
                        padding: '0 1rem',
                        display: ' flex'
                      }}
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={() => {
                            if (isSelected) {
                              setSelectedFarmIds(
                                selectedFarmIds.filter((id) => id !== item.farm_id)
                              )
                            } else {
                              setSelectedFarmIds([...selectedFarmIds, item.farm_id])
                            }
                          }}
                          value={item.farm_id.toString()}
                        />
                      }
                      label={<Text>{`${item.farm_name.toUpperCase()} FARM`}</Text>}
                    />
                    {isSelected && croppingPlans == null && (
                      <Text className={'m-8'}>Select verified cropping plan</Text>
                    )}
                    {isSelected &&
                      croppingPlans?.data !== undefined &&
                      croppingPlans?.data !== null &&
                      croppingPlans?.data
                        .filter(
                          (i: CroppingPlan) =>
                            i.is_verified === 'Approved' && i.farm_id === item.farm_id
                        )
                        .map((option: CroppingPlan) => (
                          <div
                            className=' w-[95%] flex border-t-2 border-solid border-[#afafb0] mx-auto px-4'
                            key={option.cropping_plan_id}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedCroppingPlans.includes(
                                    option.cropping_plan_id as unknown as CroppingPlanId
                                  )}
                                  onChange={() => {
                                    handleCroppingPlanCheckboxClick(option.cropping_plan_id)
                                  }}
                                  value={option.cropping_plan_id.toString()}
                                />
                              }
                              label={
                                <Text size={'small'}>
                                  {`${option.cropping_plan_id} - ${option.crop_name} - ${option.hectarage} ha Cropping Plan`}
                                </Text>
                              }
                            />
                          </div>
                        ))}
                  </div>
                )
              })}
          </div>

          {isLoading && farms === undefined && (
            <Text variant='primary' size={'medium'} className={'ml-8'}>
              Please wait ...
            </Text>
          )}
          {!isLoading && farms?.data.length === 0 && (
            <Text variant='bodyTextLight' size={'medium'} className={'ml-4 mb-8'}>
              You do not have a verified farm.
            </Text>
          )}
          {!isLoading &&
            (farms?.data?.length ?? 0) > 0 &&
            selectedFarmIds.length > 0 &&
            !areCroppingPlansSelected() && (
              <Text variant='error' size={'small'} className={'ml-8 mb-8'}>
                Select a verified cropping plan.
              </Text>
            )}

          {calculatedTotalAmount !== null && (
            <Text size={'medium'} variant={'bodyTextLight'} className={'font-normal m-8'}>
              You are Eligible for:{' '}
              <Text variant={'primary'} size={'medium'}>
                P{calculatedTotalAmount?.toFixed(2)}
              </Text>
            </Text>
          )}

          {areCroppingPlansSelected() ? (
            <div className={'max-w-full md:max-w-[400px] flex flex-col gap-4'}>
              <Text variant={'bodyTextLight'} size={'medium'} className={'font-normal ml-8'}>
                Enter an amount not greater than{' '}
                <small> P{calculatedTotalAmount?.toFixed(2)}</small>
              </Text>
              <InputField
                className='flex-[1_0_230px] capitalize'
                label={'Loan Amount(BWP)'}
                type='number'
                error={!(errors.loanAmount == null)}
                helperText={errors?.loanAmount?.message ?? ''}
                register={register('loanAmount', { valueAsNumber: true })}
                defaultValue={loanData?.total_amount?.loan_req1}
              />
            </div>
          ) : (
            <Text variant='error' size={'medium'} className={'ml-8 mb-8'}>
              Please select at least one cropping plan to proceed.
            </Text>
          )}

          <div className='w-full justify-between hidden lg:flex'>
            <BasicButton label={'Cancel'} variant={'secondary'} size={'xs'} onClick={onBack} />
            <BasicButton
              label={'Next'}
              variant={'primary'}
              size={'xs'}
              disabled={!areCroppingPlansSelected()}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      )}
    </FormWithStepper>
  )
}