import type React from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { Button, Card, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { TabView } from '../../../../components/App/Layout'
import { SearchBar } from '../../../../components/Form/SearchBar'
import { useGetCroppingPlanByExt } from '../api/getCroppingPlanByExt'
import { LabCroppingPlansTable } from '../components/tables/LabCroppingPlansTable'
import { labCroppingPlansColumns } from '../components/tables/lab-cropping-plans-columns'
import { useTranslation } from 'react-i18next'

export const AllCroppingPlans: React.FC = () => {
  const navigate = useNavigate()
  const { officer } = useOfficerStore()
  const [newRequest, setNewRequest] = useState(false)
  const { t } = useTranslation('extensionOfficer')
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetCroppingPlanByExt(officer?.ext_area_id?.toString() ?? '')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const [searchQuery, setSearchQuery] = useState(query ?? '')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  if (isLoading) {
    return (
      <div className='grid place-items-center min-h-[30%] h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return <ErrorPage />
  }

  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleOpenNewRequest = (): void => {
    setNewRequest(true)
  }

  if (croppingPlans?.data === undefined) {
    return (
      <div className='max-w-[1700px] w-full flex-col gap-4 flex justify-center mx-auto px-0 py-4'>
        <Text size='large'>Cropping Plans</Text>
        <div className='w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
          <Text size='medium' variant='bodyTextLight'>
            {t('cp.noCP')}
          </Text>
          <Button size='xs' variant='success' onClick={handleOpenNewRequest}>
            {t('cp.vCP')}
            <p className='fa-solid fa-add' />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <TabView>
      <div className='flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'>
        <Text size='large'>{t('cp.myCP')}</Text>
        <Button size='xs' variant='success' onClick={handleOpenNewRequest}>
          {t('cp.vCP')}
          <p className='fa-solid fa-add' />
        </Button>
      </div>

      <Card className='w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
        <LabCroppingPlansTable
          columns={labCroppingPlansColumns(t)}
          data={croppingPlans.data.filter(
            (item) => item.is_verified !== 'Submitted CP Verification'
          )}
        />
      </Card>

      <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
        <DialogContent className='bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'>
          <Text size='medium'>{t('cp.vFa')}</Text>
          <SearchBar
            label={t('cp.searchFID')}
            className='w-full max-w-[700px] flex gap-8'
            searchQuery={searchQuery}
            disabled={searchQuery.length !== 7}
            onChange={handleChange}
            onSearch={() => {
              navigate(`/officer/verify-cp/${searchQuery ?? ''} `)
            }}
            isSearching={isLoading}
          />
        </DialogContent>
      </Dialog>
    </TabView>
  )
}