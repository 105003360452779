import type React from 'react'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { Card, Loader } from '../../../../../components/ui'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { FarmersTable } from '../farmers/FarmersTable'
import { farmersColumns } from '../farmers/columns'
import { useTranslation } from 'react-i18next'
import { useGetFarmers } from '../../api/getFarmers'
import { uniqBy } from 'lodash'

export const FarmOwners: React.FC = () => {
  const { officer } = useOfficerStore()
  const { data, isLoading, error } = useGetFarmers(officer?.ext_area_id)
  const { t } = useTranslation('extensionOfficer')

  if (isLoading) {
    return (
      <div className='grid place-items-center min-h-[30%] h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return <ErrorPage />
  }

  const farmers = uniqBy(data.data, 'farmer_id')

  return (
    <Card className='w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
      <FarmersTable columns={farmersColumns(t)} data={farmers ?? []} />
    </Card>
  )
}
