import type React from 'react'
import { type FC } from 'react'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { Navigate } from 'react-router-dom'
import { Loader } from '../ui'

interface PasswordProtectedRouteProps {
  children: React.ReactNode
}
export const PasswordProtectedRoute: FC<PasswordProtectedRouteProps> = ({ children }) => {
  const claimValue = Session.useClaimValue(UserRoleClaim)
  if (claimValue.loading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }
  const roles = claimValue.value

  if (
    Array.isArray(roles) &&
    roles.includes('EXTENSION_OFFICER') &&
    roles.includes('FIRST_LOGIN')
  ) {
    return <Navigate to={'/officer/change-password'} />
  } else if (
    Array.isArray(roles) &&
    roles.includes('SOIL_SAMPLE_LAB') &&
    roles.includes('FIRST_LOGIN')
  ) {
    return <Navigate to={'/soil-lab/change-password'} />
  } else if (
    Array.isArray(roles) &&
    roles.includes('MECHANISATION_OFFICER') &&
    roles.includes('FIRST_LOGIN')
  ) {
    return <Navigate to={'/inventory/change-password'} />
  } else if (
    Array.isArray(roles) &&
    roles.includes('SEED_DEALER') &&
    roles.includes('FIRST_LOGIN')
  ) {
    return <Navigate to={'/seed-dealer/change-password'} />
  } else if (
    Array.isArray(roles) &&
    roles.includes('SERVICE_PROVIDER') &&
    roles.includes('FIRST_LOGIN')
  ) {
    return <Navigate to={'/service-provider/change-password'} />
  } else {
    return children
  }
}
