import {
  Banner,
  About,
  NavLandingPage,
  Categories,
  Gallery,
  Footer,
  Guidelines,
} from 'features/landing/components'
import { Layout } from 'components/ui'
import type React from 'react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'

export const Homepage: React.FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const claimValue = Session.useClaimValue(UserRoleClaim)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <Layout variant={'bodyMax'} className={'bg-background-dark relative'}>
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <Banner />
      <About />
      <Gallery />
      <Categories />
      {flags.landingGuidelines === true ? <Guidelines /> : null}
      <Guidelines />
      <Footer />
    </Layout>
  )
}
