import type React from 'react'
import { MainContainer, RowContainer } from 'features/loan-management/components/stepper'
import z, { object, type TypeOf } from 'zod'
import { FormWithStepper } from '../../generic/FormWithStepper'
import { type SubmitHandler } from 'react-hook-form'
import { BasicButton } from 'components/Elements/Button'
import { CustomRadio, type RadioOptions } from '../../../../../components/Form/Radio'
import { Text } from '../../../../../components/Elements/Text'
import { useAtom } from 'jotai'
import { companyLoanStore } from '../stores'
import { type CompanyLoanStepperProps } from '../../../types'

export const yesOrNo: Array<RadioOptions<string>> = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' }
]

const schema = object({
  loan_exists: z.enum(['yes', 'no'])
})

type CompanyNDBLoansInput = TypeOf<typeof schema>
export const CompanyNDBLoans: React.FC<CompanyLoanStepperProps> = ({
  onBack,
  onNext,
  steps,
  activeStep
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const onSubmit: SubmitHandler<CompanyNDBLoansInput> = (data: CompanyNDBLoansInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      history_of_ndb_loans: {
        loan_exists: data.loan_exists
      }
    }))
    onNext()
  }

  return (
    <FormWithStepper<CompanyNDBLoansInput, typeof schema>
      className={'flex flex-colflex-grow-[3] justify-between w-full'}
      isLoading={false}
      isDisabled={false}
      onSubmit={onSubmit}
      steps={steps}
      activeStep={activeStep}
      onBack={onBack}
      schema={schema}
    >
      {({ register, formState: { errors }, handleSubmit, control, watch }) => (
        <MainContainer>
          <Text size={'medium'} variant={'bodyTextLight'} className={'ml-4'}>
            Current NDB Loans :
          </Text>
          <div className={'w-full flex flex-col p-2 px-4'}>
            <RowContainer>
              <CustomRadio
                label='Current NDB Loans?'
                name={'loan_exists'}
                control={control}
                options={yesOrNo}
                defaultValue={loanData?.history_of_ndb_loans?.loan_exists as string}
              />
            </RowContainer>

            <div className={'w-full p-8 hidden lg:flex flex-row justify-between gap-12'}>
              <BasicButton label={'Back'} variant={'secondary'} onClick={onBack} size={'xs'} />
              <BasicButton
                label={'Next'}
                variant={'primary'}
                onClick={handleSubmit(onSubmit)}
                size={'xs'}
              />
            </div>
          </div>
        </MainContainer>
      )}
    </FormWithStepper>
  )
}
