import { type FC } from 'react'
import { type Farm, type Lease } from 'types'
import { SoilCorrection } from '../../../soil-correction/routes/SoilCorrection'

interface WithFarm {
  farm: Farm
  lease?: never
}

interface WithLease {
  farm?: never
  lease: Lease
}

type SoilCorrectionTabProps = WithFarm | WithLease
export const SoilCorrectionTab: FC<SoilCorrectionTabProps> = ({ farm, lease }) => {
  if (farm != null) {
    return (
      <>
        <SoilCorrection farm={farm} />
      </>
    )
  }

  return (
    <>
      <SoilCorrection lease={lease} />
    </>
  )
}
