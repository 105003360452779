import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useState, useEffect } from 'react'
import { object, z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem
} from '../../../../../../components/ui'
import { StepperFooter } from '../Footer'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ChemicalWeeding } from './ChemicalWeeding'

export const manualSchema = object({
  technique: z.enum(['Manual', 'Chemical'], {
    required_error: 'You need to select a Weeding technique'
  })
})

// TODO manual weeding implementation
export const WeedingPlan: React.FC = () => {
  const [isChemical, setIsChemical] = useState<boolean>(false)
  const flags = useFlags()
  const ldClient = useLDClient()

  const form = useForm<z.infer<typeof manualSchema>>({
    resolver: zodResolver(manualSchema),
    defaultValues: {
      technique: undefined
    }
  })

  const onSubmit = (data: z.infer<typeof manualSchema>): void => {
    if (data.technique === 'Chemical') {
      setIsChemical(true)
    } else {
      console.log('manual')
    }
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.manualWeeding === true && !isChemical) {
    return (
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div
            className={
              'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
            }
          >
            <FormField
              control={form.control}
              name='technique'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel>Weeding Technique</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={`Manual`} />
                        </FormControl>
                        <FormLabel className='font-normal'>Manual</FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={`Chemical`} />
                        </FormControl>
                        <FormLabel className='font-normal'>Chemical</FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <StepperFooter />
        </form>
      </Form>
    )
  }

  return <ChemicalWeeding />
}
