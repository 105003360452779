import dayjs from 'dayjs'
import { useIndividualRegistration } from 'features/authentication/hooks/useIndividualRegistration'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type z from 'zod'
import { coerce, object, string, type TypeOf } from 'zod'
import { villageList } from '../../utils/villages'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { SearchField } from '../../../../components/Form'

const schema = object({
  omang: string()
    .min(9, 'Invalid omang number')
    .max(9, 'Invalid omang number')
    .regex(/[0-9]/, 'Omang should only contain numbers')
    .trim(),
  expiryDate: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  surname: string().min(1, 'Surname is required').trim(),
  forenames: string().min(1, 'Forenames are required').trim(),
  placeOfBirth: string().min(1, 'Place of birth is required').trim(),
  dateOfBirth: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  gender: string().nonempty('Gender is required')
})

export type PersonalDetailsInput = TypeOf<typeof schema>

interface PersonalDetailsProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

export const PersonalDetailsForm: React.FC<PersonalDetailsProps> = ({
  className,
  onNext,
  onBack
}: PersonalDetailsProps) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { isLoading, verifyOmangDetails } = useIndividualRegistration(onNext)
  const { omangDetails, setOmangDetails } = useIndividualRegistrationStore()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      omang: omangDetails?.omang ?? '',
      dateOfBirth:
        omangDetails?.dateOfBirth == null ? undefined : dayjs(omangDetails?.dateOfBirth).toDate(),
      expiryDate:
        omangDetails?.expiryDate == null
          ? undefined
          : dayjs(omangDetails?.expiryDate).toDate() ?? undefined,
      placeOfBirth: omangDetails?.placeOfBirth ?? '',
      forenames: omangDetails?.forenames ?? '',
      gender: omangDetails?.gender ?? undefined,
      surname: omangDetails?.surname ?? ''
    }
  })
  const onSubmit: SubmitHandler<PersonalDetailsInput> = (data: PersonalDetailsInput) => {
    void verifyOmangDetails(data)
  }
  const onSubmitBypass: SubmitHandler<PersonalDetailsInput> = (data: PersonalDetailsInput) => {
    setOmangDetails({
      omang: data.omang,
      dateOfBirth: data.dateOfBirth,
      expiryDate: data.expiryDate,
      forenames: data.forenames,
      gender: data.gender,
      placeOfBirth: data.placeOfBirth,
      surname: data.surname
    })
    onNext()
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const { t } = useTranslation('authenticationTranslation')

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4'}
        onSubmit={form.handleSubmit(flags.omangValidation === true ? onSubmit : onSubmitBypass)}
      >
        <FormField
          control={form.control}
          name={'omang'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>Omang</FormLabel>
              <FormControl>
                <Input
                  placeholder={`Omang`}
                  type={'number'}
                  inputMode={'numeric'}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <FormField
          control={form.control}
          name='dateOfBirth'
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('personalDetails.dob')}</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outlined'}
                      className={cn(
                        'text-left font-normal',
                        field.value != null && 'text-muted-foreground'
                      )}
                    >
                      {field.value != null ? (
                        format(field.value, 'PPP')
                      ) : (
                        <span>{t('personalDetails.dob')}</span>
                      )}
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0' align='start'>
                  <Calendar
                    initialFocus
                    mode='single'
                    captionLayout={'dropdown-buttons'}
                    fromYear={dayjs(new Date(Date.now())).toDate().getFullYear() - 120}
                    toDate={
                      new Date(
                        new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 18)
                      )
                    }
                    selected={field.value}
                    onSelect={field.onChange}
                    disabled={(date) =>
                      date >=
                      new Date(
                        new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 18)
                      )
                    }
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='expiryDate'
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('personalDetails.expiry')}</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outlined'}
                      className={cn(
                        'text-left font-normal',
                        field.value != null && 'text-muted-foreground'
                      )}
                    >
                      {field.value != null ? (
                        format(field.value, 'PPP')
                      ) : (
                        <span>{t('personalDetails.expiry')}</span>
                      )}
                      <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className='w-auto p-0' align='start'>
                  <Calendar
                    mode='single'
                    captionLayout={'dropdown-buttons'}
                    fromDate={dayjs(new Date(Date.now())).toDate()}
                    toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 10}
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={'forenames'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('personalDetails.forename')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('personalDetails.forename')}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <FormField
          control={form.control}
          name={'surname'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('personalDetails.surname')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('personalDetails.surname')}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <SearchField
          options={villageList}
          defaultValue={omangDetails !== null ? omangDetails.placeOfBirth : ''}
          label={t('personalDetails.pob')}
          name={'placeOfBirth'}
          control={form.control}
          error={!(form.formState.errors.placeOfBirth == null)}
          helperText={form.formState.errors?.placeOfBirth?.message ?? ''}
        />
        <FormField
          control={form.control}
          name='gender'
          render={({ field }) => (
            <FormItem>
              <FormLabel className={'ml-2 font-redHat'}>{t('personalDetails.gender')}</FormLabel>
              <Select
                onValueChange={(val) => {
                  field.onChange(val)
                }}
                defaultValue={omangDetails?.gender}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder={t('personalDetails.gender')} />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value='M'>
                    <Text className='capitalize'>{t('personalDetails.male')}</Text>
                  </SelectItem>
                  <SelectItem value='F'>
                    <Text className='capitalize'>{t('personalDetails.female')}</Text>
                  </SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={'flex flex-row justify-between'}>
          <Button className={'w-[46%]'} disabled={isLoading} variant={'secondary'} onClick={onBack}>
            {t('personalDetails.back')}
          </Button>
          <Button
            className={'w-[46%]'}
            variant={'primary'}
            disabled={isLoading}
            loading={isLoading}
            type={'submit'}
          >
            {t('personalDetails.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
