import type React from 'react'
import { useEffect, useState } from 'react'
import { Weeding } from './Weeding'
import { ChemicalSelection } from './chemical/ChemicalSelection'
import { useWeedingStore } from '../../../stores/useWeedingStore'
import { ApplicationMethod } from './chemical/ApplicationMethod'

export const ChemicalWeeding: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const { setSelectedHerbicides, setSchedule } = useWeedingStore()

  const handleNext = (): void => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = (): void => {
    setActiveStep(activeStep - 1)
  }

  useEffect(() => {
    setSelectedHerbicides(null)
    setSchedule(null)
  }, [])

  if (activeStep === 1) {
    return <ChemicalSelection back={handleBack} next={handleNext} />
  }

  if (activeStep === 2) {
    return <ApplicationMethod back={handleBack} />
  }

  return <Weeding onBackStep={handleBack} onNextStep={handleNext} />
}
