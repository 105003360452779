import { AgroChemicalDealers } from 'components/Auth/AgroChemicalDealers'
import { ServiceProvidersProfile } from 'features/profile/components/serviceProvidersProfile'
import { Navigate } from 'react-router-dom'
import { farmerRoutes } from 'routes/farmer'
import { inventoryRoutes } from 'routes/mechanization'
import { labRoutes } from 'routes/lab'
import { officerRoutes } from 'routes/officer'
import { seeddealerRoutes } from './seeddealer'
import { servicesprovidersRoutes } from './servicesproviders'

export const protectedRoutes = [
  farmerRoutes,
  officerRoutes,
  labRoutes,
  inventoryRoutes,
  seeddealerRoutes,
  servicesprovidersRoutes,
  {
    path: '/agrochemicals',
    element: <AgroChemicalDealers />,
    children: [
      { path: '/agrochemicals/profile', element: <ServiceProvidersProfile /> },
      { path: '*', element: <Navigate to={'.'} /> }
    ]
  }
]
