import { CroppingPlanTable } from './CroppingPlanTable'
import type React from 'react'
import { useEffect } from 'react'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { useParams } from 'react-router-dom'
import { useGetCroppingPlanList } from '../../api/getCroppingPlanList'
import { Text } from 'components/ui'
import { Loader } from '../../../../../components/ui'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { TabView } from '../../../../../components/App/Layout'
import { croppingPlanColumns } from './columns'
import { useTranslation } from 'react-i18next'

export const CroppingPlans: React.FC = () => {
  const flags = useFlags()
  const { t } = useTranslation('extensionOfficer')
  const ldClient = useLDClient()
  const { officer } = useOfficerStore()
  const { farmId } = useParams<{ farmId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { data, isLoading } = useGetCroppingPlanList(farmId, farmerId, officer?.ext_area_id)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.croppingPlan === true) {
    return (
      <TabView className={'items-center'}>
        <Text size={'medium'}>{t('cp.cu')}</Text>
      </TabView>
    )
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (data?.data === undefined) {
    return (
      <TabView className={'items-center'}>
        <Text size={'medium'}>{t('cp.noCP')}</Text>
      </TabView>
    )
  }

  return (
    <TabView>
      <CroppingPlanTable data={data?.data} columns={croppingPlanColumns(t)} />
    </TabView>
  )
}