import { type ColumnDef } from '@tanstack/react-table'

import { type GetInventoryRes } from 'features/farmer/inventory/types'
import { LabInventoryStatus } from '../LabInventoryStatus'
import { Text } from '../../../../../components/ui'

export const getInventoryColumns = (
  data: GetInventoryRes[],
  t: (key: string) => string
): Array<ColumnDef<GetInventoryRes>> => {
  return [
    {
      id: 'farmer_name',
      accessorFn: (row) => {
        if (row?.merchant_id != null) {
          return row.merchant_name
        } else if (row.farmer_type === 2) {
          return row.company_name
        } else {
          return row.farmer_name
        }
      },
      cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('farmer_name')}</Text>,
      header: t('mech.on')
    },
    {
      id: 'inventory_type_id',
      accessorFn: (row) => row.inventory_type_id,
      enableHiding: true,
      cell: ({ row }) => {
        const inventoryTypeId = row.getValue('inventory_type_id')
        let displayText = ''

        switch (inventoryTypeId) {
          case 1001:
            displayText = t('mech.pwrd')
            break
          case 1002:
            displayText = t('mech.nonPwrd')
            break
          case 1003:
            displayText = t('mech.tractor')
            break
          default:
            displayText = t('mech.ut')
        }

        return <Text variant={'colorText'}>{displayText}</Text>
      },
      header: t('mech.it')
    },
    {
      id: 'chassis_number',
      accessorFn: (row) => row.chassis_number,
      cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('chassis_number')}</Text>,
      header: t('mech.rn')
    },
    {
      id: 'status',
      accessorFn: (row) => row.item_verification_status,
      cell: ({ row }) => <LabInventoryStatus inventory={row.original} className={''} />,
      header: t('mech.status')
    }
  ]
}
