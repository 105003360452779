import type React from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useToast } from '../../../../../components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useTransferInventory } from '../../hooks/useTransferInventory'
import { useTransferStore } from '../../stores/transferStore'
import { useTranslation } from 'react-i18next'

const schema = object({
  userId: string().min(1, 'Field is required'),
  userType: string().min(1, 'Field is required')
})

interface OriginTransferrerProps {
  className?: string
  next: () => void
  cancel?: () => void
}

export const OriginTransferrer: React.FC<OriginTransferrerProps> = ({
  className,
  next,
  cancel
}) => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { merchant } = useMerchantStore()
  const { t } = useTranslation('inventory')

  const { searchUser, isLoading } = useTransferInventory()
  const { setUserId, setUserType } = useTransferStore()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    if (
      farmer?.farmer_id.toString() === data.userId ||
      merchant?.merchant_id.toString() === data.userId
    ) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Cannot transfer to yourself.'
      })
    } else {
      setUserId(Number(data.userId))
      setUserType(data?.userType)
      void searchUser(data.userType, data.userId, next)
    }
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'userType'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('transfer.type')}</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value)
                  }}
                >
                  <SelectTrigger className={'relative'}>
                    <SelectValue placeholder={<Text>{t('transfer.select')}</Text>} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem className={'w-full'} value={'farmer'}>
                      <Text>{t('transfer.farmer')}</Text>
                    </SelectItem>
                    <SelectItem className={'w-full'} value={'merchant'}>
                      <Text>{t('transfer.merchant')}</Text>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={'userId'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>
                {form.watch('userType') === 'merchant'
                  ? `${t('transfer.mID')}`
                  : `${t('transfer.fID')}`}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={`${t('transfer.fmID')}`}
                  classLabel={'bg-background-dark sm:bg-card'}
                  inputMode={'numeric'}
                  type='text'
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={cancel}>
            {t('transfer.cancel')}
          </Button>
          <Button disabled={isLoading} loading={isLoading} className={'w-1/2'} type={'submit'}>
            {t('transfer.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
