import type React from 'react'
import { Button } from 'components/ui'
import { Languages } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useMediaQuery } from '@mui/material'

export const LanguageToggle: React.FC = () => {
  const { i18n, t } = useTranslation()
  const currentLanguageCode = i18n.language
  const [currentLang, setCurrentLang] = useState<string>(currentLanguageCode)

  const toggleLanguage = (newLang: string): void => {
    void i18n.changeLanguage(newLang)
    setCurrentLang(newLang)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  if (isSmallScreen) {
    return (
      <Button
        variant={'ghost'}
        size={'xs'}
        onClick={() => {
          toggleLanguage(currentLanguageCode === 'en' ? 'sw' : 'en')
        }}
      >
        <Languages size={'15px'} />
        {currentLanguageCode === 'en' ? 'EN' : 'SW'}
      </Button>
    )
  }

  return (
    <Button
      variant={'ghost'}
      size={'xs'}
      onClick={() => {
        toggleLanguage(currentLanguageCode === 'en' ? 'sw' : 'en')
      }}
    >
      <Languages />
      {currentLanguageCode === 'en' ? 'English' : 'Setswana'}
    </Button>
  )
}