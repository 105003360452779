import * as authenticationTranslationEN from '../features/authentication/translation/index.en.json'
import * as authenticationTranslationSW from '../features/authentication/translation/index.sw.json'
import * as genericComponentsEN from './index.en.json'
import * as genericComponentsSW from './index.sw.json'
import * as landManagementEN from '../features/farmer/land-management/translation/index.en.json'
import * as landManagementSW from '../features/farmer/land-management/translation/index.sw.json'
import * as croppingPlanEN from '../features/farmer/cropping-plan/translation/index.en.json'
import * as croppingPlanSW from '../features/farmer/cropping-plan/translation/index.sw.json'
import * as grantsSW from '../features/farmer/grant/translation/index.sw.json'
import * as grantsEN from '../features/farmer/grant/translation/index.en.json'
import * as leaseManagementEN from '../features/farmer/lease-management/translation/index.en.json'
import * as leaseManagementSW from '../features/farmer/lease-management/translation/index.sw.json'
import * as inventoryEN from '../features/farmer/inventory/translation/index.en.json'
import * as inventorySW from '../features/farmer/inventory/translation/index.sw.json'
import * as loanManagementEN from '../features/loan-management/translation/index.en.json'
import * as loanManagementSW from '../features/loan-management/translation/index.sw.json'
import * as extensionOfficerEN from '../features/officer/translation/index.en.json'
import * as extensionOfficerSW from '../features/officer/translation/index.sw.json'

export const resources = {
  en: {
    authenticationTranslation: authenticationTranslationEN,
    genericTranslation: genericComponentsEN,
    landManagement: landManagementEN,
    croppingPlan: croppingPlanEN,
    grants: grantsEN,
    leaseManagement: leaseManagementEN,
    inventory: inventoryEN,
    loanManagement: loanManagementEN,
    extensionOfficer: extensionOfficerEN
  },
  sw: {
    authenticationTranslation: authenticationTranslationSW,
    genericTranslation: genericComponentsSW,
    landManagement: landManagementSW,
    croppingPlan: croppingPlanSW,
    grants: grantsSW,
    leaseManagement: leaseManagementSW,
    inventory: inventorySW,
    loanManagement: loanManagementSW,
    extensionOfficer: extensionOfficerSW
  }
}