export const getProfileRoute = (role: number | null | undefined): string => {
  if (role === 2000) {
    return '/soil-lab/profile'
  } else if (role === 2001) {
    return '/service-provider/profile'
  } else if (role === 2002) {
    return '/seed-dealer/profile'
  } else if (role === 2003) {
    return '/agrochemicals/profile'
  } else {
    return '/'
  }
}

export const getReportsRoute = (role: number | null | undefined): string => {
  if (role === 2000) {
    return '/soil-lab/reports'
  } else if (role === 2001) {
    return '/service-provider/reports'
  } else if (role === 2002) {
    return '/seed-dealer/reports'
  } else if (role === 2003) {
    return '/agrochemicals/reports'
  } else {
    return '/'
  }
}
