import { useGetInventoryHistory } from '../../api/getInventoryHistory'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, TabView } from '../../../../../components/App/Layout'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Loader,
  Text
} from '../../../../../components/ui'
import { FolderX } from 'lucide-react'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { historyColumns } from './view-histories-colums'
import { ViewHistory } from './ViewHistory'
import type React from 'react'
import { Error } from '../../../../../components/Errors/Error'
import { useTranslation } from 'react-i18next'

export const ViewHistories: React.FC = () => {
  const { inventoryId } = useParams<{ inventoryId: string }>()
  const { data, isLoading, error } = useGetInventoryHistory(inventoryId ?? '')
  const { officer } = useOfficerStore()
  const { farmer } = useFarmerStore()
  const { merchant } = useMerchantStore()
  const navigate = useNavigate()
  const { t } = useTranslation('inventory')

  if (error != null && error?.response?.status !== 404) {
    return (
      <Box>
        <Error />
      </Box>
    )
  }
  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (data?.data === undefined || data?.data?.length === 0) {
    return (
      <Box>
        <div className={'flex justify-between items-center border-b border-border p-4'}>
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link
                    onClick={() => {
                      navigate(-1)
                    }}
                    className={'text-lg'}
                    to={``}
                  >
                    {t('history.info')}
                  </Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className={'text-lg'}> {t('history.history')}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <FolderX />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('history.none')}
          </Text>
        </div>
      </Box>
    )
  }

  const filteredData = data?.data.filter((item) => {
    if (officer != null) {
      return true
    }
    if (farmer != null) {
      return item.origin_farmer_id === farmer.farmer_id || item.dest_farmer_id === farmer.farmer_id
    }
    if (merchant != null) {
      return (
        item.origin_merchant_id === merchant.merchant_id ||
        item.dest_merchant_id === merchant.merchant_id
      )
    }
    return false
  })

  return (
    <>
      <div className={'flex justify-between items-center border-b border-border p-4 mb-5'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  onClick={() => {
                    navigate(-1)
                  }}
                  className={'text-lg'}
                  to={``}
                >
                  {t('history.info')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>{t('history.history')}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <TabView>
        <Text size={'large'} className={'flex items-center'}>
          {t('history.view')}
        </Text>
        <div className={'w-full flex flex-col gap-4'}>
          <ViewHistory columns={historyColumns(t)} data={filteredData} />
        </div>
      </TabView>
    </>
  )
}