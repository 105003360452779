/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { LandingButton, LandingLayout, Text } from 'features/landing/components/index'
// mui
import { Tab, Tabs } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { type TransitionProps } from '@mui/material/transitions'
// img
import medium from 'assets/landing/reimbursements/medium.png'
import mediumlarge from 'assets/landing/reimbursements/mediumLarge.png'
import cluster from 'assets/landing/reimbursements/cluster.png'
import small from 'assets/landing/reimbursements/small.png'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface TabInfo {
  label: string
  content: React.ReactNode
  disbursement: string
  popupTitle: string
}

const tabsInfo: TabInfo[] = [
  {
    label: 'Micro Scale Farmer',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} size='small'>
          There are 2 categories of micro scale farmers being level 1 (Willing and able Destitute
          Persons) and Level 2 (individuals with monthly income up to P2,500.00)
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates a maximum of 4ha of land.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 0.6 tonne/ha.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Produce to meet the family food requirement.
        </Text>
      </div>
    ),
    disbursement: '',
    popupTitle: 'Not Applicable'
  },
  {
    label: 'Small Scale Farmer',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} size='small'>
          There are 2 categories of Small-Scale farmers being level 1- Small Scale Farmer (1-8 ha)
          and level 2- Small Scale Farmer (8.1-16 ha).
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates less than 16ha of land.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates less than 16ha of land.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has annual turnover of up to P600,000.00
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 1.5 tonne/ha.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Produce for the market.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 1 full-time employee or is working full time on the farm.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: small,
    popupTitle: 'Loans for Small Scale Farmer cultivating 1ha to 16 ha.'
  },
  {
    label: 'Medium Scale Farmer',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} size='small'>
          There are 3 categories of Medium Scale farmers being level 1- Medium Scale Farmer (16.1-50
          ha), level 2- Medium Scale Farmer (50.1-100 ha) and level 3- Medium Scale Farmer
          (100.1-150 ha)
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates between 16.1ha and 150ha of land.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Have annual turnover of between P600,000.00 and P10 Million
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 2.0 tonne/ha.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Produce for the market.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 2 full-time employees or is working full time on the farm.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: medium,
    popupTitle: 'Loans for Medium Scale Farmers cultivating between 16.1ha to 100ha'
  },
  {
    label: 'Large Scale Farmer',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} size='small'>
          There are 2 categories of Large-Scale Farmers being level 1(up 500ha and Level 2 above
          500ha)
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates less than 16ha of land.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates above 150ha of land.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Have annual turnover of above P10Million.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          50% Contract based production.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 2.5 tonnes/ha.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Produce for the market.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 3 full-time employees or is working full time on the farm.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: mediumlarge,
    popupTitle:
      'Loans for Medium Scale Farmer cultivating between 100.1 ha to 150ha and Large-Scale farmers cultivating 150.1ha to 500ha)'
  },
  {
    label: 'Small Scale Cluster',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates less than 16ha of land.
        </Text>

        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has a minimum of 10 members.
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates between 500 and 2,000 ha of land.
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has an annual turnover of up to P10Million.
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          50% Contract based production.
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 2.5 tonnes/ha.
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 3 full-time employees
        </Text>
        <Text />
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: cluster,
    popupTitle:
      'Loans for Medium Scale Farmer cultivating between 100.1 ha to 150ha and Large-Scale farmers cultivating 150.1ha to 500ha)'
  },
  {
    label: 'medium scale cluster',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has a minimum of 10 members.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates between 2,000 and 6,000 ha of land.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has an annual turnover of between P10Million and P50Million
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          60% contract-based production.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 2.5 tonnes/ha.
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 4 full-time employees
        </Text>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: cluster,
    popupTitle:
      'Loans for Medium Scale Farmer cultivating between 100.1 ha to 150ha and Large-Scale farmers cultivating 150.1ha to 500ha)'
  },
  {
    label: 'Large Scale Cluster',
    content: (
      <div className='flex flex-col gap-1'>
        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates less than 16ha of land.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has a minimum of 10 members.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Cultivates between 6,000 and 12,000 ha of land.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Have annual turnover of above P50Million.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          75% Contract based production.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Productivity level is above 2.5 tonnes/ha.
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Member of Grain Producers Association
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Has at least 5 full-time employees
        </Text>

        <Text variant={'colorText'} className='flex items-center gap-2'>
          <Text variant='primary' className='text-[var(--color-success)]'>
            <p className='fa-solid fa-leaf' />
          </Text>
          Undertake production on a fully fenced field
        </Text>
      </div>
    ),
    disbursement: cluster,
    popupTitle:
      'Loans for Medium Scale Farmer cultivating between 100.1 ha to 150ha and Large-Scale farmers cultivating 150.1ha to 500ha)'
  }
]
const CustomTabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Text>{children}</Text>
        </div>
      )}
    </div>
  )
}

function a11yProps(index: number): { id: string; 'aria-controls': string } {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
//

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const Categories: React.FC = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  return (
    <LandingLayout variant='body' className='bg-[var(--color-background)]'>
      <LandingLayout className='w-full flex flex-col gap-4 px-4 sm:px-8 lg:px-12 py-12 md:py-18'>
        <div className='flex gap-4'>
          <Text size='xLarge' variant='primaryLight' className='fa-solid fa-layer-group' />
          <Text size='xLarge' variant='primaryLight'>
            Categories
          </Text>
        </div>
        <Text variant={'colorText'} size='medium'>
          Under Temo Letlotlo farmers are categorised as follows:
        </Text>
        <div className='sm:flex bg-[background]'>
          <div className='flex'>
            <Tabs
              value={value}
              orientation='vertical'
              onChange={handleChange}
              variant='scrollable'
              aria-label='scrollable'
              sx={{
                minWidth: '200px',
                margin: 0,
                borderRight: 2,
                borderColor: 'divider',
                padding: 0
              }}
            >
              {tabsInfo.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  {...a11yProps(index)}
                  sx={{
                    fontSize: '1rem',
                    textTransform: 'capitalize',
                    color: 'var(--color-text)'
                  }}
                />
              ))}
            </Tabs>
          </div>
          <div className='w-full h-full p-4 sm:p-8 flex flex-wrap gap-4'>
            {tabsInfo.map((tab, index) => (
              <CustomTabPanel key={index} value={value} index={index}>
                {tab.content}
                <div className='w-[100px] mt-8'>
                  <LandingButton
                    onClick={handleClickOpen}
                    variant='outlined'
                    size='small'
                    className='px-4 py-2'
                    title='rembursement'
                    hint={'financial benefits'}
                    icon1={<p className='material-symbols-outlined'>open_in_new</p>}
                  />
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                  keepMounted
                >
                  <DialogTitle
                    id='scroll-dialog-title'
                    sx={{
                      margin: 0,
                      borderBottom: 2,
                      borderColor: 'transparent',
                      padding: 4
                    }}
                  >
                    <Text variant={'colorText'} size='medium'>
                      {tab.popupTitle}
                    </Text>
                  </DialogTitle>

                  <DialogContent>
                    <div className='w-full flex flex-col justify-center'>
                      <img src={tab.disbursement} alt={tab.disbursement} />
                    </div>
                  </DialogContent>
                </Dialog>
              </CustomTabPanel>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className='flex flex-col gap-1'>
            <Text variant={'colorText'} size='medium'>
              {' '}
              Group Characteristics
            </Text>
            <Text variant={'colorText'}>
              The Programme recognises formation of Groups which are expected to transition to
              Clusters for collective bargaining purposes. A group is two or more farmers with
              common interest.
              <br />
              <br />
            </Text>
            <Text variant={'colorText'} className='flex items-center gap-2'>
              <Text variant={'primary'} className='material-symbols-outlined'>
                circle
              </Text>
              Has a minimum of 2 members owning land.
            </Text>
            <Text variant={'colorText'} className='flex items-center gap-2'>
              <Text variant={'primary'} className='material-symbols-outlined'>
                circle
              </Text>
              Collectively cultivate up to 499 ha of land.
            </Text>
            <Text variant={'colorText'} className='flex items-center gap-2'>
              <Text variant={'primary'} className='material-symbols-outlined'>
                circle
              </Text>
              Have working arrangement (Memorandum of Agreement / Constitution)
            </Text>
          </div>
        </div>
        <Text variant={'colorText'}>
          A cluster is a high-performance grain production zone with interconnected arable
          production activities. It operates as a cooperative or Agricultural Management
          Association.
          <br />
          <br />
          <span className='font-semibold'>NB: </span>
          The Programme recognises formation of Groups which are expected to transition to Clusters
          for collective bargaining purposes. A group is two or more farmers with common interest.
        </Text>
      </LandingLayout>
    </LandingLayout>
  )
}