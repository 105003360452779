import { type ApiResponse, type CroppingPlan } from '../../../../types'
import { axios } from '../../../../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getCroppingPlanByExt = async (
  extAreaId: number | string | null | undefined
): Promise<ApiResponse<CroppingPlan>> => {
  return axios.get(`/production/header?extension_area_id=${extAreaId ?? ''}`)
}

export const useGetCroppingPlanByExt = (
  extAreaId: string | null | undefined
): UseQueryResult<ApiResponse<CroppingPlan>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['cropping-plan', extAreaId],
    queryFn: async () => getCroppingPlanByExt(extAreaId),
    enabled: extAreaId != null
  })
}
