import { useCroppingPlanStore } from '../../../stores/useCroppingPlanStore'
import { useGetCroppingPlan, useGetIndividualHeader } from '../../../api/header/getHeader'
import { Loader, Separator, Text } from 'components/ui'
import type React from 'react'
import { Error } from 'components/Errors/Error'
import { StepperFooter } from '../Footer'
import { useTranslation } from 'react-i18next'
import { PestControlReview } from './PestControl'
import { WeedingReview } from './Weeding'

export const CroppingPlanReview: React.FC = () => {
  const { header } = useCroppingPlanStore()
  const { t } = useTranslation('croppingPlan')

  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error: headerError
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)

  const {
    data: apiCroppingPlan,
    isInitialLoading: croppingPlanIsLoading,
    error: croppingPlanError
  } = useGetCroppingPlan(header?.cropping_plan_id, header?.farmer_id)

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || croppingPlanIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (headerError != null || croppingPlanError != null) {
    if (headerError?.response?.status !== 404 || croppingPlanError?.response?.status !== 404) {
      return <Error />
    }
  }

  const notComplete =
    apiCroppingPlan?.data.seed_type == null ||
    apiCroppingPlan?.data?.tillage_method == null ||
    apiCroppingPlan?.data?.crop_id == null

  return (
    <div className='w-full max-w-[900px] mx-auto flex flex-col p-4 md:p-8 gap-4 md:gap-8'>
      <Text size='large'>{t('review.title')}</Text>
      <div className={'w-full flex flex-col gap-2 md:gap-4'}>
        {/* Cropping Plan Header */}
        {apiCroppingPlan?.data?.hectarage != null ? (
          <section className='flex flex-col gap-2'>
            <Text variant='primary'>{t('review.crop')}</Text>
            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.name')}
                </Text>
                <Text size={'medium'}>{header?.crop_name}</Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.ha')}
                </Text>
                <Text className={'font-semibold'}>{apiHeader?.data[0].hectarage}</Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/*  Seeding */}
        {apiCroppingPlan?.data?.seed_type != null ? (
          <section className='flex flex-col gap-2'>
            <Text variant='primary'>{t('review.seed')}</Text>
            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.variety')}
                </Text>
                <Text className={'font-semibold'}>{apiCroppingPlan?.data?.seed_variety}</Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.type')}
                </Text>
                <Text className={'font-semibold'}>{apiCroppingPlan?.data?.seed_type}</Text>
              </div>
            </div>

            <div className='flex flex-col gap-4 px-4'>
              <div className={'flex items-center gap-4'}>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.prod')}
                  </Text>
                  <Text className={'font-semibold'}>
                    {apiCroppingPlan?.data?.target_yield}{' '}
                    {apiCroppingPlan?.data?.target_yield == null
                      ? 'tonne(s)'
                      : apiCroppingPlan?.data?.target_yield > 1
                      ? 'tonnes'
                      : 'tonne'}
                  </Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.returns')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.anticipated_returns}
                  </Text>
                </div>
              </div>
              <Text variant='primary' size={'small'}>
                {t('review.bamb')}
              </Text>
            </div>

            <div className='flex items-center gap-4 mt-2 px-4'>
              <div className='flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.seedCost')}
                </Text>
                <Text className={'font-semibold'}>BWP {apiCroppingPlan?.data?.seed_cost}</Text>
                <Text variant='primary' size={'small'}>
                  {t('review.seedCostPrompt')}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/*  Weeding */}
        {apiCroppingPlan?.data?.weed_control_type != null ? (
          <WeedingReview cp={apiCroppingPlan?.data} />
        ) : null}

        {/*  Pest Control */}
        {apiCroppingPlan?.data?.pest_control_type != null ? (
          <PestControlReview cp={apiCroppingPlan?.data} />
        ) : null}

        {/*  Land Preparation */}
        {apiCroppingPlan?.data?.tillage_method != null ? (
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.land')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.landMethod')}
                </Text>
                <Text className={'font-semibold'}>{apiCroppingPlan?.data?.tillage_method}</Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.harrow')}
                </Text>
                <Text className={'font-semibold'}>
                  {apiCroppingPlan?.data?.isHarrowing === true ? 'Yes' : 'No'}
                </Text>
              </div>
            </div>
            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.landCost')}
                </Text>
                <Text className={'font-semibold'}>
                  BWP {apiCroppingPlan?.data?.land_preparation_cost}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/*  Harvesting */}
        {apiCroppingPlan?.data?.harvesting_method != null ? (
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.harvest')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size={'small'}>{t('review.harvestMethod')}</Text>
                <Text className={'font-semibold'}>{apiCroppingPlan?.data?.harvesting_method}</Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.harvestCost')}
                </Text>
                <Text className={'font-semibold'}>
                  BWP {apiCroppingPlan?.data?.harvesting_cost}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/*  Shelling & Threshing */}
        {apiCroppingPlan?.data?.shelling_threshing_method != null ? (
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.shell')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.shellMethod')}
                </Text>
                <Text className={'font-semibold'}>
                  {apiCroppingPlan?.data?.shelling_threshing_method}
                </Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.shellCost')}
                </Text>
                <Text className={'font-semibold'}>
                  BWP {apiCroppingPlan?.data?.shelling_threshing_cost}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/*  Packaging */}
        {apiCroppingPlan?.data?.packaging_method != null ? (
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.pack')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.packMethod')}
                </Text>
                <Text className={'font-semibold'}>{apiCroppingPlan?.data?.packaging_method}</Text>
              </div>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.packCost')}
                </Text>
                <Text className={'font-semibold'}>BWP {apiCroppingPlan?.data?.packaging_cost}</Text>
              </div>
            </div>
            <Separator />
          </section>
        ) : null}

        {/* Transportation */}
        {apiCroppingPlan?.data?.transportation_cost === null ||
        apiCroppingPlan?.data?.transportation_cost === 0 ? null : (
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.tran')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  {t('review.tcTran')}
                </Text>
                <Text className={'font-semibold'}>
                  BWP {apiCroppingPlan?.data?.transportation_cost}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        )}

        {/* Total Cost */}
        <section className='flex flex-col gap-4'>
          <Text variant='primary' className={'font-semibold'}>
            {t('review.tCost')}
          </Text>

          <div className='flex items-center gap-4 px-4'>
            <div className='w-1/2 flex flex-col'>
              <Text size={'large'} variant={'success'} className={'font-semibold'}>
                BWP {header.total_cost}
              </Text>
            </div>
          </div>
          <Separator />
        </section>
      </div>
      <StepperFooter notComplete={notComplete} />
    </div>
  )
}
