import { type ApiResponse, type ApiResponseWithObject } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type StaticInvData } from '../types'

export const getStaticInventory = async (): Promise<ApiResponseWithObject<StaticInvData>> => {
  return axios.get(`/staticdata/inventory`)
}

export const useGetStaticInventory = (): UseQueryResult<
  ApiResponseWithObject<StaticInvData>,
  ApiResponse<[]>
> => {
  return useQuery({
    queryKey: ['staticInventoryData'],
    queryFn: async () => getStaticInventory()
  })
}
