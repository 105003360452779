export function mapCmsErrorToMessage(error: string): string {
  switch (error) {
    case 'Duplicate Contact Number':
      return 'User already exists with number'
    case 'Duplicate OMANG Number':
      return 'User already exists with omang.'
    case 'omang number provided does not exist.':
      return 'Some information is not correct'
    case 'Some information is not correct':
      return 'Some information is not correct'
    case 'Network Error':
      return 'Network error, please check your internet connection.'
    case 'Land data not found for the specified person ID.':
      return 'Land does not exist for this user'
    case 'ERR_BAD_REQUEST':
      return 'Bad Request, Please try again.'
    case 'ERR_NETWORK':
      return 'Connection problems, please check your connection.'
    case 'ERR_CANCELED':
      return 'Request Canceled, Please try again.'
    case 'ECONNABORTED':
      return 'Request Canceled, Please try again.'
    case '204':
      return 'User with omang not found'
    case '400':
      return 'Bad Request, Please try again.'
    case '404':
      return 'Not Found'
    case '401':
      return 'Unauthorized'
    case '403':
      return 'Request Forbidden'
    case '405':
      return 'Not allowed'
    case '408':
      return 'Your request timed out, try again.'
    case '409':
      return 'Already Exists'
    case '429':
      return 'Too many requests, try again later.'
    case '500':
      return 'Internal error, try again later.'
    case 'Operation unsuccessful':
      return 'Request failed, Please try again later!'
    default:
      return 'Something went wrong, Please try again later!'
  }
}
