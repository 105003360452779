import { Divider } from '@mui/material'
import { Text } from 'components/ui'
import {
  getChemicalPestMethod,
  getChemicalWeedMethodValue,
  getHerbicide,
  getInsecticide,
  getManualPestStageValue,
  getManualWeedApplicationStageValue,
  getManualWeedMethodValue
} from 'features/farmer/cropping-plan/utils/functions'
import type React from 'react'
import { type CroppingPlan, type CroppingPlanDetails } from 'types'
import { useTranslation } from 'react-i18next'

interface CroppingPlanInfoProps {
  header: CroppingPlan
  croppingPlan: CroppingPlanDetails
}

export const CroppingPlanInfo: React.FC<CroppingPlanInfoProps> = ({ croppingPlan, header }) => {
  const { t } = useTranslation('extensionOfficer')

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'w-full flex items-center border-b border-border pb-4'}>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'}>{t('cp.crop')}</Text>
          <Text variant={'primary'} size={'medium'}>
            {header?.crop_name}
          </Text>
        </div>

        <div className={'w-1/2 flex flex-col mr-8'}>
          <Text size={'small'}>{t('cp.ha')}</Text>
          <Text size={'medium'} variant={'primary'}>
            {croppingPlan?.hectarage}
          </Text>
        </div>
      </div>
      {/* seeding */}
      <div className='flex flex-col gap-4 border-b border-border pb-4'>
        <Text size={'medium'} variant={'primary'}>
          {t('cp.seeding')}
        </Text>
        <div className={'w-full flex items-center'}>
          {/* Seeding */}
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.seedV')}
            </Text>
            <Text size={'medium'}>{croppingPlan?.seed_variety}</Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.ar')}
            </Text>
            <Text size={'medium'}>BWP {croppingPlan?.anticipated_returns}</Text>
          </div>
        </div>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'} variant={'secondary'}>
            {t('cp.tcSeed')}
          </Text>
          <Text size={'medium'} variant={'primary'}>
            BWP {croppingPlan?.seed_cost}{' '}
          </Text>
        </div>
      </div>

      {croppingPlan?.packaging_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.weed')}
          </Text>
          <div className={'w-full flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.weedM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.weed_control_type}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.weedAs')}
              </Text>
              <Text size={'medium'}>
                {getManualWeedApplicationStageValue(
                  croppingPlan?.weed_control_chemical_schedule ??
                    croppingPlan?.weed_control_manual_schedule ??
                    undefined
                )}
              </Text>
            </div>
          </div>
          <div className={'w-full flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.weedAm')}
              </Text>
              <Text size={'medium'}>
                {croppingPlan?.weed_control_manual_method !== null
                  ? getManualWeedMethodValue(croppingPlan?.weed_control_manual_method)
                  : getChemicalWeedMethodValue(croppingPlan.weed_control_chemical_method)}
              </Text>
            </div>
            {croppingPlan?.weed_control_chemicals != null &&
            croppingPlan?.weed_control_chemicals.length > 0 ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text size={'small'} variant={'secondary'}>
                  {t('cp.herb')}
                </Text>
                {croppingPlan?.weed_control_chemicals?.map((item, index) => (
                  <span key={index}>
                    * <Text size={'medium'}>{getHerbicide(parseInt(item.id))[0].name}</Text>
                  </span>
                ))}
              </div>
            ) : null}
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcWeed')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {croppingPlan?.weed_control_type === 'Chemical'
                  ? (croppingPlan?.weed_control_chemical_cost ?? 0) +
                    (croppingPlan?.weed_control_application_cost ?? 0)
                  : croppingPlan?.weed_control_application_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      {croppingPlan?.packaging_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.pest')}
          </Text>

          <div className={'w-full flex flex-wrap items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.pestM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.pest_control_type} </Text>
            </div>
            {croppingPlan?.pest_control_manual_schedule != null ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text size={'small'} variant={'secondary'}>
                  {t('cp.pestS')}
                </Text>
                <Text size={'medium'}>
                  {croppingPlan?.pest_control_chemical_schedule != null
                    ? croppingPlan?.pest_control_chemical_schedule
                    : getManualPestStageValue(croppingPlan?.pest_control_manual_schedule)}
                </Text>
              </div>
            ) : null}
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.pestAm')}
              </Text>
              <Text size={'medium'}>
                {croppingPlan?.pest_control_manual_method !== null
                  ? croppingPlan?.pest_control_manual_method
                  : croppingPlan?.pest_control_chemical_method != null
                  ? getChemicalPestMethod(croppingPlan.pest_control_chemical_method)
                  : null}
              </Text>
            </div>
            {croppingPlan?.pest_control_chemicals != null &&
            croppingPlan?.pest_control_chemicals.length > 0 ? (
              <div className={'w-1/2 flex flex-col'}>
                <Text size={'small'} variant={'secondary'}>
                  {t('cp.inst')}
                </Text>

                {croppingPlan?.pest_control_chemicals?.map((item, index) => (
                  <span key={index}>
                    *<Text size={'medium'}> {getInsecticide(parseInt(item.id))[0].name}</Text>
                  </span>
                ))}
              </div>
            ) : null}
          </div>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcPest')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP{' '}
                {croppingPlan?.pest_control_type === 'Chemical'
                  ? (croppingPlan?.pest_control_application_cost ?? 0) +
                    (croppingPlan?.pest_control_chemical_cost ?? 0)
                  : croppingPlan?.pest_control_application_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col gap-4 border-b border-border pb-4'>
        <Text size={'medium'} variant={'primary'}>
          {t('cp.land')}
        </Text>

        <div className={'w-full flex flex-wrap items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.landM')}
            </Text>
            <Text size={'medium'}>{croppingPlan?.tillage_method}</Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.harr')}
            </Text>
            <Text size={'medium'}>
              {croppingPlan?.isHarrowing === true ? t('cp.yes') : t('cp.no')}
            </Text>
          </div>
        </div>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'} variant={'secondary'}>
            {t('cp.tcLand')}
          </Text>
          <Text variant={'success'} size={'medium'}>
            BWP {croppingPlan?.land_preparation_cost}
          </Text>
        </div>
      </div>

      {croppingPlan?.harvesting_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.harv')}
          </Text>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.harvM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.harvesting_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcHarv')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.harvesting_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      {croppingPlan?.shelling_threshing_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.st')}
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.stm')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.shelling_threshing_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcst')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                {' '}
                BWP {croppingPlan?.shelling_threshing_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      {croppingPlan?.packaging_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.pack')}
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.packM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.packaging_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcPack')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.packaging_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      <div className={'flex flex-col mt-6'}>
        {/* Transportation */}
        <div className={'flex items-center'}>
          {croppingPlan?.transportation_cost === null ||
          croppingPlan?.transportation_cost === 0 ? null : (
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tran')}
              </Text>

              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.transportation_cost}
              </Text>
            </div>
          )}
          {/* Total Cost */}
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'primary'}>
              {t('cp.tcTran')}
            </Text>
            <Text variant={'success'} size={'medium'}>
              BWP {header.total_cost}
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}