import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import type React from 'react'
import { useState } from 'react'
import { Button, Text } from '../../../../../../../components/ui'
import { useWeedingStore } from '../../../../stores/useWeedingStore'
import { type Adjuvants, type Herbicides } from '../../../../../../../types/static'
import { useTranslation } from 'react-i18next'
import collect from 'collect.js'
import type { WeedControl, WeedingChemicals } from '../../../../../../../types'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  herbicide: Herbicides
  weeding: WeedControl | undefined
  next: () => void
  back: () => void
}

export function AdjuvantsTable<TData, TValue>({
  columns,
  data,
  herbicide,
  next,
  back,
  weeding
}: DataTableProps<TData, TValue>): React.ReactNode {
  const collection = collect(
    weeding?.chemicals?.filter(
      (item) =>
        item.chemical_price_detail_id === herbicide.herbicide_price_detail_id &&
        item.weed_id === herbicide.weed_id &&
        item.adjuvant == null
    ) ?? []
  )
  const selectedAdjuvantApi = collection.isEmpty()
    ? {}
    : (collection
        .mapWithKeys((item: WeedingChemicals) => [
          [item?.adjuvant_price_detail_id?.toString()],
          true
        ])
        .all() as unknown as Record<string, boolean>)

  const { setSelectedHerbicides, selectedHerbicides } = useWeedingStore()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    weeding != null ? { ...selectedAdjuvantApi } : {}
  )
  const table = useReactTable({
    data,
    columns,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => (row as Adjuvants).adjuvant_price_detail_id.toString(),
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const { t } = useTranslation('croppingPlan')

  const onSubmit = (): void => {
    if (table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected()) {
      const selectedAdjuvantFromTable = table
        .getSelectedRowModel()
        .rows.map((row) => row.original as Adjuvants)
      const herbicidesSchedule = herbicide.application_stage

      if (herbicidesSchedule === 'Post Emergence') {
        const updatedList = selectedHerbicides?.PE?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: selectedAdjuvantFromTable.length === 0 ? null : selectedAdjuvantFromTable[0]
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PE: updatedList
        })

        next()
      } else if (herbicidesSchedule === 'Pre Planting') {
        const updatedList = selectedHerbicides?.PrP?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: selectedAdjuvantFromTable.length === 0 ? null : selectedAdjuvantFromTable[0]
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PrP: updatedList
        })
        next()
      } else {
        const updatedList = selectedHerbicides?.PrE?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: selectedAdjuvantFromTable.length === 0 ? null : selectedAdjuvantFromTable[0]
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PrE: updatedList
        })
        next()
      }
    } else {
      const herbicidesSchedule = herbicide.application_stage

      if (herbicidesSchedule === 'Post Emergence') {
        const updatedList = selectedHerbicides?.PE?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: null
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PE: updatedList
        })

        console.log(updatedList)
        next()
      } else if (herbicidesSchedule === 'Pre Planting') {
        const updatedList = selectedHerbicides?.PrP?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: null
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PrP: updatedList
        })
        next()
      } else {
        const updatedList = selectedHerbicides?.PrE?.map((item) => {
          if (item.herbicide.registration_no === herbicide.registration_no) {
            return {
              ...item,
              adjuvant: null
            }
          }
          return item
        })

        setSelectedHerbicides({
          ...selectedHerbicides!,
          PrE: updatedList
        })
        next()
      }
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col sm:flex-row gap-4 justify-start sm:items-center'}>
        <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
          Adjuvant Selection for {herbicide?.herbicide_name} / {herbicide?.weed_name}
        </Text>
      </div>
      <div className='rounded-md border'>
        <Table className={'relative'}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    row.toggleSelected()
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} selectable />
      <div className='w-full flex justify-end gap-2'>
        <Button onClick={back} size={'sm'} variant={'secondary'}>
          {t('footer.prev')}
        </Button>
        <Button onClick={onSubmit} size={'sm'} variant={'primary'}>
          {t('footer.next')}
        </Button>
      </div>
    </div>
  )
}
